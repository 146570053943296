"use strict";
import moment from "moment";
import _ from "lodash";
import {
    CLOUDINARY_LIB_URL,
    SID_LOOKUP,
    SPANISH,
    ENGLISH,
} from "./app.constants";
import {
    BLOCKED,
    COMPLETE as PAPERWORK_COMPLETE,
    RECORD_SOURCE_EXTERNAL,
    INITIATOR,
    PENDING as PAPERWORK_PENDING,
    SIGNER,
    YES_PREPARER,
} from "./common/paperwork/constants";
import {
    getCloudinaryUrl,
    getContactWorkerDisplayName,
    getLatestMembership,
    lazyLoad,
    urlParamFilter,
    urlParamSort,
} from "./common/common/common.utils";
import {
    CANCELLED,
    COMPLETE,
    GROWER_CERTIFIED,
    PENDING,
    PENDING as SAFETY_PENDING,
} from "./common/safety/safety.constants";
import {
    COMMON_DATE_FORMAT,
    DATE_FORMAT,
    EXPIRING,
    MISSING,
    TRAINED,
    UNTRAINED,
    EMPLOYEE_ACTIVE,
} from "./common/common/common.constants";
import cookiesRequiredTemplate from "./cookies_required.html";
import { NOTE, REMOTE, WORKER_COMMENT } from "./common/safety/constants";
import {
    filterOutBadEnums,
    ONBOARDING_REPORT_FILTERS,
    SORTS,
    wrap_option,
    getNextRole,
    getRemainingRoles,
    getSignatureFieldNames,
} from "./common/paperwork/paperwork.utils";
import {
    EMPLOYEE_ENGAGEMENT_CATEGORY,
    COMPLIANCE_CATEGORY,
} from "./common/communication/constants";
import {
    IN_PROGRESS_MEETING_STATUS,
    TOPIC_FILTERS,
    TRAINING_REPORT_FILTERS,
} from "./common/safety/safety.filters";
import {
    ADD_TERMINATE_EMPLOYEES,
    AUTHENTICATED_PERMISSION,
    CREATE_FARM,
    EDIT_ADMINS,
    EDIT_FARM,
    EDIT_GROWER_ADMINS,
    EDIT_GROWER_ORGANIZATION,
    EDIT_GROWER_BILLING,
    MANAGE_COMMUNICATION,
    MANAGE_CREWS,
    MANAGE_ONBOARDING,
    MANAGE_SAFETY,
    CAN_EXPORT_DATA_GROWER,
} from "./common/auth/auth.constants";
import { ACTIVE, SENT, SENT_STATUS } from "./common/communication/constants";
import { NOW } from "./common/safety/constants";
import { COMMUNICATION_LIST_FILTERS } from "./common/communication/filters";
import { addBreadcrumb } from "@sentry/browser";

export const publicWaflaContractListState = {
    name: "visatrace",
    url: "/visatrace/{grower_airtable_id}",
    component: "publicContractList",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "grower.wafla" */ "./grower/wafla"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        contractsData: function (WAFLAContractService, $transition$) {
            "ngInject";
            return WAFLAContractService.retrieve(
                $transition$.params().grower_airtable_id,
            );
        },
        grower: function (contractsData) {
            "ngInject";
            return contractsData.grower;
        },
        activeContracts: function (contractsData) {
            "ngInject";
            return _.filter(contractsData.contracts, function (contract) {
                return _.includes(
                    [
                        "Active",
                        "Crossing Completed",
                        "Transfer",
                        "Processing Outbound",
                    ],
                    contract.status,
                );
            });
        },
        doneContracts: function (contractsData) {
            "ngInject";
            return _.filter(contractsData.contracts, ["status", "Done"]);
        },
    },
    data: {
        title: "routes.WAFLA_DASHBOARD",
        breadcrumb: "crumbs.WAFLA_DASHBOARD",
        mixpanelEvent: "Viewed public wafla dashboard",
    },
};

export const homescreenState = {
    name: "app.homescreen",
    url: "/home",
    component: "homeScreen",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "common.home" */ "./common/home"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        growerMemberships: function (GrowerMembershipService, accountSid) {
            "ngInject";
            return GrowerMembershipService.list({
                user__sid: accountSid,
                active: true,
            });
        },
        growers: function (GrowerOrganizationService, growerMemberships) {
            "ngInject";
            return GrowerOrganizationService.several(
                _.map(growerMemberships, "grower_organization"),
            );
        },
        farmManagementMemberships: function (
            ManagementMembershipService,
            accountSid,
        ) {
            "ngInject";
            return ManagementMembershipService.list({
                active: true,
                user__sid: accountSid,
            });
        },
        farmsFromFarmManagement: function (
            FarmService,
            farmManagementMemberships,
        ) {
            "ngInject";
            return FarmService.list({
                sid__in: _.join(_.map(farmManagementMemberships, "farm")),
                active: true,
                visible: true,
            });
        },
        growerManagementMemberships: function (
            GrowerManagementMembershipService,
            accountSid,
        ) {
            "ngInject";
            return GrowerManagementMembershipService.list({
                active: true,
                user__sid: accountSid,
            });
        },
        farmsFromGrowerManagement: function (
            FarmService,
            growerManagementMemberships,
        ) {
            "ngInject";
            return FarmService.list({
                active: true,
                grower_organization__sid__in: _.join(
                    _.map(growerManagementMemberships, "grower_organization"),
                ),
                visible: true,
            });
        },
        managedFarms: function (
            farmsFromFarmManagement,
            farmsFromGrowerManagement,
        ) {
            "ngInject";
            let myFarms = _.unionBy(
                farmsFromFarmManagement,
                farmsFromGrowerManagement,
                SID_LOOKUP,
            );
            return _.groupBy(myFarms, "grower_organization");
        },
        managedGrowers: function (GrowerOrganizationService, managedFarms) {
            "ngInject";
            return GrowerOrganizationService.several(_.keys(managedFarms));
        },
        managedCrews: function (GroupService, accountSid) {
            "ngInject";
            return GroupService.list({ managed_by: accountSid });
        },
        managedCrewsData: function (managedCrews, growers) {
            "ngInject";
            return _.map(managedCrews, function (crew) {
                let grower = _.find(growers, [
                    SID_LOOKUP,
                    crew.grower_organization,
                ]);
                return {
                    crew: crew,
                    grower_organization: grower,
                };
            });
        },
        myIncompleteDocumentRequests: function (
            DocumentPackageRequestService,
            growerMemberships,
        ) {
            "ngInject";
            return DocumentPackageRequestService.list({
                grower_membership__sid__in: _.join(
                    _.map(growerMemberships, SID_LOOKUP),
                ),
                completed: false,
                cancelled: false,
            });
        },
        documentPackages: function (
            DocumentPackageService,
            myIncompleteDocumentRequests,
        ) {
            "ngInject";
            return DocumentPackageService.several(
                _.map(myIncompleteDocumentRequests, "document_package"),
            );
        },
        myPendingSignatureRequests: function (
            SignatureRequestService,
            myIncompleteDocumentRequests,
            growerMemberships,
        ) {
            "ngInject";
            return SignatureRequestService.list({
                grower_membership__sid__in: _.join(
                    _.map(growerMemberships, SID_LOOKUP),
                ),
                status: PAPERWORK_PENDING,
                role: SIGNER,
                document_package_request__sid__in: _.join(
                    _.map(myIncompleteDocumentRequests, SID_LOOKUP),
                ),
            });
        },
        myCompletedSignatureRequests: function (
            SignatureRequestService,
            myIncompleteDocumentRequests,
            growerMemberships,
        ) {
            "ngInject";
            return SignatureRequestService.list({
                grower_membership__sid__in: _.join(
                    _.map(growerMemberships, SID_LOOKUP),
                ),
                status: PAPERWORK_COMPLETE,
                role: SIGNER,
                document_package_request__sid__in: _.join(
                    _.map(myIncompleteDocumentRequests, SID_LOOKUP),
                ),
            });
        },
        myPaperwork: function (
            myIncompleteDocumentRequests,
            myPendingSignatureRequests,
            documentPackages,
            myCompletedSignatureRequests,
        ) {
            "ngInject";
            let paperwork = [];
            _.forEach(myIncompleteDocumentRequests, function (documentRequest) {
                paperwork.push({
                    documentRequest: documentRequest,
                    signatureRequest: _.find(
                        _.concat(
                            myPendingSignatureRequests,
                            myCompletedSignatureRequests,
                        ),
                        ["document_package_request", documentRequest.sid],
                    ),
                    documentPackage: _.find(documentPackages, [
                        SID_LOOKUP,
                        documentRequest.document_package,
                    ]),
                });
            });
            paperwork = _.filter(paperwork, function (paperwork) {
                return (
                    _.get(paperwork.signatureRequest, "status") !==
                    PAPERWORK_COMPLETE
                );
            });
            return _.groupBy(paperwork, "documentRequest.requester");
        },
        safetyMeetingRequests: function (
            SafetyMeetingRequestService,
            accountSid,
        ) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                active: true,
                subject__sid: accountSid,
                status: SAFETY_PENDING,
            });
        },
        safetyMeetings: function (safetyMeetingRequests, SafetyMeetingService) {
            "ngInject";
            return SafetyMeetingService.several(
                _.map(safetyMeetingRequests, "safety_meeting"),
            );
        },
        safetyMeetingTopics: function (
            SafetyMeetingTopicService,
            safetyMeetingRequests,
        ) {
            "ngInject";
            return SafetyMeetingTopicService.several(
                _.map(safetyMeetingRequests, "topic"),
            );
        },
        neededMeetings: function (
            safetyMeetingRequests,
            safetyMeetings,
            safetyMeetingTopics,
        ) {
            "ngInject";
            let neededMeetings = [];
            _.forEach(safetyMeetingRequests, function (request) {
                let meeting = _.find(safetyMeetings, [
                    SID_LOOKUP,
                    request.safety_meeting,
                ]);
                neededMeetings.push({
                    request: request,
                    meeting: meeting,
                    topic: _.find(safetyMeetingTopics, [
                        SID_LOOKUP,
                        request.topic,
                    ]),
                });
            });
            return _.groupBy(neededMeetings, "request.requester");
        },
    },
    data: {
        title: "routes.HOME_TITLE",
        mixpanelEvent: "Viewed homescreen",
    },
};

export const signinState = {
    name: "signin",
    url: "/signin?redirect_to?mobile_email",
    params: {
        redirect_to: {
            inherit: false,
            value: undefined,
        },
        mobile_email: {
            inherit: false,
            value: undefined,
        },
    },
    component: "signin",
    resolve: {
        credentials: function ($transition$) {
            "ngInject";
            return {
                mobile_email: $transition$.params().mobile_email,
                redirect_to: $transition$.params().redirect_to,
            };
        },
    },
    data: {
        permissions: {
            except: [AUTHENTICATED_PERMISSION],
            redirectTo: homescreenState.name,
        },
        title: "routes.SIGN_IN_TITLE",
        mixpanelEvent: "Viewed signin",
    },
};

export const signinDoneState = {
    name: "signin.done",
    url: "/done?channel",
    component: "signinDone",
    resolve: {
        channel: function ($transition$) {
            "ngInject";
            return $transition$.params().channel;
        },
    },
    data: {
        permissions: {
            except: [AUTHENTICATED_PERMISSION],
            redirectTo: homescreenState.name,
        },
        title: "routes.SIGN_IN_DONE_TITLE",
        mixpanelEvent: "Viewed signin done",
    },
};

export const cookiesRequiredState = {
    name: "cookies",
    url: "/cookies-required",
    template: cookiesRequiredTemplate,
    data: {
        title: "routes.COOKIES_REQUIRED_TITLE",
        mixpanelEvent: "Viewed cookies required",
    },
};

export const resetState = {
    name: "reset",
    url: "/reset",
    redirectTo: (trans) => {
        let AuthService = trans.injector().get("AuthService");
        let $cookies = trans.injector().get("$cookies");
        let $window = trans.injector().get("$window");
        AuthService.clearStorage();
        $cookies.remove("nf_ab");
        $window.location = "/signin";
    },
};

export const errorTestState = {
    name: "errorTest",
    url: "/error",
    controller: function () {
        throw new TypeError("This is a test error");
    },
    data: {
        title: "routes.ERROR_TEST_TITLE",
        mixpanelEvent: "Viewed error test",
    },
};

export const appState = {
    name: "app",
    abstract: true,
    component: "frameComponent",
    resolve: {
        accountSid: function (AccountService, AuthService) {
            "ngInject";
            return AuthService.getAuthenticatedUserSid();
        },
        account: function (AccountService, accountSid, PermPermissionStore) {
            "ngInject";
            var permissions = PermPermissionStore.getStore();
            console.log(permissions);
            return AccountService.retrieve(accountSid);
        },
    },
    data: {
        permissions: {
            only: [AUTHENTICATED_PERMISSION],
            redirectTo: {
                default: signinState.name,
            },
        },
    },
};

export const growersState = {
    name: "app.growers",
    url: "/growers",
    abstract: true,
};

function previousIfForbidden(rejectedPermission, transitionProperties) {
    return {
        state: forbiddenState,
        params: {
            redirect: {
                state: transitionProperties.fromState.name,
                params: transitionProperties.fromParams,
            },
        },
    };
}

export const forbiddenState = {
    name: "app.forbidden",
    url: "/forbidden",
    params: {
        redirect: {
            value: {
                state: homescreenState.name,
                params: {},
            },
            squash: true,
        },
    },
    component: "forbidden",
    resolve: {
        redirect: function ($transition$) {
            "ngInject";
            return $transition$.params().redirect;
        },
    },
    data: {
        title: "routes.FORBIDDEN_TITLE",
        mixpanelEvent: "Viewed forbidden landing page",
    },
};

export const growerDashboardState = {
    name: "app.growers.grower",
    url: "/{grower_sid}",
    component: "growerDashboard",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "common.safety" */ "./common/safety"),
            import(/* webpackChunkName: "grower" */ "./grower"),
            import(
                /* webpackChunkName: "lazy.communication" */ "./lazy/communication"
            ),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        grower: function (GrowerOrganizationService, $transition$) {
            "ngInject";
            return GrowerOrganizationService.retrieve(
                $transition$.params().grower_sid,
            );
        },
        timeTrackingVendorConfigurations: function (
            TimeTrackingVendorConfigurationService,
            $transition$,
        ) {
            "ngInject";
            return TimeTrackingVendorConfigurationService.list(
                {
                    grower_organization__sid: $transition$.params().grower_sid,
                    active: true,
                },
                {
                    expand: ["time_tracking_vendor"],
                },
            );
        },
        farms: function (FarmService, $transition$) {
            "ngInject";
            return FarmService.list({
                active: true,
                visible: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        myGrowerMembership: function (
            GrowerMembershipService,
            account,
            $transition$,
        ) {
            "ngInject";
            return GrowerMembershipService.first({
                grower_organization__sid: $transition$.params().grower_sid,
                user__sid: account.sid,
            });
        },
    },
    data: {
        title: "routes.GROWER_DASHBOARD_TITLE",
        mixpanelEvent: "Viewed grower dashboard",
    },
};

export const fieldClockRedirectState = {
    name: "app.growers.grower.fromFieldClock",
    url: "/from-fieldclock?company?employee_id?view",
    resolve: {
        employeeConfiguration: function (
            $transition$,
            TimeTrackingEmployeeConfigurationService,
        ) {
            "ngInject";
            let view = $transition$.params().view;
            if (view == "new-employee") {
                return;
            }
            return TimeTrackingEmployeeConfigurationService.first({
                grower_membership__grower_organization__sid:
                    $transition$.params().grower_sid,
                employee_external_id: $transition$.params().employee_id,
                time_tracking_vendor_configuration__company_external_id:
                    $transition$.params().company,
            });
        },
        growerMembership: function (
            $transition$,
            employeeConfiguration,
            GrowerMembershipService,
        ) {
            "ngInject";
            let view = $transition$.params().view;
            if (view == "new-employee" || !employeeConfiguration) {
                return;
            }
            return GrowerMembershipService.retrieve(
                employeeConfiguration.grower_membership,
            );
        },
        farmMembership: function (
            $transition$,
            employeeConfiguration,
            growerMembership,
            FarmMembershipService,
        ) {
            "ngInject";
            let view = $transition$.params().view;
            if (view == "new-employee" || !employeeConfiguration) {
                return;
            }
            return FarmMembershipService.first({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                user__sid: growerMembership.user,
            });
        },
        redirect: function (
            $transition$,
            growerMembership,
            farmMembership,
            farms,
            ToastService,
        ) {
            "ngInject";
            let view = $transition$.params().view;
            let state;
            let params = {
                grower_sid: $transition$.params().grower_sid,
            };
            if (view == "new-employee") {
                state = newWorkerState;
                params.farm_sid = _.first(farms).sid;
            } else {
                if (growerMembership && farmMembership) {
                    params.farm_sid = farmMembership.farm;
                    params.member_sid = growerMembership.user;
                    if (view == "edit-employee") {
                        state = memberEditProductsState;
                    } else if (view == "show-employee") {
                        state = memberState;
                    } else {
                        state = manageRosterState;
                    }
                } else {
                    params.farm_sid = _.first(farms).sid;
                    state = manageRosterState;
                    ToastService.create("toasts.TIME_TRACKING_MISSING");
                }
            }
            return $transition$.router.stateService.target(state, params);
        },
    },
    data: {
        mixpanelEvent: "Viewed FieldClock redirect",
    },
    redirectTo: (trans) => {
        return trans.injector().getAsync("redirect");
    },
};

export const growerHelpState = {
    name: "app.growers.grower.help",
    url: "/help",
    views: { lower: { component: "supportDashboard" } },
    data: {
        title: "routes.GROWER_HELP_TITLE",
        mixpanelEvent: "Viewed grower support dashboard",
    },
};

export const growerManageOnboardingState = {
    name: "app.growers.grower.manageOnboarding",
    url: "/onboarding",
    views: { lower: { component: "growerManageOnboarding" } },
    lazyLoad: function ($transition$) {
        return lazyLoad($transition$, CLOUDINARY_LIB_URL);
    },
    resolve: {
        documents: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        customizations: function (
            DocumentPackageCustomizationService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageCustomizationService.list({
                owner__sid: $transition$.params().grower_sid,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        packets: function (PacketService, $transition$) {
            "ngInject";
            return PacketService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
        packetMembers: function (PacketMemberService, packets) {
            "ngInject";
            return PacketMemberService.list({
                active: true,
                packet__sid__in: _.join(_.map(packets, SID_LOOKUP)),
            });
        },
    },
    data: {
        title: "routes.GROWER_MANAGE_ONBOARDING_TITLE",
        mixpanelEvent: "Viewed grower manage onboarding",
        breadcrumb: "crumbs.GROWER_MANAGE_ONBOARDING",
    },
};

export const growerOnboardingReportState = {
    name: "app.growers.grower.manageOnboarding.report",
    url: "/report?from?to?crews?",
    params: {
        from: {
            value: () => moment().startOf("day").toISOString(),
            squash: true,
            inherit: false,
        },
        to: {
            value: () => moment().toISOString(),
            squash: true,
            inherit: false,
        },
        crews: {
            array: true,
            value: [],
        },
    },
    component: "companyOnboardingReport",
    resolve: {
        documentRequests: function (
            DocumentPackageRequestService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageRequestService.list({
                active: true,
                cancelled: false,
                requester__sid: $transition$.params().grower_sid,
                created__gt: $transition$.params().from,
                created__lt: $transition$.params().to,
                omit: "data",
            });
        },
        groups: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        query: function ($transition$) {
            "ngInject";
            let x = {
                fromDate: $transition$.params().from,
                toDate: $transition$.params().to,
                crews: $transition$.params().crews,
            };
            console.log(x);
            return x;
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        memberships: function (MembershipService, $transition$) {
            "ngInject";
            return MembershipService.list({
                group__farm__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        users: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                growermembership__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        onboardingEvents: function (
            documentRequests,
            growerMemberships,
            farmMemberships,
            users,
            farms,
            $transition$,
            memberships,
        ) {
            "ngInject";
            let documentRequestsByUser = _.groupBy(documentRequests, "signer");
            let farmMembershipsByUser = _.groupBy(farmMemberships, "user");
            let groupMembershipsByUser = _.groupBy(memberships, "user");

            let hiresWithDocuments = _.keys(documentRequestsByUser);
            let thisPeriodsNewFarmMemberships = _.filter(
                farmMemberships,
                function (membership) {
                    let most_recent_hire_date = moment(membership.date_added);
                    let from = moment($transition$.params().from);
                    let to = moment($transition$.params().to);
                    return most_recent_hire_date.isBetween(from, to);
                },
            );
            let hiresWithoutDocuments = _.difference(
                _.map(thisPeriodsNewFarmMemberships, "user"),
                hiresWithDocuments,
            );
            let allUsers = _.union(hiresWithDocuments, hiresWithoutDocuments);

            let events = [];
            _.forEach(allUsers, function (userSid) {
                let thisUser = _.find(users, [SID_LOOKUP, userSid]);
                let requests = documentRequestsByUser[userSid];
                let thisEmployeesGrowerMembership = _.find(growerMemberships, [
                    "user",
                    userSid,
                ]);
                let thisEmployeesFarmMemberships =
                    farmMembershipsByUser[userSid];
                let thisEmployeesGroupMemberships =
                    groupMembershipsByUser[userSid];
                let mostRecentFarmMembership = _.last(
                    _.orderBy(
                        thisEmployeesFarmMemberships,
                        function (membership) {
                            return moment(membership.created);
                        },
                    ),
                );
                let mostRecentDocumentRequest = _.last(
                    _.orderBy(requests, function (documentRequest) {
                        return moment(documentRequest.created);
                    }),
                );
                let eventData = {
                    user: thisUser,
                    memberships: thisEmployeesGroupMemberships,
                    growerMembership: thisEmployeesGrowerMembership,
                    farm: _.find(farms, [
                        SID_LOOKUP,
                        mostRecentFarmMembership.farm,
                    ]),
                    documentRequests: requests,
                    mostRecentDocumentRequestDate: _.get(
                        mostRecentDocumentRequest,
                        "created",
                        mostRecentFarmMembership.date_added,
                    ),
                    completeDocumentRequests: _.filter(requests, [
                        "completed",
                        true,
                    ]),
                    incompleteDocumentRequests: _.filter(requests, [
                        "completed",
                        false,
                    ]),
                };
                events.push(eventData);
            });
            events = _.filter(events, function (eventData) {
                let filterResults = [];
                if ($transition$.params().crews.length > 0) {
                    filterResults.push(
                        !_.isEmpty(
                            _.intersection(
                                _.map(eventData.memberships, "group"),
                                $transition$.params().crews,
                            ),
                        ),
                    );
                }
                return _.every(filterResults);
            });
            return _.orderBy(
                events,
                function (eventData) {
                    return moment(eventData.mostRecentDocumentRequestDate);
                },
                "desc",
            );
        },
    },
    data: {
        title: "routes.GROWER_ONBOARDING_REPORT_TITLE",
        mixpanelEvent: "Viewed grower onboarding report",
        breadcrumb: "crumbs.GROWER_ONBOARDING_REPORT",
    },
};

export const growerDocumentReportState = {
    name: "app.growers.grower.manageOnboarding.report.documentReport",
    url: "/document-report",
    component: "companyDocumentReport",
    resolve: {
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.GROWER_DOCUMENT_REPORT_TITLE",
        mixpanelEvent: "Viewed grower detailed document report",
        breadcrumb: "crumbs.GROWER_DOCUMENT_REPORT",
    },
};

export const employeeInformationReportState = {
    name: "app.growers.grower.manageOnboarding.report.employeeInformationReport",
    url: "/employee-information-report",
    component: "employeeInformationReport",
    resolve: {
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.EMPLOYEE_INFORMATION_REPORT_TITLE",
        mixpanelEvent: "Viewed employee information report",
        breadcrumb: "crumbs.EMPLOYEE_INFORMATION_REPORT",
    },
};

export const onboardingDocumentListState = {
    name: "app.growers.grower.manageOnboarding.documents",
    url: "/documents",
    component: "onboardingDocumentList",
    data: {
        title: "routes.GROWER_ONBOARDING_DOCUMENT_LIST",
        mixpanelEvent: "Viewed onboarding document list",
        breadcrumb: "crumbs.GROWER_ONBOARDING_DOCUMENT_LIST",
    },
};

export const uploadOnboardingDocumentState = {
    name: "app.growers.grower.manageOnboarding.documents.upload",
    url: "/upload",
    component: "uploadOnboardingDocument",
    data: {
        title: "routes.GROWER_UPLOAD_ONBOARDING_DOCUMENT",
        mixpanelEvent: "Viewed upload onboarding document",
        breadcrumb: "crumbs.GROWER_UPLOAD_ONBOARDING_DOCUMENT",
    },
};

export const growerDocumentReportDownloadDocumentsState = {
    name: "app.growers.grower.manageOnboarding.report.documentReport.download",
    url: "/download-documents",
    params: {
        actionItems: {
            squash: true,
            inherit: false,
        },
    },
    component: "documentDownloader",
    resolve: {
        actionItems: function ($transition$) {
            "ngInject";
            return _.filter($transition$.params().actionItems, function (item) {
                return !_.isNil(item.document_package_record);
            });
        },
        redirectState: function () {
            "ngInject";
            return growerDocumentReportState;
        },
    },
    data: {
        title: "routes.DOWNLOAD_DOCUMENTS_TITLE",
        mixpanelEvent: "Viewed download documents",
        breadcrumb: "crumbs.GROWER_DOCUMENT_REPORT_DOWNLOAD_DOCUMENTS",
        permissions: {
            only: [CAN_EXPORT_DATA_GROWER],
        },
    },
    redirectTo: parentIfEmpty,
};

export const documentPackageRecordDataDownloadState = {
    name: "app.growers.grower.manageOnboarding.report.documentPackageRecordDataDownload",
    url: "/data-download",
    component: "documentPackageRecordDataDownload",
    data: {
        title: "routes.DOCUMENTPACKAGERECORD_DATA_DOWNLOAD_TITLE",
        mixpanelEvent: "Viewed documentpackagerecord data download",
        breadcrumb: "crumbs.DOCUMENTPACKAGERECORD_DATA_DOWNLOAD",
        permissions: {
            only: [CAN_EXPORT_DATA_GROWER],
        },
    },
};

export const growerExportEmployeeDataState = {
    name: "app.growers.grower.manageOnboarding.report.export",
    url: "/export",
    params: {
        actionItems: {
            squash: true,
            inherit: false,
        },
    },
    component: "companyExportEmployeeData",
    resolve: {
        actionItems: function ($transition$) {
            "ngInject";
            return $transition$.params().actionItems;
        },
        exportFormats: function (ExportFormatService, $transition$) {
            "ngInject";
            return ExportFormatService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        users: function (actionItems) {
            "ngInject";
            return _.map(actionItems, "user");
        },
    },
    data: {
        title: "routes.COMPANY_EXPORT_EMPLOYEE_DATA_TITLE",
        mixpanelEvent: "Viewed employee data export",
        breadcrumb: "crumbs.COMPANY_EXPORT_EMPLOYEE_DATA",
        permissions: {
            only: [CAN_EXPORT_DATA_GROWER],
            // redirectTo: growerOnboardingReportState.name,
        },
    },
    redirectTo: parentIfEmpty,
};

export const growerDownloadDocumentsState = {
    name: "app.growers.grower.manageOnboarding.report.download",
    url: "/download-documents",
    params: {
        actionItems: {
            squash: true,
            inherit: false,
        },
    },
    component: "downloadDocuments",
    resolve: {
        subjects: function ($transition$) {
            "ngInject";
            return _.map($transition$.params().actionItems, "user");
        },
        actionItems: function ($transition$) {
            "ngInject";
            return $transition$.params().actionItems;
        },
        redirectState: function () {
            "ngInject";
            return growerOnboardingReportState;
        },
    },
    data: {
        title: "routes.DOWNLOAD_DOCUMENTS_TITLE",
        mixpanelEvent: "Viewed download documents",
        breadcrumb: "crumbs.DOWNLOAD_DOCUMENTS",
        permissions: {
            only: [CAN_EXPORT_DATA_GROWER],
        },
    },
    redirectTo: parentIfEmpty,
};

export const growerManagementState = {
    name: "app.growers.grower.management",
    url: "/management",
    views: { lower: { component: "growerManagement" } },
    resolve: {
        managementMemberships: function (
            $transition$,
            GrowerManagementMembershipService,
        ) {
            "ngInject";
            return GrowerManagementMembershipService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        managerUsers: function (managementMemberships, AccountService) {
            "ngInject";
            return AccountService.several(_.map(managementMemberships, "user"));
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        management: function (
            growerMemberships,
            managerUsers,
            managementMemberships,
        ) {
            "ngInject";
            return _.map(managerUsers, function (user) {
                return {
                    user: user,
                    membership: _.find(managementMemberships, [
                        "user",
                        user.sid,
                    ]),
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
        users: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        selectableUsers: function (managerUsers, users) {
            "ngInject";
            return _.differenceBy(users, managerUsers, SID_LOOKUP);
        },
        workers: function (selectableUsers, growerMemberships) {
            "ngInject";
            return _.map(selectableUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.GROWER_MANAGEMENT_TITLE",
        permissions: {
            only: [EDIT_GROWER_ADMINS],
        },
        mixpanelEvent: "Viewed grower managers dashboard",
        breadcrumb: "crumbs.GROWER_MANAGEMENT",
    },
};

export const growerManagerCreateAccountState = {
    name: "app.growers.grower.management.createAccount",
    url: "/create-account",
    component: "growerManagementCreateAccount",
    resolve: {
        newMember: function ($transition$) {
            "ngInject";
            return {
                mobile: $transition$.params().mobile,
                preferred_language: SPANISH,
            };
        },
    },
    data: {
        title: "routes.GROWER_MANAGER_CREATE_ACCOUNT_TITLE",
        mixpanelEvent: "Viewed create grower manager account",
        // permissions: {
        //     only: function(transitionProperties) {
        //         return [
        //             createPermissionString(
        //                 transitionProperties.toParams.farm_sid,
        //                 "add_terminate_employees"
        //             )
        //         ];
        //     }
        // }
        breadcrumb: "crumbs.GROWER_MANAGER_CREATE_ACCOUNT",
    },
};

export const growerManagerEditState = {
    name: "app.growers.grower.management.edit",
    url: "/{management_membership_sid}/edit",
    component: "growerManagerEdit",
    resolve: {
        membership: function ($transition$, GrowerManagementMembershipService) {
            "ngInject";
            return GrowerManagementMembershipService.retrieve(
                $transition$.params().management_membership_sid,
            );
        },
        user: function (membership, AccountService) {
            "ngInject";
            return AccountService.retrieve(membership.user);
        },
        permissions: function (GrowerManagementPermissionService) {
            "ngInject";
            return GrowerManagementPermissionService.list();
        },
    },
    data: {
        title: "routes.GROWER_MANAGER_EDIT_TITLE",
        mixpanelEvent: "Viewed edit grower manager",
        breadcrumb: "crumbs.GROWER_MANAGER_EDIT",
    },
};

export const unassignedEmployeesState = {
    name: "app.growers.grower.unassignedEmployees",
    url: "/unassigned",
    views: { lower: { component: "unassignedEmployees" } },
    resolve: {
        growerMemberships: function ($transition$, GrowerMembershipService) {
            "ngInject";
            return GrowerMembershipService.list({
                grower__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        farmMemberships: function ($transition$, FarmMembershipService) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
        users: function (growerMemberships, AccountService, farmMemberships) {
            "ngInject";
            let unassignedEmployees = _.map(
                growerMemberships,
                function (membership) {
                    let employeesFarmMemberships = _.filter(farmMemberships, [
                        "user",
                        membership.user,
                    ]);
                    if (_.isEmpty(employeesFarmMemberships)) {
                        return membership.user;
                    }
                },
            );
            return AccountService.several(_.map(unassignedEmployees));
        },
        employees: function (growerMemberships, users, farmMemberships) {
            "ngInject";
            return _.map(users, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                    farmMemberships: _.filter(farmMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.UNASSIGNED_EMPLOYEE_TITLE",
        mixpanelEvent: "Viewed unassigned employees",
    },
};

export const syncResultsListState = {
    name: "app.growers.grower.syncResultsList",
    url: "/sync-history",
    views: { lower: { component: "syncResultsList" } },
    resolve: {
        records: function ($transition$, SyncRecordService) {
            "ngInject";
            return SyncRecordService.list({
                grower__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.SYNC_RESULTS_LIST_TITLE",
        mixpanelEvent: "Viewed sync results list",
        breadcrumb: "crumbs.SYNC_RESULTS_LIST",
    },
};

export const syncResultState = {
    name: "app.growers.grower.syncResultsList.view",
    url: "/{syncrecord_sid}",
    component: "syncResult",
    resolve: {
        syncRecord: function ($transition$, SyncRecordService) {
            "ngInject";
            return SyncRecordService.retrieve(
                $transition$.params().syncrecord_sid,
            );
        },
        removedUsers: function (syncRecord, AccountService) {
            "ngInject";
            return AccountService.several(syncRecord.old_employees);
        },
        removedGrowerMemberships: function (
            $transition$,
            GrowerMembershipService,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                syncs_where_deactivated: $transition$.params().syncrecord_sid,
            });
        },
        removedEmployees: function (removedUsers, removedGrowerMemberships) {
            "ngInject";
            return _.map(removedUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(removedGrowerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
        needRemovedUsers: function (syncRecord, AccountService) {
            "ngInject";
            return AccountService.several(syncRecord.need_deactivating);
        },
        needRemovedGrowerMemberships: function (
            $transition$,
            GrowerMembershipService,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                syncs_where_need_deactivating:
                    $transition$.params().syncrecord_sid,
            });
        },
        needRemovedEmployees: function (
            needRemovedUsers,
            needRemovedGrowerMemberships,
        ) {
            "ngInject";
            return _.map(needRemovedUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(needRemovedGrowerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
        reactivatedUsers: function (syncRecord, AccountService) {
            "ngInject";
            return AccountService.several(syncRecord.reactivated_employees);
        },
        reactivatedGrowerMemberships: function (
            $transition$,
            GrowerMembershipService,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                syncs_where_reactivated: $transition$.params().syncrecord_sid,
            });
        },
        newCrewMemberships: function ($transition$, MembershipService) {
            "ngInject";
            return MembershipService.list({
                active: true,
                group__farm__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        reactivatedEmployees: function (
            reactivatedUsers,
            reactivatedGrowerMemberships,
            newCrewMemberships,
        ) {
            "ngInject";
            return _.map(reactivatedUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(reactivatedGrowerMemberships, [
                        "user",
                        user.sid,
                    ]),
                    memberships: _.filter(newCrewMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
        newUsers: function (syncRecord, AccountService) {
            "ngInject";
            return AccountService.several(syncRecord.new_users);
        },
        newGrowerMemberships: function ($transition$, GrowerMembershipService) {
            "ngInject";
            return GrowerMembershipService.list({
                syncs_where_created: $transition$.params().syncrecord_sid,
            });
        },
        newEmployees: function (
            newUsers,
            newGrowerMemberships,
            newCrewMemberships,
        ) {
            "ngInject";
            return _.map(newUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(newGrowerMemberships, [
                        "user",
                        user.sid,
                    ]),
                    memberships: _.filter(newCrewMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.SYNC_RESULTS_TITLE",
        mixpanelEvent: "Viewed sync results",
        breadcrumb: "crumbs.SYNC_RESULTS",
    },
};

export const growerReactivateEmployeesState = {
    name: "app.growers.grower.reactivate",
    url: "/reactivate?user",
    views: { lower: { component: "growerReactivateEmployee" } },
    resolvePolicy: { when: "EAGER" },
    resolve: {
        user: function ($transition$, AccountService) {
            "ngInject";
            return AccountService.retrieve($transition$.params().user);
        },
        growerMembership: function ($transition$, GrowerMembershipService) {
            "ngInject";
            return GrowerMembershipService.first(
                {
                    grower_organization__sid: $transition$.params().grower_sid,
                    user__sid: $transition$.params().user,
                },
                "end_date",
                false,
            );
        },
        employee: function (growerMembership, user) {
            "ngInject";
            return {
                user: user,
                growerMembership: growerMembership,
            };
        },
    },
    data: {
        title: "routes.GROWER_REACTIVATE_EMPLOYEES_TITLE",
        mixpanelEvent: "Viewed reactivate employee",
    },
    redirectTo: parentIfNoUser,
};

export const growerEditState = {
    name: "app.growers.grower.edit",
    url: "/edit?scrollTo",
    views: { lower: { component: "growerEdit" } },
    lazyLoad: function ($transition$) {
        return lazyLoad($transition$, CLOUDINARY_LIB_URL);
    },
    resolve: {
        svixUrl: function ($transition$, SvixDashboardService) {
            "ngInject";
            return SvixDashboardService.getUrl(
                $transition$.params().grower_sid,
            ).then(
                function (data) {
                    return data;
                },
                function (error) {
                    return null;
                },
            );
        },
        timeTrackingVendors: function (TimeTrackingVendorService) {
            "ngInject";
            return TimeTrackingVendorService.list();
        },
    },
    data: {
        title: "routes.GROWER_EDIT_TITLE",
        mixpanelEvent: "Viewed grower edit",
        permissions: {
            only: [EDIT_GROWER_ORGANIZATION],
        },
        breadcrumb: "crumbs.REACTIVATE",
    },
};

export const farmCreateState = {
    name: "app.growers.grower.create",
    url: "/create",
    component: "farmCreate",
    resolve: {
        crops: function (CropService) {
            "ngInject";
            return CropService.list();
        },
    },
    data: {
        title: "routes.FARM_CREATE_TITLE",
        mixpanelEvent: "Viewed farm create",
        permissions: {
            only: [CREATE_FARM],
        },
    },
};

export const farmDashboardState = {
    name: "app.growers.grower.farm",
    url: "/{farm_sid}",
    component: "farmDashboard",
    redirectTo: "app.growers.grower.farm.manage",
    resolve: {
        farm: function (farms, $transition$) {
            "ngInject";
            return _.find(farms, [SID_LOOKUP, $transition$.params().farm_sid]);
        },
    },
    data: {
        title: "routes.FARM_DASHBOARD_TITLE",
        mixpanelEvent: "Viewed farm dashboard",
    },
};

export const complianceExpertsState = {
    name: "app.growers.grower.complianceExperts",
    url: "/compliance-experts",
    views: { lower: { component: "complianceExperts" } },
    data: {
        title: "routes.COMPLIANCE_EXPERTS_TITLE",
        mixpanelEvent: "Viewed compliance experts",
    },
};

export const manageRosterState = {
    name: "app.growers.grower.farm.manage",
    url: "?order_by?search?active_status",
    params: {
        order_by: {
            dynamic: true,
            value: "name",
            squash: true,
            inherit: false,
        },
        search: {
            dynamic: true,
            inherit: false,
        },
        active_status: {
            value: EMPLOYEE_ACTIVE,
        },
    },
    views: { lower: { component: "manage" } },
    resolvePolicy: { when: "EAGER" },
    resolve: {
        groups: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        groupFarms: function (FarmService, groups) {
            "ngInject";
            return FarmService.several(_.map(groups, "farm"));
        },
        managers: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                manager__farm__sid: $transition$.params().farm_sid,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            if ($transition$.params().active_status === EMPLOYEE_ACTIVE) {
                return FarmMembershipService.list({
                    farm__sid: $transition$.params().farm_sid,
                    active: true,
                });
            } else {
                return [];
            }
        },
        memberships: function (MembershipService, $transition$) {
            "ngInject";
            if ($transition$.params().active_status === EMPLOYEE_ACTIVE) {
                return MembershipService.list({
                    group__grower_organization__sid:
                        $transition$.params().grower_sid,
                    active: true,
                });
            } else {
                return [];
            }
        },
        membershipsWithGroups: function (memberships, groups, groupFarms) {
            "ngInject";
            return _.map(memberships, function (membershipOriginal) {
                let membership = _.cloneDeep(membershipOriginal);
                let group = _.cloneDeep(
                    _.find(groups, [SID_LOOKUP, membership.group]),
                );
                if (group && group.farm) {
                    let foundFarm = _.find(groupFarms, [
                        SID_LOOKUP,
                        group.farm,
                    ]);
                    group.farm = foundFarm;
                }
                membership.group = group;
                return membership;
            });
        },
        users: function (AccountService, $transition$) {
            "ngInject";
            let params;
            if ($transition$.params().active_status === EMPLOYEE_ACTIVE) {
                params = {
                    farm__sid: $transition$.params().farm_sid,
                };
            } else {
                params = {
                    growermembership__grower_organization__sid:
                        $transition$.params().grower_sid,
                };
            }
            return AccountService.list(params);
        },
        incompleteDocumentPackageRequests: function (
            DocumentPackageRequestService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageRequestService.list({
                requester__sid: $transition$.params().grower_sid,
                completed: false,
                farm__sid: $transition$.params().farm_sid,
                cancelled: false,
                omit: "data",
            });
        },
        signatureRequestsForIncompleteDocumentPackageRequests: function (
            SignatureRequestService,
            $transition$,
        ) {
            "ngInject";
            return SignatureRequestService.list({
                document_package_request__requester__sid:
                    $transition$.params().grower_sid,
                document_package_request__completed: false,
                document_package_request__cancelled: false,
            });
        },
        documentPackages: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        incompletePaperworkRequests: function (
            incompleteDocumentPackageRequests,
            signatureRequestsForIncompleteDocumentPackageRequests,
            managers,
            documentPackages,
        ) {
            "ngInject";
            let sigRequests =
                signatureRequestsForIncompleteDocumentPackageRequests;

            let paperworks = _.map(
                incompleteDocumentPackageRequests,
                function (request) {
                    let signatureRequests = _.filter(sigRequests, [
                        "document_package_request",
                        request.sid,
                    ]);

                    let pendingSignatureRequests = _.filter(signatureRequests, [
                        "status",
                        PAPERWORK_PENDING,
                    ]);
                    let pendingEmployerSignatureRequests = _.filter(
                        pendingSignatureRequests,
                        ["role", INITIATOR],
                    );
                    let pendingEmployeeSignatureRequests = _.filter(
                        pendingSignatureRequests,
                        ["role", SIGNER],
                    );
                    let pendingExternalSignatureRequests = _.filter(
                        pendingSignatureRequests,
                        ["role", RECORD_SOURCE_EXTERNAL],
                    );

                    let employerSigners = [];
                    _.forEach(managers, function (manager) {
                        if (
                            _.includes(
                                _.map(
                                    pendingEmployerSignatureRequests,
                                    "signer",
                                ),
                                manager.sid,
                            )
                        ) {
                            employerSigners.push(manager);
                        }
                    });
                    let documentPackage = _.find(documentPackages, [
                        SID_LOOKUP,
                        request.document_package,
                    ]);
                    return {
                        documentPackageRequest: request,
                        signatureRequests: signatureRequests,
                        employerSigners: employerSigners,
                        employeeNeedsToSign:
                            pendingEmployeeSignatureRequests.length > 0,
                        notStarted: signatureRequests.length === 0,
                        externalNeedsToSign:
                            pendingExternalSignatureRequests.length > 0,
                        nextRole: getNextRole(request, documentPackage),
                    };
                },
            );

            let groupedPaperwork = _.groupBy(
                paperworks,
                "documentPackageRequest.signer",
            );

            let paperworkSummary = {};
            _.forEach(groupedPaperwork, function (paperwork, key) {
                paperworkSummary[key] = {
                    paperwork: paperwork,
                    employeeNeedsToSign: _.some(
                        paperwork,
                        "employeeNeedsToSign",
                    ),
                    employerNeedsToSign: _.some(paperwork, function (value) {
                        return value.nextRole === INITIATOR;
                    }),
                    externalNeedsToSign: _.some(
                        paperwork,
                        "externalNeedsToSign",
                    ),
                    employerSigners: _.uniqBy(
                        _.flatten(
                            _.map(
                                _.filter(paperwork, [
                                    "employeeNeedsToSign",
                                    false,
                                ]),
                                "employerSigners",
                            ),
                        ),
                        SID_LOOKUP,
                    ),
                    notStarted: _.some(paperwork, "notStarted"),
                };
            });
            return paperworkSummary;
        },
        incompleteSafetyMeetingRequests: function (
            SafetyMeetingRequestService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                safety_meeting__farm__sid: $transition$.params().farm_sid,
                status: SAFETY_PENDING,
            });
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            let params = {
                grower_organization__sid: $transition$.params().grower_sid,
                active: $transition$.params().active_status === EMPLOYEE_ACTIVE,
            };
            if ($transition$.params().active_status === EMPLOYEE_ACTIVE) {
                params.farm__sid = $transition$.params().farm_sid;
            }
            return GrowerMembershipService.list(params);
        },
        rosterMembers: function (
            farmMemberships,
            memberships,
            users,
            incompletePaperworkRequests,
            incompleteSafetyMeetingRequests,
            growerMemberships,
            membershipsWithGroups,
        ) {
            "ngInject";
            return _.map(growerMemberships, function (growerMembership) {
                let user = _.find(users, [SID_LOOKUP, growerMembership.user]);
                return {
                    growerMembership: growerMembership,
                    user: user,
                    farmMembership: _.find(farmMemberships, [
                        "user",
                        growerMembership.user,
                    ]),
                    memberships: _.filter(memberships, [
                        "user",
                        growerMembership.user,
                    ]),
                    incompletePaperworkRequests:
                        incompletePaperworkRequests[growerMembership.user],
                    incompleteSafetyMeetingRequests: _.filter(
                        incompleteSafetyMeetingRequests,
                        ["subject", growerMembership.user],
                    ),
                    membershipsWithGroups: _.filter(membershipsWithGroups, [
                        "user",
                        growerMembership.user,
                    ]),
                };
            });
        },
        searchTerm: function ($transition$) {
            "ngInject";
            return $transition$.params().search;
        },
        algoliaParams: function (GrowerMembershipService) {
            "ngInject";
            return GrowerMembershipService.getAlgoliaKey();
        },
    },
    data: {
        title: "routes.MANAGE_ROSTER_TITLE",
        mixpanelEvent: "Viewed employee dashboard",
        breadcrumb: "crumbs.MANAGE_ROSTER",
    },
};

export const memberState = {
    name: "app.growers.grower.farm.manage.member",
    url: "/{member_sid}",
    component: "member",
    resolvePolicy: { when: "EAGER" },
    resolve: {
        user: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.retrieve($transition$.params().member_sid);
        },
        growerMembership: function ($transition$, GrowerMembershipService) {
            "ngInject";
            return GrowerMembershipService.first(
                {
                    grower_organization__sid: $transition$.params().grower_sid,
                    user__sid: $transition$.params().member_sid,
                },
                "end_date",
                false,
            );
        },
        groupMemberships: function (MembershipService, $transition$) {
            "ngInject";
            return MembershipService.list({
                active: true,
                group__grower_organization__sid:
                    $transition$.params().grower_sid,
                user__sid: $transition$.params().member_sid,
            });
        },
        incompletePaperworkRequests: function (
            DocumentPackageRequestService,
            $transition$,
            growerMembership,
        ) {
            "ngInject";
            return DocumentPackageRequestService.list({
                requester__sid: $transition$.params().grower_sid,
                completed: false,
                grower_membership__sid: growerMembership.sid,
                cancelled: false,
            });
        },
        allSignatureRequestsRaw: function (
            SignatureRequestService,
            $transition$,
            growerMembership,
        ) {
            "ngInject";
            return SignatureRequestService.list({
                document_package_request__grower_membership__sid:
                    growerMembership.sid,
                document_package_request__completed: false,
                document_package_request__requester__sid:
                    $transition$.params().grower_sid,
            });
        },
        documentPackages: function (
            DocumentPackageService,
            incompletePaperworkRequests,
        ) {
            "ngInject";
            return DocumentPackageService.several(
                _.map(incompletePaperworkRequests, "document_package"),
            );
        },
        signingUsers: function (AccountService, allSignatureRequestsRaw) {
            "ngInject";
            return AccountService.several(
                _.uniq(_.map(allSignatureRequestsRaw, "signer")),
            );
        },
        customizations: function (
            DocumentPackageCustomizationService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageCustomizationService.list({
                owner__sid: $transition$.params().grower_sid,
            });
        },
        allPaperwork: function (
            allSignatureRequestsRaw,
            incompletePaperworkRequests,
            documentPackages,
            signingUsers,
            customizations,
        ) {
            "ngInject";
            let paperworks = _.map(
                incompletePaperworkRequests,
                function (documentPackageRequest) {
                    let paperwork = {
                        documentPackageRequest,
                        signatureRequests: _.filter(allSignatureRequestsRaw, {
                            document_package_request:
                                documentPackageRequest.sid,
                        }),
                        documentPackage: _.find(documentPackages, [
                            SID_LOOKUP,
                            documentPackageRequest.document_package,
                        ]),
                    };
                    if (documentPackageRequest.document_package_customization) {
                        paperwork.customization = _.find(customizations, [
                            SID_LOOKUP,
                            documentPackageRequest.document_package_customization,
                        ]);
                    }
                    paperwork.nextRole = getNextRole(
                        paperwork.documentPackageRequest,
                        paperwork.documentPackage,
                    );
                    paperwork.processing =
                        !paperwork.nextRole &&
                        paperwork.signatureRequests.length > 0 &&
                        _.every(paperwork.signatureRequests, [
                            "status",
                            PAPERWORK_COMPLETE,
                        ]);
                    paperwork.nextSignatureRequest = _.find(
                        allSignatureRequestsRaw,
                        [
                            SID_LOOKUP,
                            documentPackageRequest.next_pending_signature_request,
                        ],
                    );
                    if (paperwork.nextSignatureRequest) {
                        paperwork.nextSigner = _.find(signingUsers, [
                            SID_LOOKUP,
                            paperwork.nextSignatureRequest.signer,
                        ]);
                    } else {
                        paperwork.nextSigner = undefined;
                    }
                    return paperwork;
                },
            );
            return _.orderBy(paperworks, "documentPackageRequest.created");
        },
        farmMembership: function (farmMemberships, $transition$) {
            "ngInject";
            return _.find(farmMemberships, [
                "user",
                $transition$.params().member_sid,
            ]);
        },
        subjectIncompleteSafetyMeetingRequests: function (
            SafetyMeetingRequestService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                subject__sid: $transition$.params().member_sid,
                safety_meeting__farm__sid: $transition$.params().farm_sid,
                status: SAFETY_PENDING,
            });
        },
        safetyMeetingTopics: function (
            SafetyMeetingTopicService,
            subjectIncompleteSafetyMeetingRequests,
        ) {
            "ngInject";
            return SafetyMeetingTopicService.several(
                _.map(subjectIncompleteSafetyMeetingRequests, "topic"),
            );
        },
        incompleteSafetyMeetings(
            subjectIncompleteSafetyMeetingRequests,
            SafetyMeetingService,
        ) {
            "ngInject";
            return SafetyMeetingService.several(
                _.map(subjectIncompleteSafetyMeetingRequests, "safety_meeting"),
            );
        },
        subjectIncompleteSafetyMeetings(
            subjectIncompleteSafetyMeetingRequests,
            incompleteSafetyMeetings,
            safetyMeetingTopics,
        ) {
            "ngInject";
            return _.map(
                subjectIncompleteSafetyMeetingRequests,
                function (request) {
                    return {
                        request: request,
                        meeting: _.find(incompleteSafetyMeetings, [
                            SID_LOOKUP,
                            request.safety_meeting,
                        ]),
                        topic: _.find(safetyMeetingTopics, [
                            SID_LOOKUP,
                            request.topic,
                        ]),
                    };
                },
            );
        },
        trainingRequirements: function (
            TrainingRequirementService,
            growerMembership,
        ) {
            "ngInject";
            return TrainingRequirementService.several(
                growerMembership.training_requirements,
            );
        },
        credentials: function (CredentialService, $transition$) {
            "ngInject";
            return CredentialService.list({
                subject__sid: $transition$.params().member_sid,
                archived: false,
            });
        },
        credentialTypes: function (CredentialTypeService, credentials) {
            "ngInject";
            return CredentialTypeService.several(
                _.map(credentials, "credential_type"),
            );
        },
        credentialsData: function (credentials, credentialTypes) {
            "ngInject";
            return _.map(credentials, function (credential) {
                return {
                    credential: credential,
                    credentialType: _.find(credentialTypes, [
                        SID_LOOKUP,
                        credential.credential_type,
                    ]),
                };
            });
        },
        identificationDocuments: function (
            IdentificationDocumentService,
            $transition$,
            growerMembership,
        ) {
            "ngInject";
            return IdentificationDocumentService.head(
                {
                    grower_membership__sid: growerMembership.sid,
                    owner__sid: growerMembership.grower_organization,
                    archived: false,
                },
                "created",
                4,
                false,
            );
        },
        identificationDocumentTypes: function (
            IdentificationDocumentTypeService,
            identificationDocuments,
        ) {
            "ngInject";
            return IdentificationDocumentTypeService.several(
                _.map(identificationDocuments, "document_type"),
            );
        },
        identificationDocumentsData: function (
            identificationDocuments,
            identificationDocumentTypes,
        ) {
            "ngInject";
            return _.map(identificationDocuments, function (document) {
                return {
                    document: document,
                    documentType: _.find(identificationDocumentTypes, [
                        SID_LOOKUP,
                        document.document_type,
                    ]),
                };
            });
        },
        timeTrackingEmployeeConfigurations: function (
            TimeTrackingEmployeeConfigurationService,
            growerMembership,
        ) {
            "ngInject";
            return TimeTrackingEmployeeConfigurationService.list({
                grower_membership__sid: growerMembership.sid,
            });
        },
        housingAssignments: function (
            HousingAssignmentService,
            growerMembership,
        ) {
            "ngInject";
            return HousingAssignmentService.list({
                active: true,
                grower_membership__sid: growerMembership.sid,
            });
        },
        housingSites: function (HousingSiteService, housingAssignments) {
            "ngInject";
            return HousingSiteService.several(
                _.map(housingAssignments, "housing_site"),
            );
        },
        memberHousingAssignments: function (housingAssignments, housingSites) {
            "ngInject";
            return _.map(housingAssignments, function (assignment) {
                return {
                    assignment,
                    housingSite: _.find(housingSites, [
                        SID_LOOKUP,
                        assignment.housing_site,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.MEMBER_TITLE",
        mixpanelEvent: "Viewed employee",
        breadcrumb: "crumbs.MEMBER",
    },
};

// TODO: OK to move to grower level
export const manageCommunicationState = {
    name: "app.growers.grower.manageCommunication",
    url: "/communication",
    lazyLoad: function ($transition$) {
        return lazyLoad($transition$, CLOUDINARY_LIB_URL);
    },
    views: { lower: { component: "manageCommunication" } },
    resolve: {
        alertThemes: function (AlertThemeService) {
            "ngInject";
            return AlertThemeService.list({
                category: EMPLOYEE_ENGAGEMENT_CATEGORY,
            });
        },
        alertSubscriptions: function (AlertSubscriptionService, $transition$) {
            "ngInject";
            return AlertSubscriptionService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.MANAGE_COMMUNICATION_TITLE",
        // permissions: {
        //     only: [MANAGE_COMMUNICATION], // Renable after permissions are migrated from farm level to grower level
        // },
        mixpanelEvent: "Viewed communication dashboard",
        breadcrumb: "crumbs.MANAGE_COMMUNICATION",
    },
};

// TODO: OK to move to grower level
export const conversationListState = {
    name: "app.growers.grower.manageCommunication.conversations",
    url: "/conversations?state?owner",
    component: "conversationList",
    resolve: {
        conversations: function (ConversationService, $transition$) {
            "ngInject";
            let params = {
                grower_organization__sid: $transition$.params().grower_sid,
                state: $transition$.params().state,
            };
            return ConversationService.list(params);
        },
        participants: function (AccountService, conversations) {
            "ngInject";
            let owners = _.map(conversations, "owner");
            let contacts = _.map(conversations, "contact");
            return AccountService.several(_.concat(owners, contacts));
        },
        beams: function (BeamsService, account) {
            "ngInject";
            BeamsService.initializeForConversations(account.sid);
        },
        twilioClient: function (ConversationService) {
            "ngInject";
            return ConversationService.createTwilioClient();
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.CONVERSATION_LIST_TITLE",
        mixpanelEvent: "Viewed conversations list",
        breadcrumb: "crumbs.CONVERSATION_LIST",
    },
};

// TODO: OK to move to grower level
export const conversationCreateState = {
    name: "app.growers.grower.manageCommunication.conversations.create",
    url: "/create",
    params: {
        contact: {
            squash: true,
        },
        is_anonymous: {
            squash: true,
            value: false,
        },
        quote: {
            squash: true,
            value: false,
        },
    },
    component: "conversationCreate",
    resolve: {
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        employees: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        workers: function (employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
        conversation: function ($transition$) {
            "ngInject";
            return {
                contact: $transition$.params().contact,
                is_anonymous: $transition$.params().is_anonymous,
            };
        },
        quote: function ($transition$) {
            "ngInject";
            return $transition$.params().quote;
        },
    },
    data: {
        title: "routes.CONVERSATION_CREATE_TITLE",
        mixpanelEvent: "Viewed create conversation",
        breadcrumb: "crumbs.CONVERSATION_CREATE",
    },
};

// TODO: OK to move to grower level
export const conversationState = {
    name: "app.growers.grower.manageCommunication.conversations.conversation",
    url: "/{conversation_sid}",
    params: {
        quote: {
            squash: true,
            value: false,
        },
    },
    component: "conversation",
    resolve: {
        conversation: function (ConversationService, $transition$) {
            "ngInject";
            return ConversationService.retrieve(
                $transition$.params().conversation_sid,
            );
        },
        addedParticipant: function (conversation) {
            "ngInject";
            return conversation.addParticipant();
        },
        twilioClientConversation: function (
            ConversationService,
            addedParticipant,
        ) {
            "ngInject";
            return ConversationService.createTwilioClient();
        },
        twilioConversation: function (twilioClientConversation, conversation) {
            "ngInject";
            return twilioClientConversation.getConversationBySid(
                conversation.conversation_sid,
            );
        },
        participants: function (AccountService, conversation) {
            "ngInject";
            return AccountService.several([
                conversation.contact,
                conversation.owner,
            ]);
        },
        contact: function (participants, conversation) {
            "ngInject";
            return _.find(participants, [SID_LOOKUP, conversation.contact]);
        },
        owner: function (participants, conversation) {
            "ngInject";
            return _.find(participants, [SID_LOOKUP, conversation.owner]);
        },
        messages: function () {
            "ngInject";
            return [];
        },
        growerMemberships: function (
            GrowerMembershipService,
            $transition$,
            conversation,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                user__sid: conversation.contact,
            });
        },
        contactWorker: function (growerMemberships, contact, conversation) {
            "ngInject";
            let growerMembership = getLatestMembership(
                growerMemberships,
                contact.sid,
            );
            return {
                user: contact,
                growerMembership,
                displayName: getContactWorkerDisplayName(
                    conversation,
                    growerMembership,
                ),
            };
        },
        newMessage: function ($transition$) {
            "ngInject";
            let quote = $transition$.params().quote;
            let newMessage = {};
            if (quote) {
                newMessage.raw = '"' + quote + '"';
            }
            return newMessage;
        },
        conversationNotes: function (ConversationNoteService, $transition$) {
            "ngInject";
            return ConversationNoteService.list({
                conversation__sid: $transition$.params().conversation_sid,
            });
        },
        translations: function (
            ConversationNoteTranslatedService,
            conversationNotes,
        ) {
            "ngInject";
            let translationSids = _.flatten(
                _.map(conversationNotes, "translations"),
            );
            return ConversationNoteTranslatedService.several(translationSids);
        },
        authors: function (AccountService, conversationNotes) {
            "ngInject";
            let authorSids = _.map(conversationNotes, "author");
            return AccountService.several(authorSids);
        },
        notes: function (conversationNotes, translations, $translate, authors) {
            "ngInject";
            return _.map(conversationNotes, function (note) {
                return {
                    note,
                    toShow: _.find(translations, {
                        conversation_note: note.sid,
                        language: $translate.use(),
                    }),
                    original: _.find(translations, {
                        conversation_note: note.sid,
                        original_language: true,
                    }),
                    author: _.find(authors, [SID_LOOKUP, note.author]),
                };
            });
        },
    },
    data: {
        title: "routes.CONVERSATION_TITLE",
        mixpanelEvent: "Viewed conversation",
        breadcrumb: "crumbs.CONVERSATION",
    },
};

// TODO: OK to move to grower level
export const conversationEditState = {
    name: "app.growers.grower.manageCommunication.conversations.conversation.edit",
    url: "/edit",
    views: { conversationEdit: { component: "conversationEdit" } },
    resolve: {
        growerManagementMemberships: function (
            GrowerManagementMembershipService,
            $transition$,
        ) {
            "ngInject";
            return GrowerManagementMembershipService.list({
                grower__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        managerUsers: function (growerManagementMemberships, AccountService) {
            "ngInject";
            return AccountService.several(
                _.map(growerManagementMemberships, "user"),
            );
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        managers: function (
            growerManagementMemberships,
            managerUsers,
            growerMemberships,
        ) {
            "ngInject";
            return _.map(managerUsers, function (user) {
                return {
                    user: user,
                    growerManagementMembership: _.find(
                        growerManagementMemberships,
                        ["user", user.sid],
                    ),
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.CONVERSATION_EDIT_TITLE",
        mixpanelEvent: "Viewed edit conversation",
        breadcrumb: "crumbs.CONVERSATION_EDIT",
    },
};

// TODO: OK to move to grower level
export const conversationNoteCreateState = {
    name: "app.growers.grower.manageCommunication.conversations.conversation.note",
    url: "/add-note",
    views: { note: { component: "conversationNoteCreate" } },
    data: {
        title: "routes.CONVERSATION_NOTE_CREATE_TITLE",
        mixpanelEvent: "Viewed conversation note create",
        breadcrumb: "crumbs.CONVERSATION_NOTE_CREATE",
    },
};

// TODO: OK to move to grower level
export const communicationHistoryState = {
    name: "app.growers.grower.manageCommunication.history",
    url: "/messages?sender?has_location?has_attachments?status?timetable",
    params: {
        has_location: {
            type: "bool",
        },
        has_attachments: {
            type: "bool",
        },
        status: {
            value: SENT_STATUS,
        },
    },
    component: "announcementList",
    resolve: {
        announcementObjects: function (AnnouncementService, $transition$) {
            "ngInject";
            let transitionParams = $transition$.params();
            let params = {
                active: true,
                grower_organization__sid: transitionParams.grower_sid,
                status: transitionParams.status,
                timetable: transitionParams.timetable,
                omit: "recipients",
            };
            let senderSid = transitionParams.sender;
            if (!_.isNil(senderSid)) {
                params.sender__sid = senderSid;
            }
            let hasAttachments = transitionParams.has_attachments;
            if (!_.isNil(hasAttachments)) {
                params.attachments__isnull = !hasAttachments;
            }
            let hasLocation = transitionParams.has_location;
            if (!_.isNil(hasLocation)) {
                params.location_lat__isnull = !hasLocation;
                params.location_lng__isnull = !hasLocation;
            }
            return AnnouncementService.list(params);
        },
        translateds: function (
            announcementObjects,
            AnnouncementTRANSLATEDService,
        ) {
            "ngInject";
            return AnnouncementTRANSLATEDService.several(
                _.flatten(_.map(announcementObjects, "translations")),
            );
        },
        senders: function (announcementObjects, AccountService) {
            "ngInject";
            return AccountService.several(
                _.map(announcementObjects, "sender"),
            ).then(function (senders) {
                return _.keyBy(senders, SID_LOOKUP);
            });
        },
        managers: function ($transition$, AccountService) {
            "ngInject";
            return AccountService.list({
                grower_manager__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        status: function ($transition$) {
            "ngInject";
            return $transition$.params().status;
        },
        farmsFromAnnouncements: function (announcementObjects, FarmService) {
            "ngInject";
            return FarmService.several(
                _.compact(_.uniq(_.map(announcementObjects, "farm"))),
            );
        },
    },
    data: {
        title: "routes.COMMUNICATION_HISTORY_TITLE",
        mixpanelEvent: "Viewed previous messages list",
        breadcrumb: "crumbs.COMMUNICATION_HISTORY",
    },
};

// TODO: OK to move to grower level
export const communicationReportState = {
    name: "app.growers.grower.manageCommunication.communicationReport",
    url: "/report?crew?mobile_status?mobile?scrollTo?order_by",
    params: {
        order_by: {
            value: "date_added",
        },
        crew: {
            array: true,
        },
    },
    component: "communicationReport",
    resolve: {
        groups: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        memberships: function (MembershipService, $transition$) {
            "ngInject";
            return MembershipService.list({
                group__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
        users: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        rosterMembers: function (
            $transition$,
            memberships,
            users,
            growerMemberships,
        ) {
            "ngInject";
            let collection = _.map(users, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                    memberships: _.filter(memberships, ["user", user.sid]),
                };
            });

            collection = urlParamFilter(
                collection,
                COMMUNICATION_LIST_FILTERS,
                $transition$.params(),
            );
            return urlParamSort(
                collection,
                SORTS,
                $transition$.params().order_by,
            );
        },
    },
    data: {
        title: "routes.COMMUNICATION_REPORT_TITLE",
        mixpanelEvent: "Created communications report",
        breadcrumb: "crumbs.COMMUNICATION_REPORT",
    },
};

// TODO: OK to move to grower level
export const announcementDetailState = {
    name: "app.growers.grower.manageCommunication.history.view",
    url: "/{announcement_sid}",
    component: "announcementDetail",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
                {},
                {
                    expand: ["translations", "sender"],
                },
            );
        },
        farm: function (FarmService, announcement) {
            "ngInject";
            return FarmService.retrieve(announcement.farm);
        },
        messages: function (MessageService, $transition$) {
            "ngInject";
            return MessageService.list({
                announcement__sid: $transition$.params().announcement_sid,
            });
        },
        recipients: function ($transition$, AccountService) {
            "ngInject";
            return AccountService.list({
                announcement__sid: $transition$.params().announcement_sid,
            });
        },
        messageItems: function (messages, recipients) {
            "ngInject";
            return _.map(messages, function (message) {
                message.recipient = _.find(recipients, [
                    SID_LOOKUP,
                    message.recipient,
                ]);
                return message;
            });
        },
        attachments: function (AttachmentService, $transition$) {
            "ngInject";
            return AttachmentService.list({
                announcement__sid: $transition$.params().announcement_sid,
            }).then(function (results) {
                return _.groupBy(results, "language");
            });
        },
    },
    data: {
        title: "routes.ANNOUNCEMENT_DETAIL_TITLE",
        mixpanelEvent: "Viewed announcement detail",
        breadcrumb: "crumbs.ANNOUNCEMENT_DETAIL",
    },
};

export const manageSafetyState = {
    name: "app.growers.grower.manageSafety",
    url: "/safety",
    views: { lower: { component: "manageSafety" } },
    resolve: {
        comments: function (CommentService, $transition$) {
            "ngInject";
            return CommentService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                category: WORKER_COMMENT,
                important: true,
            });
        },
        translations: function (CommentTRANSLATEDService, comments) {
            "ngInject";
            let translationSids = _.flatten(_.map(comments, "translations"));
            return CommentTRANSLATEDService.several(translationSids);
        },
        feedback: function (comments, translations, $translate) {
            "ngInject";
            return _.map(comments, function (comment) {
                return {
                    comment: comment,
                    toShow: _.find(translations, {
                        comment: comment.sid,
                        language: $translate.use(),
                    }),
                    original: _.find(translations, {
                        comment: comment.sid,
                        original_language: true,
                    }),
                };
            });
        },
        accidentReports: function (AccidentReportService, $transition$) {
            "ngInject";
            return AccidentReportService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                archived: false,
            });
        },
        accidentReportEmployees: function (AccountService, accidentReports) {
            "ngInject";
            return AccountService.several(_.map(accidentReports, "employee"));
        },
        recentAccidentReports: function (
            accidentReports,
            accidentReportEmployees,
            moment,
        ) {
            "ngInject";
            let recentReports = _.filter(accidentReports, function (report) {
                return moment(report.created).isAfter(
                    moment().subtract(1, "month"),
                );
            });
            return _.map(recentReports, function (report) {
                return {
                    report: report,
                    employee: _.find(accidentReportEmployees, [
                        SID_LOOKUP,
                        report.employee,
                    ]),
                };
            });
        },
        safetyMeetingProgram: function (
            SafetyMeetingProgramService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingProgramService.first({
                active: true,
                administrator__growerorganization__sid:
                    $transition$.params().grower_sid,
            });
        },
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        subscriptions: function (
            SafetyMeetingProgramSubscriptionService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingProgramSubscriptionService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            }).then(function (subscriptions) {
                return _.keyBy(subscriptions, "safety_meeting_program");
            });
        },
        alertThemes: function (AlertThemeService) {
            "ngInject";
            return AlertThemeService.list({ category: COMPLIANCE_CATEGORY });
        },
        alertSubscriptions: function (AlertSubscriptionService, $transition$) {
            "ngInject";
            return AlertSubscriptionService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.MANAGE_SAFETY_TITLE",
        // permissions: {
        //     only: [MANAGE_SAFETY],
        // },
        mixpanelEvent: "Viewed safety dashboard",
        breadcrumb: "crumbs.MANAGE_SAFETY",
    },
};

export const safetyMeetingProgramsState = {
    name: "app.growers.grower.manageSafety.programs",
    url: "/safety-meeting-programs",
    component: "safetyMeetingProgramList",
    resolve: {
        safetyMeetingPrograms: function (
            SafetyMeetingProgramService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingProgramService.list({
                active: true,
                administrator__growerorganization__sid:
                    $transition$.params().grower_sid,
            });
        },
        safetyMeetingProgramElements: function (
            SafetyMeetingProgramElementService,
        ) {
            "ngInject";
            return SafetyMeetingProgramElementService.list().then(
                function (elements) {
                    return _.groupBy(elements, "safety_meeting_program");
                },
            );
        },
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        subscriptions: function (
            SafetyMeetingProgramSubscriptionService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingProgramSubscriptionService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            }).then(function (subscriptions) {
                return _.keyBy(subscriptions, "safety_meeting_program");
            });
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_PROGRAM_LIST_TITLE",
        mixpanelEvent: "Viewed safety meeting program list",
        breadcrumb: "crumbs.SAFETY_MEETING_PROGRAM_LIST",
    },
};

export const safetyReportState = {
    name: "app.growers.grower.manageSafety.trainingReport",
    url: "/training-report",
    component: "trainingReport",
    resolve: {
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        memberships: function (MembershipService, $transition$) {
            "ngInject";
            return MembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        users: function (AccountService, farmMemberships) {
            "ngInject";
            return AccountService.several(_.map(farmMemberships, "user"));
        },
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        topics: function (SafetyMeetingTopicService, $transition$) {
            "ngInject";
            return SafetyMeetingTopicService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        trainingRequirements: function (
            TrainingRequirementService,
            $transition$,
        ) {
            "ngInject";
            return TrainingRequirementService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
        courses: function (SafetyCourseService, $transition$) {
            "ngInject";
            return SafetyCourseService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.SAFETY_REPORT_TITLE",
        // permissions: {
        //     only: [MANAGE_SAFETY],
        // },
        mixpanelEvent: "Viewed safety report",
        breadcrumb: "crumbs.SAFETY_REPORT",
    },
};

export const trainingTimeReportState = {
    name: "app.growers.grower.manageSafety.trainingTime",
    url: "/time-report",
    component: "trainingTime",
    data: {
        title: "routes.TRAINING_TIME_REPORT_TITLE",
        // permissions: {
        //     only: [MANAGE_SAFETY],
        // },
        mixpanelEvent: "Viewed training time report",
        breadcrumb: "crumbs.TRAINING_TIME_REPORT",
    },
};

export const safetyCommentsState = {
    name: "app.growers.grower.manageSafety.comments",
    url: "/safety-feedback?important",
    params: {
        important: {
            type: "bool",
            value: true,
        },
    },
    component: "feedbackList",
    resolve: {
        comments: function (CommentService, $transition$) {
            "ngInject";
            return CommentService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                category: WORKER_COMMENT,
                important: $transition$.params().important,
            });
        },
        translations: function (CommentTRANSLATEDService, comments) {
            "ngInject";
            let translationSids = _.flatten(_.map(comments, "translations"));
            return CommentTRANSLATEDService.several(translationSids);
        },
        feedback: function (comments, translations, $translate) {
            "ngInject";
            return _.map(comments, function (comment) {
                return {
                    comment: comment,
                    toShow: _.find(translations, {
                        comment: comment.sid,
                        language: $translate.use(),
                    }),
                    original: _.find(translations, {
                        comment: comment.sid,
                        original_language: true,
                    }),
                };
            });
        },
    },
    data: {
        title: "routes.SAFETY_COMMENTS_TITLE",
        mixpanelEvent: "Viewed safety comments",
        breadcrumb: "crumbs.SAFETY_COMMENTS",
    },
};

export const accidentLogState = {
    name: "app.growers.grower.manageSafety.accidentLog",
    url: "/accident-log",
    component: "accidentLog",
    resolve: {
        reports: function (AccidentReportService, $transition$) {
            "ngInject";
            return AccidentReportService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                archived: false,
            });
        },
        employees: function (AccountService, reports) {
            "ngInject";
            return AccountService.several(_.map(reports, "employee"));
        },
        accidentReports: function (reports, employees) {
            "ngInject";
            return _.map(reports, function (report) {
                return {
                    report: report,
                    employee: _.find(employees, [SID_LOOKUP, report.employee]),
                };
            });
        },
    },

    data: {
        title: "routes.ACCIDENT_LOG_TITLE",
        mixpanelEvent: "Viewed accident log",
        breadcrumb: "crumbs.ACCIDENT_LOG",
    },
};

export const downloadOSHALogState = {
    name: "app.growers.grower.manageSafety.accidentLog.download",
    url: "/download-osha-log",
    component: "downloadOSHALog",
    data: {
        title: "routes.DOWNLOAD_OSHA_LOG_TITLE",
        mixpanelEvent: "Viewed download accident log",
        breadcrumb: "crumbs.DOWNLOAD_OSHA_LOG",
    },
};

export const viewAccidentReportState = {
    name: "app.growers.grower.manageSafety.accidentLog.view",
    url: "/{report_sid}",
    component: "accidentReport",
    resolve: {
        report: function (AccidentReportService, $transition$) {
            "ngInject";
            return AccidentReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
        translations: function (
            AccidentReportTranslatedService,
            $translate,
            $transition$,
        ) {
            "ngInject";
            return AccidentReportTranslatedService.list({
                report__sid: $transition$.params().report_sid,
            }).then(function (translations) {
                return {
                    display: _.find(translations, [
                        "language",
                        $translate.use(),
                    ]),
                    original: _.find(translations, "original_language"),
                };
            });
        },
        employee: function (AccountService, report) {
            "ngInject";
            return AccountService.retrieve(report.employee);
        },
        author: function (AccountService, report) {
            "ngInject";
            return AccountService.retrieve(report.author);
        },
    },
    data: {
        title: "routes.VIEW_ACCIDENT_REPORT_TITLE",
        mixpanelEvent: "Viewed accident report",
        breadcrumb: "crumbs.VIEW_ACCIDENT_REPORT",
    },
};

export const createAccidentReportState = {
    name: "app.growers.grower.manageSafety.accidentLog.new",
    url: "/new-accident-report",
    component: "createAccidentReport",
    resolve: {
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        users: function (AccountService, farmMemberships) {
            "ngInject";
            return AccountService.several(_.map(farmMemberships, "user"));
        },
        employees: function (growerMemberships, farmMemberships, users) {
            "ngInject";
            return _.map(users, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
    },
    data: {
        title: "routes.CREATE_ACCIDENT_REPORT_TITLE",
        mixpanelEvent: "Viewed new accident report",
        breadcrumb: "crumbs.CREATE_ACCIDENT_REPORT",
    },
};

export const createAccidentReportBaseState = {
    name: "app.growers.grower.manageSafety.accidentLog.view.base",
    abstract: true,
    component: "accidentReportEditBase",
    redirectTo: viewAccidentReportState,
};

export const accidentReportMetaInformationState = {
    name: "app.growers.grower.manageSafety.accidentLog.view.base.metaInformation",
    url: "/basic-information",
    component: "accidentReportMetaInformation",
    resolve: {
        prefillData: function (report) {
            "ngInject";
            return report.prefill();
        },
    },
    data: {
        title: "routes.ACCIDENT_REPORT_META_INFORMATION_TITLE",
        mixpanelEvent: "Viewed accident report basic information",
    },
};

export const accidentReportEmployeeInformationState = {
    name: "app.growers.grower.manageSafety.accidentLog.view.base.employeeInformation",
    url: "/employee-information",
    component: "accidentReportEmployeeInformation",
    resolve: {
        prefillData: function (report) {
            "ngInject";
            return report.prefill();
        },
    },
    data: {
        title: "routes.ACCIDENT_REPORT_EMPLOYEE_INFORMATION_TITLE",
        mixpanelEvent: "Viewed accident report employee information",
    },
};

export const accidentReportAccidentInformationState = {
    name: "app.growers.grower.manageSafety.accidentLog.view.base.accidentInformation",
    url: "/accident-information",
    component: "accidentReportAccidentInformation",
    data: {
        title: "routes.ACCIDENT_REPORT_ACCIDENT_INFORMATION_TITLE",
        mixpanelEvent: "Viewed accident report accident information",
    },
};

export const accidentReportHealthCareInformationState = {
    name: "app.growers.grower.manageSafety.accidentLog.view.base.healthcareInformation",
    url: "/healthcare-information",
    component: "accidentReportHealthCareInformation",
    data: {
        title: "routes.ACCIDENT_REPORT_HEALTH_CARE_INFORMATION_TITLE",
        mixpanelEvent: "Viewed accident report health care information",
    },
};

export const safetyMeetingsState = {
    name: "app.growers.grower.manageSafety.meetings",
    url: "/safety-meetings?search?category?delivery_type?status?crew",
    params: {
        order_by: {
            value: "start_date",
            squash: true,
        },
        search: {
            dynamic: true,
        },
        status: {
            value: IN_PROGRESS_MEETING_STATUS,
        },
        crew: {
            array: true,
        },
    },
    component: "meetingList",
    resolve: {
        safetyMeetingTopics: function (
            SafetyMeetingTopicService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingTopicService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        courses: function (SafetyCourseService, $transition$) {
            "ngInject";
            return SafetyCourseService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        meetingsRaw: function (SafetyMeetingService, $transition$) {
            "ngInject";
            return SafetyMeetingService.list({
                organizer__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        groups: function (GroupService, meetingsRaw) {
            "ngInject";
            return GroupService.several(_.flatten(_.map(meetingsRaw, "crews")));
        },
        meetings: function (
            SafetyMeetingService,
            moment,
            $transition$,
            safetyMeetingTopics,
            courses,
            meetingsRaw,
            groups,
        ) {
            "ngInject";
            let meetings = _.map(meetingsRaw, function (meeting) {
                return {
                    topics: _.filter(safetyMeetingTopics, function (topic) {
                        return _.includes(meeting.topics, topic.sid);
                    }),
                    meeting: meeting,
                    courses: _.filter(courses, function (course) {
                        return _.includes(meeting.courses, course.sid);
                    }),
                    crews: _.filter(groups, function (group) {
                        return _.includes(meeting.crews, group.sid);
                    }),
                };
            });
            return _.map(meetings, function (meeting) {
                meeting.meeting.is_future = moment(
                    meeting.meeting.start_date,
                ).isAfter(moment());
                return meeting;
            });
        },
    },
    data: {
        title: "routes.SAFETY_MEETINGS_TITLE",
        mixpanelEvent: "Viewed safety meeting list",
        breadcrumb: "crumbs.SAFETY_MEETINGS",
    },
};

export const topicsState = {
    name: "app.growers.grower.manageSafety.topics",
    url: "/topics?search?administrator?category",
    params: {
        search: {
            dynamic: true,
        },
        administrator: {
            array: true,
        },
    },
    component: "topicList",
    resolve: {
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        topics: function (SafetyMeetingTopicService, $transition$) {
            "ngInject";
            return SafetyMeetingTopicService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.TOPICS_TITLE",
        mixpanelEvent: "Viewed topics list",
        breadcrumb: "crumbs.TOPICS",
    },
};

export const viewTopicState = {
    name: "app.growers.grower.manageSafety.topics.view",
    url: "/{topic_sid}",
    component: "safetyMeetingTopic",
    resolve: {
        topic: function (SafetyMeetingTopicService, $transition$) {
            "ngInject";
            return SafetyMeetingTopicService.retrieve(
                $transition$.params().topic_sid,
            );
        },
        translateds: function (
            SafetyMeetingTopicTRANSLATEDService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingTopicTRANSLATEDService.list({
                safety_meeting_topic__sid: $transition$.params().topic_sid,
            });
        },
    },
    data: {
        title: "routes.VIEW_TOPIC_TITLE",
        mixpanelEvent: "Viewed topic",
        breadcrumb: "crumbs.VIEW_TOPIC",
    },
};

export const topicCreateState = {
    name: "app.growers.grower.manageSafety.topics.create",
    url: "/create",
    component: "topicCreate",
    data: {
        title: "routes.TOPIC_CREATE_TITLE",
        mixpanelEvent: "Viewed training topic create",
        breadcrumb: "crumbs.TOPIC_CREATE",
    },
};

export const topicEditState = {
    name: "app.growers.grower.manageSafety.topics.view.edit",
    url: "/{topic_sid}/edit",
    component: "topicEdit",
    lazyLoad: function ($transition$) {
        return lazyLoad($transition$, CLOUDINARY_LIB_URL);
    },
    resolve: {
        topic: function ($transition$, SafetyMeetingTopicService) {
            "ngInject";
            return SafetyMeetingTopicService.retrieve(
                $transition$.params().topic_sid,
            );
        },
        translateds: function (
            $transition$,
            SafetyMeetingTopicTRANSLATEDService,
        ) {
            "ngInject";
            return SafetyMeetingTopicTRANSLATEDService.list({
                safety_meeting_topic__sid: $transition$.params().topic_sid,
            });
        },
    },
    data: {
        title: "routes.TOPIC_EDIT_TITLE",
        mixpanelEvent: "Viewed topic edit",
        breadcrumb: "crumbs.TOPIC_EDIT",
    },
};

export const courseListState = {
    name: "app.growers.grower.manageSafety.courses",
    url: "/courses",
    component: "courseList",
    resolve: {
        topics: function (SafetyMeetingTopicService, $transition$) {
            "ngInject";
            return SafetyMeetingTopicService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        courses: function (SafetyCourseService, $transition$) {
            "ngInject";
            return SafetyCourseService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.COURSE_LIST_TITLE",
        mixpanelEvent: "Viewed courses list",
        breadcrumb: "crumbs.COURSE_LIST",
    },
};

export const trainingRequirementListState = {
    name: "app.growers.grower.manageSafety.trainingRequirements",
    url: "/training-requirements",
    component: "trainingRequirementList",
    resolve: {
        topics: function (SafetyMeetingTopicService, $transition$) {
            "ngInject";
            return SafetyMeetingTopicService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        topicData: function (topics, administrators, $transition$) {
            "ngInject";
            let collection = _.map(topics, function (topic) {
                return {
                    topic: topic,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        topic.administrator,
                    ]),
                };
            });
            return urlParamFilter(
                collection,
                TOPIC_FILTERS,
                $transition$.params(),
            );
        },
        trainingRequirements: function (
            TrainingRequirementService,
            $transition$,
        ) {
            "ngInject";
            return TrainingRequirementService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
        trainingRequirementsData: function (trainingRequirements, topics) {
            "ngInject";
            return _.map(trainingRequirements, function (requirement) {
                return {
                    requirement: requirement,
                    topics: _.filter(topics, function (topic) {
                        return _.includes(requirement.topics, topic.sid);
                    }),
                };
            });
        },
    },
    data: {
        title: "routes.TRAINING_REQUIREMENT_LIST_TITLE",
        mixpanelEvent: "Viewed training requirements list",
        breadcrumb: "crumbs.TRAINING_REQUIREMENT_LIST",
    },
};

export const safetyMeetingCreateState = {
    name: "app.growers.grower.manageSafety.createMeeting",
    url: "/create?category?courses?topics",
    params: {
        additional_participants: {
            array: true,
            value: [],
        },
        courses: {
            array: true,
            value: [],
        },
        topics: {
            array: true,
            value: [],
        },
        //     category: {
        //         value: SAFE,
        //     },
    },
    component: "createSafetyMeeting",
    resolve: {
        groups: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        topics: function (SafetyMeetingTopicService, $transition$) {
            "ngInject";
            return SafetyMeetingTopicService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        topicData: function (topics, administrators) {
            "ngInject";
            return _.map(topics, function (topic) {
                return {
                    topic: topic,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        topic.administrator,
                    ]),
                };
            });
        },
        courses: function (SafetyCourseService, $transition$) {
            "ngInject";
            return SafetyCourseService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        courseData: function (courses, administrators, topics) {
            "ngInject";
            return _.map(courses, function (course) {
                return {
                    course: course,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        course.administrator,
                    ]),
                    topics: _.filter(topics, function (topic) {
                        return _.includes(course.topics, topic.sid);
                    }),
                };
            });
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
        employees: function (AccountService, farmMemberships) {
            "ngInject";
            return AccountService.several(_.map(farmMemberships, "user"));
        },
        workers: function (farmMemberships, employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
        newMeeting: function ($transition$) {
            "ngInject";
            return {
                timetable: NOW,
                delivery_type: REMOTE,
                category: $transition$.params().category,
                additional_participants:
                    $transition$.params().additional_participants,
                crews: [],
                topics: $transition$.params().topics,
                courses: $transition$.params().courses,
            };
        },
        wpsCredentials: function ($transition$, CredentialService) {
            "ngInject";
            return CredentialService.list({
                archived: false,
                credential_type__wps_qualified: true,
                expiration_date__gt: moment().format(DATE_FORMAT),
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        wpsCredentialedUsers: function (wpsCredentials, AccountService) {
            "ngInject";
            let wpsTrainerUserSids = _.map(wpsCredentials, "subject");
            return AccountService.several(wpsTrainerUserSids);
        },
        wpsCredentialedGrowerMemberships: function (
            wpsCredentials,
            GrowerMembershipService,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                user__sid__in: _.join(_.map(wpsCredentials, "subject")),
            });
        },
        wpsTrainers: function (
            wpsCredentialedUsers,
            wpsCredentialedGrowerMemberships,
        ) {
            "ngInject";
            return _.map(wpsCredentialedUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(wpsCredentialedGrowerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_CREATE_TITLE",
        mixpanelEvent: "Viewed safety meeting create",
        breadcrumb: "crumbs.SAFETY_MEETING_CREATE",
    },
};

export const safetyMeetingEditState = {
    name: "app.growers.grower.manageSafety.editMeeting",
    url: "/{meeting_sid}/edit",
    component: "editSafetyMeeting",
    resolve: {
        groups: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        meeting: function ($transition$, SafetyMeetingService) {
            "ngInject";
            return SafetyMeetingService.retrieve(
                $transition$.params().meeting_sid,
            );
        },
        topics: function (SafetyMeetingTopicService, $transition$) {
            "ngInject";
            return SafetyMeetingTopicService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        topicData: function (topics, administrators) {
            "ngInject";
            return _.map(topics, function (topic) {
                return {
                    topic: topic,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        topic.administrator,
                    ]),
                };
            });
        },
        courses: function (SafetyCourseService, $transition$) {
            "ngInject";
            return SafetyCourseService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        courseData: function (courses, administrators, topics) {
            "ngInject";
            return _.map(courses, function (course) {
                return {
                    course: course,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        course.administrator,
                    ]),
                    topics: _.filter(topics, function (topic) {
                        return _.includes(course.topics, topic.sid);
                    }),
                };
            });
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                farm__sid: $transition$.params().farm_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
        employees: function (AccountService, farmMemberships) {
            "ngInject";
            return AccountService.several(_.map(farmMemberships, "user"));
        },
        workers: function (farmMemberships, employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
        wpsCredentials: function ($transition$, CredentialService) {
            "ngInject";
            return CredentialService.list({
                archived: false,
                credential_type__wps_qualified: true,
                expiration_date__gt: moment().format(DATE_FORMAT),
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        wpsCredentialedUsers: function (
            $transition$,
            wpsCredentials,
            AccountService,
        ) {
            "ngInject";
            let wpsTrainerUserSids = _.map(wpsCredentials, "subject");
            return AccountService.several(wpsTrainerUserSids);
        },
        wpsTrainers: function (wpsCredentialedUsers) {
            "ngInject";
            return _.map(wpsCredentialedUsers, function (user) {
                return {
                    user: user,
                };
            });
        },
    },
    redirectTo: (trans) => {
        let meetingResolve = trans.injector().getAsync("meeting");
        return meetingResolve.then((meeting) => {
            if (moment(meeting.start_date) < moment()) {
                let $state = trans.injector().get("$state");
                return $state.target(
                    safetyMeetingsState,
                    { grower_sid: meeting.organizer },
                    { reload: safetyMeetingsState },
                );
            }
        });
    },
    data: {
        title: "routes.SAFETY_MEETING_EDIT_TITLE",
        mixpanelEvent: "Viewed safety meeting edit",
        breadcrumb: "crumbs.SAFETY_MEETING_EDIT",
    },
};

export const manageSafetyMeetingState = {
    name: "app.growers.grower.manageSafety.meetings.manage",
    url: "/{meeting_sid}",
    component: "manageMeeting",
    resolve: {
        meeting: function ($transition$, SafetyMeetingService) {
            "ngInject";
            return SafetyMeetingService.retrieve(
                $transition$.params().meeting_sid,
            );
        },
        topics: function (SafetyMeetingTopicService, meeting) {
            "ngInject";
            return SafetyMeetingTopicService.several(meeting.topics);
        },
        requests: function (SafetyMeetingRequestService, $transition$) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                safety_meeting__sid: $transition$.params().meeting_sid,
            });
        },
        participants: function (requests, AccountService) {
            "ngInject";
            return AccountService.several(_.map(requests, "subject"));
        },
        crews: function (GroupService, meeting) {
            "ngInject";
            return GroupService.several(meeting.crews);
        },
        targetParticipants: function (
            meeting,
            MembershipService,
            crews,
            $q,
            AccountService,
        ) {
            "ngInject";
            let pastMembers = MembershipService.list({
                group__sid__in: meeting.crews,
                created_before: meeting.start_date,
                end_date_after: meeting.start_date,
                active: false,
            });
            let currentMembers = MembershipService.list({
                group__sid__in: meeting.crews,
                created_before: meeting.start_date,
                end_date__isnull: true,
                active: true,
            });
            return $q
                .all([pastMembers, currentMembers])
                .then(_.flatten)
                .then(function (responses) {
                    return _.map(responses, "user");
                })
                .then(_.uniq)
                .then(function (userSids) {
                    return AccountService.several(userSids);
                });
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        scheduler: function (meeting, growerMemberships) {
            "ngInject";
            return _.find(growerMemberships, ["user", meeting.scheduler]);
        },
        nonParticipants: function (
            participants,
            targetParticipants,
            growerMemberships,
        ) {
            "ngInject";
            let users = _.differenceBy(targetParticipants, participants, "sid");
            return _.map(users, function (user) {
                return {
                    user: user,
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
        signatures: function (SignatureService, requests) {
            "ngInject";
            return SignatureService.several(_.map(requests, "signature"));
        },
        requestStatuses: function (
            requests,
            participants,
            crews,
            growerMemberships,
            signatures,
        ) {
            "ngInject";
            return _.map(participants, function (participant) {
                let participantsRequests = _.filter(requests, [
                    "subject",
                    participant.sid,
                ]);
                for (let request of participantsRequests) {
                    request.signature = _.find(signatures, [
                        SID_LOOKUP,
                        request.signature,
                    ]);
                }
                return {
                    requests: participantsRequests,
                    participant: participant,
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        participant.sid,
                    ),
                    pending: _.some(participantsRequests, [
                        "status",
                        SAFETY_PENDING,
                    ]),
                    complete: _.every(participantsRequests, [
                        "status",
                        COMPLETE,
                    ]),
                    cancelled: _.every(participantsRequests, [
                        "status",
                        CANCELLED,
                    ]),
                };
            });
        },
        pending: function (requestStatuses) {
            "ngInject";
            return _.filter(requestStatuses, "pending");
        },
        completed: function (requestStatuses) {
            "ngInject";
            return _.filter(requestStatuses, "complete");
        },
        cancelled: function (requestStatuses) {
            "ngInject";
            return _.filter(requestStatuses, "cancelled");
        },
        allComments: function (CommentService, $transition$) {
            "ngInject";
            return CommentService.list({
                safety_meeting__sid: $transition$.params().meeting_sid,
            });
        },
        comments: function (allComments) {
            "ngInject";
            return _.filter(allComments, ["category", WORKER_COMMENT]);
        },
        translations: function (CommentTRANSLATEDService, comments) {
            "ngInject";
            let translationSids = _.flatten(_.map(comments, "translations"));
            return CommentTRANSLATEDService.several(translationSids);
        },
        feedback: function (comments, translations, $translate) {
            "ngInject";
            let feedback = {
                important: [],
                hidden: [],
            };
            for (let comment of comments) {
                let reformattedComment = {
                    comment: comment,
                    toShow: _.find(translations, {
                        comment: comment.sid,
                        language: $translate.use(),
                    }),
                    original: _.find(translations, {
                        comment: comment.sid,
                        original_language: true,
                    }),
                };
                if (comment.important) {
                    feedback.important.push(reformattedComment);
                } else {
                    feedback.hidden.push(reformattedComment);
                }
            }
            return feedback;
        },
        notesRaw: function (allComments) {
            "ngInject";
            return _.filter(allComments, ["category", NOTE]);
        },
        notesTranslations: function (CommentTRANSLATEDService, notesRaw) {
            "ngInject";
            let translationSids = _.flatten(_.map(notesRaw, "translations"));
            return CommentTRANSLATEDService.several(translationSids);
        },
        notesData: function (notesRaw, notesTranslations, $translate) {
            "ngInject";
            return _.map(notesRaw, function (comment) {
                return {
                    comment: comment,
                    toShow: _.find(notesTranslations, {
                        comment: comment.sid,
                        language: $translate.use(),
                    }),
                    original: _.find(notesTranslations, {
                        comment: comment.sid,
                        original_language: true,
                    }),
                };
            });
        },
        wpsTrainer: function (meeting, growerMemberships) {
            "ngInject";
            return _.find(growerMemberships, ["user", meeting.wps_trainer]);
        },
    },
    data: {
        title: "routes.MANAGE_SAFETY_MEETING_TITLE",
        mixpanelEvent: "Viewed manage safety meeting",
        breadcrumb: "crumbs.MANAGE_SAFETY_MEETING",
    },
};

export const safetyMeetingFieldReportState = {
    name: "app.growers.grower.manageSafety.meetings.manage.fieldReport",
    url: "/field-report",
    resolve: {
        memberships: function (MembershipService, meeting, $transition$) {
            "ngInject";
            return MembershipService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
                user__safetymeetingrequest__safety_meeting__sid:
                    $transition$.params().meeting_sid,
            });
        },
        pendingWithMemberships: function (pending, memberships) {
            "ngInject";
            return _.map(pending, function (status) {
                status.memberships = _.filter(memberships, [
                    "user",
                    status.participant.sid,
                ]);
                return status;
            });
        },
        pendingByCrew: function (pendingWithMemberships, crews) {
            "ngInject";
            let results = {};
            _.forEach(crews, function (crew) {
                results[crew.sid] = _.filter(
                    pendingWithMemberships,
                    function (status) {
                        return _.includes(
                            _.uniq(_.map(status.memberships, "group")),
                            crew.sid,
                        );
                    },
                );
            });
            return results;
        },
        pendingOther: function (pendingWithMemberships, crews) {
            "ngInject";
            return _.filter(pendingWithMemberships, function (status) {
                let participantCrewSids = _.map(status.memberships, "group");
                let meetingCrewSids = _.map(crews, SID_LOOKUP);
                return (
                    _.difference(participantCrewSids, meetingCrewSids)
                        .length === participantCrewSids.length
                );
            });
        },
    },
    component: "fieldReport",
    data: {
        title: "routes.SAFETY_MEETING_FIELD_REPORT_TITLE",
        mixpanelEvent: "Viewed safety meeting field report",
        breadcrumb: "crumbs.SAFETY_MEETING_FIELD_REPORT",
    },
};

export const safetyMeetingNotesState = {
    name: "app.growers.grower.manageSafety.meetings.manage.notes",
    url: "/notes",
    component: "meetingNotes",
    data: {
        title: "routes.SAFETY_MEETING_NOTES_TITLE",
        mixpanelEvent: "Viewed safety meeting notes",
        breadcrumb: "crumbs.SAFETY_MEETING_NOTES",
    },
};

export const safetyMeetingDownloadState = {
    name: "app.growers.grower.manageSafety.meetings.manage.download",
    url: "/download",
    component: "downloadMeetingReport",
    data: {
        title: "routes.SAFETY_MEETING_DOWNLOAD_TITLE",
        mixpanelEvent: "Viewed safety meeting download",
        breadcrumb: "crumbs.SAFETY_MEETING_DOWNLOAD",
    },
};

export const safetyMeetingFinishState = {
    name: "app.growers.grower.manageSafety.meetings.manage.finish",
    url: "/finish",
    component: "safetyMeetingFinish",
    data: {
        title: "routes.SAFETY_MEETING_FINISH_TITLE",
        mixpanelEvent: "Viewed safety meeting finish",
        breadcrumb: "crumbs.SAFETY_MEETING_FINISH",
    },
};

export const safetyMeetingMessageState = {
    name: "app.growers.grower.manageSafety.meetings.manage.message",
    url: "/message",
    component: "safetyMeetingMessage",
    data: {
        title: "routes.SAFETY_MEETING_MESSAGE_TITLE",
        mixpanelEvent: "Viewed safety meeting message",
        breadcrumb: "crumbs.SAFETY_MEETING_MESSAGE",
    },
};

export const sendCrewBossAttendanceLinkState = {
    name: "app.growers.grower.manageSafety.meetings.manage.sendCrewBossAttendanceLink",
    url: "/crew-boss-attendance",
    component: "sendCrewBossAttendanceLink",
    resolve: {
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
        employees: function (AccountService, farmMemberships) {
            "ngInject";
            return AccountService.several(_.map(farmMemberships, "user"));
        },
        workers: function (farmMemberships, employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_SEND_CREW_BOSS_ATTENDANCE_TITLE",
        mixpanelEvent: "Viewed send attendance link to supervisors",
    },
};

export const safetyMeetingAddParticipantState = {
    name: "app.growers.grower.manageSafety.meetings.manage.addParticipant",
    url: "/add-participant",
    component: "addParticipant",
    resolve: {
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
        employees: function (AccountService, farmMemberships) {
            "ngInject";
            return AccountService.several(_.map(farmMemberships, "user"));
        },
        workers: function (farmMemberships, employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_ADD_PARTICIPANT_TITLE",
        mixpanelEvent: "Viewed add participant to safety meeting",
        breadcrumb: "crumbs.SAFETY_MEETING_ADD_PARTICIPANT",
    },
};

export const safetyMeetingScanAttendanceState = {
    name: "app.growers.grower.manageSafety.meetings.manage.scanAttendance",
    url: "/scan-attendance",
    component: "scanAttendance",
    resolve: {
        redirectState: function () {
            "ngInject";
            return manageSafetyMeetingState;
        },
        manualState: function () {
            "ngInject";
            return safetyMeetingManualAttendanceState;
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_TAKE_ATTENDANCE_TITLE",
        mixpanelEvent: "Viewed take attendance to safety meeting",
        breadcrumb: "crumbs.SAFETY_MEETING_TAKE_ATTENDANCE",
    },
};

export const safetyMeetingManualAttendanceState = {
    name: "app.growers.grower.manageSafety.meetings.manage.manualAttendance",
    url: "/manual-attendance",
    component: "manualAttendance",
    resolve: {
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
        employees: function (AccountService, farmMemberships) {
            "ngInject";
            return AccountService.several(_.map(farmMemberships, "user"));
        },
        workers: function (farmMemberships, employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
        redirectState: function () {
            "ngInject";
            return manageSafetyMeetingState;
        },
        searchState: function () {
            "ngInject";
            return safetyMeetingScanAttendanceState;
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_BACKUP_SEARCH_TITLE",
        mixpanelEvent: "Viewed safety meeting manual attendance",
        breadcrumb: "crumbs.SAFETY_MEETING_BACKUP_SEARCH",
    },
};

export const safetyMeetingRequestSignState = {
    name: "app.growers.grower.manageSafety.meetings.manage.sign",
    url: "/{participant_sid}/sign",
    component: "safetyMeetingSign",
    resolve: {
        requests: function ($transition$, SafetyMeetingRequestService) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                subject__sid: $transition$.params().participant_sid,
                safety_meeting__sid: $transition$.params().meeting_sid,
            });
        },
        participant: function ($transition$, AccountService) {
            "ngInject";
            return AccountService.retrieve(
                $transition$.params().participant_sid,
            );
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_REQUEST_SIGN_TITLE",
        mixpanelEvent: "Viewed safety meeting sign",
        breadcrumb: "crumbs.SAFETY_MEETING_REQUEST_SIGN",
    },
};

export const packetCreateState = {
    name: "app.growers.grower.manageOnboarding.createPacket",
    url: "/packets/create",
    component: "packetCreate",
    resolve: {
        documents: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                accessor__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        documentData: function (documents, administrators) {
            "ngInject";
            return _.map(documents, function (document) {
                return {
                    document: document,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        document.administrator,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.PACKET_CREATE_TITLE",
        mixpanelEvent: "Viewed packet create",
        breadcrumb: "crumbs.PACKET_CREATE",
    },
};

export const packetEditState = {
    name: "app.growers.grower.manageOnboarding.packetEdit",
    url: "/packets/{packet_sid}/edit",
    component: "packetEdit",
    resolve: {
        packet: function ($transition$, PacketService) {
            "ngInject";
            return PacketService.retrieve($transition$.params().packet_sid);
        },
        documents: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                accessor__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        customizations: function (
            DocumentPackageCustomizationService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageCustomizationService.list({
                owner__sid: $transition$.params().grower_sid,
            });
        },
        packetMembers: function (PacketMemberService, $transition$) {
            "ngInject";
            return PacketMemberService.list({
                packet__sid: $transition$.params().packet_sid,
                active: true,
            });
        },
    },
    data: {
        title: "routes.PACKET_EDIT_TITLE",
        mixpanelEvent: "Viewed packet edit",
        breadcrumb: "crumbs.PACKET_EDIT",
    },
};

export const packetMemberEditState = {
    name: "app.growers.grower.manageOnboarding.packetEdit.packetMemberEdit",
    url: "/{packet_member_sid}/edit",
    component: "packetMemberEdit",
    resolve: {
        packetMember: function ($transition$, PacketMemberService) {
            "ngInject";
            return PacketMemberService.retrieve(
                $transition$.params().packet_member_sid,
            );
        },
        documentPackage: function (DocumentPackageService, packetMember) {
            "ngInject";
            return DocumentPackageService.retrieve(
                packetMember.document_package,
            );
        },
        customization: function (
            DocumentPackageCustomizationService,
            packetMember,
        ) {
            "ngInject";
            if (packetMember.document_package_customization) {
                return DocumentPackageCustomizationService.retrieve(
                    packetMember.document_package_customization,
                );
            }
        },
    },
    data: {
        title: "routes.PACKET_MEMBER_EDIT_TITLE",
        mixpanelEvent: "Viewed packet member edit",
        breadcrumb: "crumbs.PACKET_MEMBER_EDIT",
    },
};

export const courseCreateState = {
    name: "app.growers.grower.manageSafety.courses.createCourse",
    url: "/courses/create",
    component: "courseCreate",
    resolve: {
        topicData: function (topics, administrators) {
            "ngInject";
            return _.map(topics, function (topic) {
                return {
                    topic: topic,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        topic.administrator,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.COURSE_CREATE_TITLE",
        mixpanelEvent: "Viewed course create",
        breadcrumb: "crumbs.COURSE_CREATE",
    },
};

export const courseEditState = {
    name: "app.growers.grower.manageSafety.courses.courseEdit",
    url: "/courses/{course_sid}/edit",
    component: "courseEdit",
    resolve: {
        course: function ($transition$, SafetyCourseService) {
            "ngInject";
            return SafetyCourseService.retrieve(
                $transition$.params().course_sid,
            );
        },
        topicData: function (topics, administrators) {
            "ngInject";
            return _.map(topics, function (topic) {
                return {
                    topic: topic,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        topic.administrator,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.COURSE_EDIT_TITLE",
        mixpanelEvent: "Viewed course edit",
        breadcrumb: "crumbs.COURSE_EDIT",
    },
};

export const trainingRequirementCreateState = {
    name: "app.growers.grower.manageSafety.trainingRequirements.trainingRequirementCreate",
    url: "/training-requirements/create",
    views: {
        trainingRequirementListView: { component: "trainingRequirementCreate" },
    },
    data: {
        title: "routes.TRAINING_REQUIREMENT_CREATE_TITLE",
        mixpanelEvent: "Viewed training requirement create",
        breadcrumb: "crumbs.TRAINING_REQUIREMENT_CREATE",
    },
};

export const trainingRequirementEditState = {
    name: "app.growers.grower.manageSafety.trainingRequirements.trainingRequirementEdit",
    url: "/training-requirements/{requirement_sid}/edit",
    views: {
        trainingRequirementListView: { component: "trainingRequirementEdit" },
    },
    resolve: {
        requirement: function ($transition$, TrainingRequirementService) {
            "ngInject";
            return TrainingRequirementService.retrieve(
                $transition$.params().requirement_sid,
            );
        },
    },
    data: {
        title: "routes.TRAINING_REQUIREMENT_EDIT_TITLE",
        mixpanelEvent: "Viewed training requirement edit",
        breadcrumb: "crumbs.TRAINING_REQUIREMENT_EDIT",
    },
};

export const manageCrewsState = {
    name: "app.growers.grower.manageCrews",
    url: "/crews",
    views: { lower: { component: "manageCrews" } },
    resolve: {
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        farms: function (FarmService, $transition$) {
            "ngInject";
            return FarmService.list({
                active: true,
                visible: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.MANAGE_CREWS_TITLE",
        mixpanelEvent: "Viewed crews dashboard",
        breadcrumb: "crumbs.MANAGE_CREWS",
    },
};

export const crewCreateState = {
    name: "app.growers.grower.manageCrews.createCrew",
    url: "/create",
    component: "crewCreate",
    data: {
        title: "routes.CREW_CREATE_TITLE",
        mixpanelEvent: "Viewed crew create",
        breadcrumb: "crumbs.CREW_CREATE",
    },
};

export const crewEditState = {
    name: "app.growers.grower.manageCrews.crewEdit",
    url: "/{crew_sid}/edit",
    component: "crewEdit",
    resolve: {
        group: function ($transition$, GroupService) {
            "ngInject";
            return GroupService.retrieve($transition$.params().crew_sid);
        },
    },
    data: {
        title: "routes.CREW_EDIT_TITLE",
        mixpanelEvent: "Viewed crew edit",
        breadcrumb: "crumbs.CREW_EDIT",
    },
};

export const crewManagementState = {
    name: "app.growers.grower.manageCrews.crewManagement",
    url: "/{crew_sid}/management",
    component: "crewManagement",
    resolve: {
        group: function ($transition$, GroupService) {
            "ngInject";
            return GroupService.retrieve($transition$.params().crew_sid);
        },
        permissions: function (GroupManagementPermissionService) {
            "ngInject";
            return GroupManagementPermissionService.list({});
        },
        managementMemberships: function (
            $transition$,
            GroupManagementMembershipService,
        ) {
            "ngInject";
            return GroupManagementMembershipService.list({
                active: true,
                group__sid: $transition$.params().crew_sid,
            });
        },
        managerUsers: function (managementMemberships, AccountService) {
            "ngInject";
            if (managementMemberships.length) {
                return AccountService.several(
                    _.uniq(_.map(managementMemberships, "user")),
                );
            } else {
                return [];
            }
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        management: function (
            managerUsers,
            managementMemberships,
            growerMemberships,
        ) {
            "ngInject";
            return _.map(managerUsers, function (user) {
                return {
                    user: user,
                    membership: _.find(managementMemberships, [
                        "user",
                        user.sid,
                    ]),
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
        users: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                growermembership__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        selectableUsers: function (managerUsers, users) {
            "ngInject";
            return _.differenceBy(users, managerUsers, SID_LOOKUP);
        },
        workers: function (selectableUsers, growerMemberships) {
            "ngInject";
            return _.map(selectableUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.CREW_MANAGEMENT_TITLE",
        // permissions: {
        //     only: function (transitionProperties) {
        //         return [
        //             createPermissionString(
        //                 transitionProperties.toParams.farm_sid,
        //                 "edit_admins"
        //             ),
        //         ];
        //     },
        // },
        mixpanelEvent: "Viewed crew managers",
        breadcrumb: "crumbs.CREW_MANAGEMENT",
    },
};

export const crewManagerEditState = {
    name: "app.growers.grower.manageCrews.crewManagement.edit",
    url: "/{group_management_membership_sid}/edit",
    component: "crewManagerEdit",
    resolve: {
        membership: function ($transition$, GroupManagementMembershipService) {
            "ngInject";
            return GroupManagementMembershipService.retrieve(
                $transition$.params().group_management_membership_sid,
            );
        },
        user: function (membership, AccountService) {
            "ngInject";
            return AccountService.retrieve(membership.user);
        },
        permissions: function (GroupManagementPermissionService) {
            "ngInject";
            return GroupManagementPermissionService.list({});
        },
    },
    data: {
        title: "routes.CREW_MANAGER_EDIT_TITLE",
        // permissions: {
        //     only: function (transitionProperties) {
        //         return [
        //             createPermissionString(
        //                 transitionProperties.toParams.farm_sid,
        //                 "edit_admins"
        //             ),
        //         ];
        //     },
        // },
        mixpanelEvent: "Viewed edit crew manager",
        breadcrumb: "crumbs.CREW_MANAGER_EDIT",
    },
};

export const farmManagementState = {
    name: "app.growers.grower.farm.management",
    url: "/management",
    views: { lower: { component: "farmManagement" } },
    resolve: {
        managementMemberships: function (
            $transition$,
            ManagementMembershipService,
        ) {
            "ngInject";
            return ManagementMembershipService.list({
                active: true,
                farm__sid: $transition$.params().farm_sid,
            });
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        managerUsers: function ($transition$, AccountService) {
            "ngInject";
            return AccountService.list({
                manager__farm__sid: $transition$.params().farm_sid,
            });
        },
        permissions: function (ManagementPermissionService) {
            "ngInject";
            return ManagementPermissionService.list();
        },
        management: function (
            growerMemberships,
            managerUsers,
            managementMemberships,
        ) {
            "ngInject";
            return _.map(managerUsers, function (user) {
                return {
                    user: user,
                    membership: _.find(managementMemberships, [
                        "user",
                        user.sid,
                    ]),
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
        users: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        selectableUsers: function (managerUsers, users) {
            "ngInject";
            return _.differenceBy(users, managerUsers, SID_LOOKUP);
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        workers: function (selectableUsers, growerMemberships) {
            "ngInject";
            return _.map(selectableUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.FARM_MANAGEMENT_TITLE",
        permissions: {
            only: [EDIT_ADMINS],
        },
        mixpanelEvent: "Viewed farm administrators dashboard",
        breadcrumb: "crumbs.FARM_MANAGEMENT",
    },
};

export const farmManagerEditState = {
    name: "app.growers.grower.farm.management.edit",
    url: "/{management_membership_sid}/edit",
    component: "farmManagerEdit",
    resolve: {
        membership: function ($transition$, ManagementMembershipService) {
            "ngInject";
            return ManagementMembershipService.retrieve(
                $transition$.params().management_membership_sid,
            );
        },
        user: function (membership, AccountService) {
            "ngInject";
            return AccountService.retrieve(membership.user);
        },
        themes: function (ThemeService) {
            "ngInject";
            return ThemeService.list({ public: true });
        },
        subscriptions: function (user, NoticeSubscriptionService, farm) {
            "ngInject";
            return NoticeSubscriptionService.list({
                user__sid: user.sid,
                active: true,
                farm__sid: farm.sid,
            });
        },
        activeSubscriptions: function (themes, subscriptions) {
            "ngInject";
            return _.map(themes, function (theme) {
                return {
                    theme: theme,
                    subscription: _.find(subscriptions, ["theme", theme.sid]),
                };
            });
        },
    },
    data: {
        title: "routes.FARM_MANAGER_EDIT_TITLE",
        permissions: {
            only: [EDIT_ADMINS],
        },
        mixpanelEvent: "Viewed edit manager",
        breadcrumb: "crumbs.FARM_MANAGER_EDIT",
    },
};

export const farmEditState = {
    name: "app.growers.grower.farm.edit",
    url: "/edit",
    views: { lower: { component: "farmEdit" } },
    resolve: {
        crops: function (CropService) {
            "ngInject";
            return CropService.list();
        },
        farmManagementMemberships: function (
            ManagementMembershipService,
            $transition$,
        ) {
            "ngInject";
            return ManagementMembershipService.list({
                farm__sid: $transition$.params().farm_sid,
                active: true,
            });
        },
        managerUsers: function ($transition$, AccountService) {
            "ngInject";
            return AccountService.list({
                manager__farm__sid: $transition$.params().farm_sid,
            });
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        managers: function (
            farmManagementMemberships,
            managerUsers,
            growerMemberships,
        ) {
            "ngInject";
            return _.map(managerUsers, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmManagementMemberships, [
                        "user",
                        user.sid,
                    ]),
                    growerMembership: _.find(growerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.FARM_EDIT_TITLE",
        mixpanelEvent: "Viewed farm edit",
        permissions: {
            only: [EDIT_FARM],
        },
    },
};

export const newWorkerState = {
    name: "app.growers.grower.farm.manage.addWorker",
    url: "/add-worker?mobile_phone",
    component: "newWorkerStart",
    resolve: {
        mobile: function ($transition$) {
            "ngInject";
            return $transition$.params().mobile_phone;
        },
        algoliaParams: function (GrowerMembershipService) {
            "ngInject";
            return GrowerMembershipService.getAlgoliaKey();
        },
    },
    data: {
        title: "routes.NEW_WORKER_TITLE",
        permissions: {
            only: [ADD_TERMINATE_EMPLOYEES],
        },
        mixpanelEvent: "Viewed new employee start",
        breadcrumb: "crumbs.NEW_WORKER",
    },
};

export const newWorkerCreateState = {
    name: "app.growers.grower.farm.manage.addWorker.new",
    url: "/new?mobile",
    component: "newWorkerCreate",
    resolve: {
        newMember: function ($transition$) {
            "ngInject";
            return {
                mobile: $transition$.params().mobile,
                preferred_language: SPANISH,
            };
        },
    },
    data: {
        title: "routes.NEW_WORKER_CREATE_TITLE",
        mixpanelEvent: "Viewed create new employee account",
        breadcrumb: "crumbs.NEW_WORKER_CREATE",
        // permissions: {
        //     only: function(transitionProperties) {
        //         return [
        //             createPermissionString(
        //                 transitionProperties.toParams.farm_sid,
        //                 "add_terminate_employees"
        //             )
        //         ];
        //     }
        // }
    },
};

export const newWorkerBaseState = {
    name: "app.growers.grower.farm.manage.addWorker.base",
    url: "/{grower_membership_sid}",
    abstract: true,
    component: "newWorkerBase",
    resolve: {
        growerMembership: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.retrieve(
                $transition$.params().grower_membership_sid,
            );
        },
        farmMembership: function (
            FarmMembershipService,
            $transition$,
            moment,
            growerMembership,
        ) {
            "ngInject";
            return FarmMembershipService.first({
                farm__sid: $transition$.params().farm_sid,
                user__sid: growerMembership.user,
                active: true,
            }).then(function (membership) {
                if (membership) {
                    return membership;
                } else {
                    return FarmMembershipService.create({
                        farm: $transition$.params().farm_sid,
                        user: growerMembership.user,
                        date_added: moment(),
                    });
                }
            });
        },
    },
};
export const newWorkerPhotoPromptState = {
    name: "app.growers.grower.farm.manage.addWorker.base.photo",
    url: "/photo",
    component: "newWorkerPhoto",
    resolve: {
        user: function (AccountService, growerMembership) {
            "ngInject";
            return AccountService.retrieve(growerMembership.user);
        },
        nextState: function () {
            "ngInject";
            return newWorkerAssignCrewsState;
        },
        reloadState: function () {
            "ngInject";
            return newWorkerBaseState;
        },
    },
    data: {
        title: "routes.NEW_WORKER_PHOTO_TITLE",
        mixpanelEvent: "Viewed new employee take photo",
        breadcrumb: "crumbs.NEW_WORKER_PHOTO",
    },
};

export const newWorkerAssignCrewsState = {
    name: "app.growers.grower.farm.manage.addWorker.base.crews",
    url: "/crews",
    component: "newWorkerAssignCrews",
    resolve: {
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.NEW_WORKER_ASSIGN_CREWS_TITLE",
        mixpanelEvent: "Viewed new employee assign crews",
        breadcrumb: "crumbs.NEW_WORKER_ASSIGN_CREWS",
    },
};

export const newWorkerPaperworkState = {
    name: "app.growers.grower.farm.manage.addWorker.base.paperwork",
    url: "/documents",
    component: "newWorkerPaperwork",
    resolve: {
        growerMembership: function (GrowerMembershipService, growerMembership) {
            "ngInject";
            return GrowerMembershipService.retrieve(growerMembership.sid);
        },
        packets: function (PacketService, $transition$) {
            "ngInject";
            return PacketService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
        packetMembers: function (PacketMemberService, packets) {
            "ngInject";
            return PacketMemberService.list({
                active: true,
                packet__sid__in: _.join(_.map(packets, SID_LOOKUP)),
            });
        },
        documentPackages: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.NEW_WORKER_PAPERWORK_TITLE",
        mixpanelEvent: "Viewed new employee paperwork",
        breadcrumb: "crumbs.NEW_WORKER_PAPERWORK",
    },
};

export const newWorkerTrainingState = {
    name: "app.growers.grower.farm.manage.addWorker.base.training",
    url: "/training",
    component: "newWorkerTraining",
    resolve: {
        growerMembership: function (GrowerMembershipService, growerMembership) {
            "ngInject";
            return GrowerMembershipService.retrieve(growerMembership.sid);
        },
        trainingRequirements: function (
            TrainingRequirementService,
            $transition$,
        ) {
            "ngInject";
            return TrainingRequirementService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
        courses: function (SafetyCourseService, $transition$) {
            "ngInject";
            return SafetyCourseService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        wpsCredentials: function ($transition$, CredentialService) {
            "ngInject";
            return CredentialService.list({
                archived: false,
                credential_type__wps_qualified: true,
                expiration_date__gt: moment().format(DATE_FORMAT),
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        wpsCredentialedUsers: function (wpsCredentials, AccountService) {
            "ngInject";
            let wpsTrainerUserSids = _.map(wpsCredentials, "subject");
            return AccountService.several(wpsTrainerUserSids);
        },
        wpsCredentialedGrowerMemberships: function (
            wpsCredentials,
            GrowerMembershipService,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                user__sid__in: _.join(_.map(wpsCredentials, "subject")),
            });
        },
        wpsTrainers: function (
            wpsCredentialedUsers,
            wpsCredentialedGrowerMemberships,
        ) {
            "ngInject";
            return _.map(wpsCredentialedUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(wpsCredentialedGrowerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.NEW_WORKER_TRAINING_TITLE",
        mixpanelEvent: "Viewed new employee training",
        breadcrumb: "crumbs.NEW_WORKER_TRAINING",
    },
};

export const newWorkerDoneState = {
    name: "app.growers.grower.farm.manage.addWorker.base.done",
    url: "/done",
    component: "newWorkerDone",
    resolve: {
        growerMembership: function (GrowerMembershipService, growerMembership) {
            "ngInject";
            return GrowerMembershipService.retrieve(growerMembership.sid);
        },
        timeTrackingVendorConfigurations: function (
            TimeTrackingVendorConfigurationService,
            $transition$,
        ) {
            "ngInject";
            return TimeTrackingVendorConfigurationService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
    },
    data: {
        title: "routes.NEW_WORKER_DONE_TITLE",
        mixpanelEvent: "Viewed new employee done",
        breadcrumb: "crumbs.NEW_WORKER_DONE",
    },
};

export const newWorkerTimeTrackingState = {
    name: "app.growers.grower.farm.manage.addWorker.base.timetracking",
    url: "/time-tracking",
    component: "newWorkerTimeTracking",
    resolve: {
        growerMembership: function (GrowerMembershipService, growerMembership) {
            "ngInject";
            return GrowerMembershipService.retrieve(growerMembership.sid);
        },
        timeTrackingVendorConfigurations: function (
            TimeTrackingVendorConfigurationService,
            $transition$,
        ) {
            "ngInject";
            return TimeTrackingVendorConfigurationService.list(
                {
                    grower_organization__sid: $transition$.params().grower_sid,
                    active: true,
                },
                {
                    expand: ["time_tracking_vendor"],
                },
            );
        },
        timeTrackingEmployeeConfigurations: function (
            TimeTrackingEmployeeConfigurationService,
            growerMembership,
        ) {
            "ngInject";
            return TimeTrackingEmployeeConfigurationService.list({
                grower_membership__sid: growerMembership.sid,
            });
        },
        redirectState: function () {
            "ngInject";
            return "app.growers.grower.farm.manage.addWorker.base.timetracking"; // Have to reference the string since reload state is itself
        },
    },
    data: {
        title: "routes.NEW_WORKER_TIME_TRACKING",
        mixpanelEvent: "Viewed new employee time tracking",
        breadcrumb: "crumbs.NEW_WORKER_TIME_TRACKING",
    },
};

function parentIfEmpty(transition) {
    let targetState = transition.targetState();
    if (!targetState.params().actionItems) {
        return targetState.state().$$state().parent.name;
    }
}

function parentIfNoUser(transition) {
    let targetState = transition.targetState();
    if (!targetState.params().user) {
        return targetState.state().$$state().parent.name;
    }
}

export const sendPaperworkSelectedState = {
    name: "app.growers.grower.farm.manage.sendPaperwork",
    url: "/send-paperwork",
    params: {
        actionItems: {
            array: true,
        },
    },
    component: "sendPaperworkSelected",
    resolve: {
        recipients: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.several($transition$.params().actionItems);
        },
        documents: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                accessor__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        packets: function (PacketService, $transition$) {
            "ngInject";
            return PacketService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
        packetMembers: function (PacketMemberService, packets) {
            "ngInject";
            return PacketMemberService.list({
                active: true,
                packet__sid__in: _.join(_.map(packets, SID_LOOKUP)),
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        customizations: function (
            DocumentPackageCustomizationService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageCustomizationService.list({
                owner__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.SEND_PAPERWORK_SELECTED_TITLE",
        mixpanelEvent: "Viewed send paperwork selected",
        breadcrumb: "crumbs.SEND_PAPERWORK_SELECTED",
    },
    redirectTo: parentIfEmpty,
};

export const bulkAddCrewState = {
    name: "app.growers.grower.farm.manage.bulkAddCrew",
    url: "/add-crew",
    params: {
        actionItems: {
            array: true,
        },
    },
    component: "bulkAddCrew",
    resolve: {
        subjects: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.several($transition$.params().actionItems);
        },
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                farm__sid: $transition$.params().farm_sid,
            });
        },
    },
    data: {
        title: "routes.BULK_ADD_CREW_TITLE",
        mixpanelEvent: "Viewed bulk add to crew",
        breadcrumb: "crumbs.BULK_ADD_CREW",
    },
    redirectTo: parentIfEmpty,
};

export const bulkRemoveCrewState = {
    name: "app.growers.grower.farm.manage.bulkRemoveCrew",
    url: "/remove-crew",
    params: {
        actionItems: {
            array: true,
        },
    },
    component: "bulkRemoveCrew",
    resolve: {
        subjects: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.several($transition$.params().actionItems);
        },
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                farm__sid: $transition$.params().farm_sid,
            });
        },
        memberships: function (MembershipService, $transition$) {
            "ngInject";
            return MembershipService.list({
                group__farm__sid: $transition$.params().farm_sid,
                active: true,
            });
        },
    },
    data: {
        title: "routes.BULK_REMOVE_CREW_TITLE",
        mixpanelEvent: "Viewed bulk remove from crew",
        breadcrumb: "crumbs.BULK_REMOVE_CREW",
    },
    redirectTo: parentIfEmpty,
};

export const bulkMoveFarmState = {
    name: "app.growers.grower.farm.manage.bulkMoveFarm",
    url: "/move-farms",
    params: {
        actionItems: {
            array: true,
        },
    },
    component: "bulkMoveFarm",
    resolve: {
        subjects: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.several($transition$.params().actionItems);
        },
        farms: function (FarmService, $transition$) {
            "ngInject";
            return FarmService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        crews: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            }).then(function (crews) {
                return _.groupBy(crews, "farm");
            });
        },
        memberships: function (MembershipService, $transition$) {
            "ngInject";
            return MembershipService.list({
                group__farm__sid: $transition$.params().farm_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__sid: $transition$.params().farm_sid,
                active: true,
            });
        },
    },
    data: {
        title: "routes.BULK_MOVE_FARM_TITLE",
        mixpanelEvent: "Viewed bulk move farms",
        breadcrumb: "crumbs.BULK_MOVE_FARM",
    },
    redirectTo: parentIfEmpty,
};

export const bulkTerminateState = {
    name: "app.growers.grower.farm.manage.bulkTerminate",
    url: "/terminate",
    params: {
        actionItems: {
            array: true,
        },
    },
    component: "bulkTerminate",
    resolve: {
        subjects: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.several($transition$.params().actionItems);
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
    },
    data: {
        title: "routes.BULK_TERMINATE_TITLE",
        mixpanelEvent: "Viewed bulk terminate",
        breadcrumb: "crumbs.BULK_TERMINATE",
    },
    redirectTo: parentIfEmpty,
};

export const editMemberTimeTrackingState = {
    name: "app.growers.grower.farm.manage.member.editTimeTracking",
    url: "/edit-time-tracking",
    component: "newWorkerTimeTracking",
    resolve: {
        timeTrackingEmployeeConfigurations: function (
            TimeTrackingEmployeeConfigurationService,
            growerMembership,
        ) {
            "ngInject";
            return TimeTrackingEmployeeConfigurationService.list({
                grower_membership__sid: growerMembership.sid,
            });
        },
        redirectState: function () {
            "ngInject";
            return memberState;
        },
    },
    data: {
        title: "routes.EDIT_MEMBER_TIME_TRACKING_TITLE",
        mixpanelEvent: "Viewed employee time tracking edit",
        breadcrumb: "crumbs.EDIT_MEMBER_TIME_TRACKING",
    },
};

export const editMemberAccountState = {
    name: "app.growers.grower.farm.manage.member.editMemberAccount",
    url: "/edit-account",
    component: "editMemberAccount",
    resolve: {
        user: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.retrieve($transition$.params().member_sid);
        },
    },
    data: {
        title: "routes.EDIT_MEMBER_ACCOUNT_TITLE",
        mixpanelEvent: "Viewed employee account edit",
        breadcrumb: "crumbs.EDIT_MEMBER_ACCOUNT",
    },
};

export const createMemberCredentialState = {
    name: "app.growers.grower.farm.manage.member.createCredential",
    url: "/add-credential",
    component: "createCredential",
    resolve: {
        credentialTypes: function (CredentialTypeService) {
            "ngInject";
            return CredentialTypeService.list({ active: true });
        },
    },
    data: {
        title: "routes.CREATE_MEMBER_CREDENTIAL_TITLE",
        mixpanelEvent: "Viewed add employee credential or license",
        breadcrumb: "crumbs.CREATE_MEMBER_CREDENTIAL",
    },
};

export const memberEditProductsState = {
    name: "app.growers.grower.farm.manage.member.editProducts",
    url: "/edit-details",
    component: "memberEditProducts",
    resolve: {
        growerMembership: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.first({
                grower_organization__sid: $transition$.params().grower_sid,
                user__sid: $transition$.params().member_sid,
            });
        },
    },
    data: {
        title: "routes.MEMBER_EDIT_PRODUCT_TITLE",
        mixpanelEvent: "Viewed employee details edit",
        breadcrumb: "crumbs.MEMBER_EDIT_PRODUCT",
    },
};

export const memberEditPhotoState = {
    name: "app.growers.grower.farm.manage.member.editProducts.editPhoto",
    url: "/photo",
    component: "memberEditPhoto",
    lazyLoad: function ($transition$) {
        return lazyLoad($transition$, CLOUDINARY_LIB_URL);
    },
    resolve: {
        nextState: function () {
            "ngInject";
            return memberState;
        },
    },
    data: {
        title: "routes.MEMBER_EDIT_PHOTO_TITLE",
        breadcrumb: "crumbs.MEMBER_EDIT_PHOTO",
    },
};

export const memberEditTrainingRequirementsState = {
    name: "app.growers.grower.farm.manage.member.trainingRequirements",
    url: "/training-requirements",
    component: "memberEditTrainingRequirements",
    resolve: {
        trainingRequirements: function (
            TrainingRequirementService,
            $transition$,
        ) {
            "ngInject";
            return TrainingRequirementService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.MEMBER_EDIT_TRAINING_REQUIREMENTS_TITLE",
        mixpanelEvent: "Viewed employee training requirements edit",
        breadcrumb: "crumbs.MEMBER_EDIT_TRAINING_REQUIREMENTS",
    },
};

export const memberMoveState = {
    name: "app.growers.grower.farm.manage.member.move",
    url: "/move",
    component: "moveWorker",
    resolve: {
        groupMemberships: function (MembershipService, $transition$) {
            "ngInject";
            return MembershipService.list({
                active: true,
                group__farm__sid: $transition$.params().farm_sid,
                user__sid: $transition$.params().member_sid,
            });
        },
        groups: function ($transition$, GroupService) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        assignments: function (groupMemberships) {
            "ngInject";
            return _.map(groupMemberships, "group");
        },
        originalAssignments: function (assignments) {
            "ngInject";
            return _.clone(assignments);
        },
        originalMemberships: function (groupMemberships) {
            "ngInject";
            return groupMemberships;
        },
    },
    data: {
        title: "routes.MEMBER_MOVE_TITLE",
        mixpanelEvent: "Viewed move worker",
        breadcrumb: "crumbs.MEMBER_MOVE",
    },
};

export const memberAssignHousingState = {
    name: "app.growers.grower.farm.manage.member.housing",
    url: "/housing",
    component: "memberAssignHousing",
    resolve: {
        housingSites: function (HousingSiteService, $transition$) {
            "ngInject";
            return HousingSiteService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        originalHousingAssignments: function (
            HousingAssignmentService,
            growerMembership,
        ) {
            "ngInject";
            return HousingAssignmentService.list({
                active: true,
                grower_membership__sid: growerMembership.sid,
            });
        },
        originalHousingSites: function (originalHousingAssignments) {
            "ngInject";
            return _.map(originalHousingAssignments, "housing_site");
        },
    },
    data: {
        title: "routes.MEMBER_ASSIGN_HOUSING_TITLE",
        mixpanelEvent: "Viewed worker assign housing",
        breadcrumb: "crumbs.MEMBER_ASSIGN_HOUSING",
    },
};

export const bulkAssignHousingState = {
    name: "app.growers.grower.farm.manage.bulkAssignHousing",
    url: "/housing",
    params: {
        actionItems: {
            squash: true,
            inherit: false,
        },
    },
    component: "bulkAssignHousing",
    resolve: {
        housingSites: function (HousingSiteService, $transition$) {
            "ngInject";
            return HousingSiteService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        employees: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                user__sid__in: _.join($transition$.params().actionItems),
                grower_organization__sid: $transition$.params().grower_sid,
            }).then(function (memberships) {
                return _.map(memberships, function (membership) {
                    return {
                        ...membership,
                        displayText:
                            membership.first_name + " " + membership.last_name,
                    };
                });
            });
        },
        assignments: function (HousingAssignmentService, $transition$) {
            "ngInject";
            return HousingAssignmentService.list({
                active: true,
                housing_site__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.BULK_ASSIGN_HOUSING_TITLE",
        mixpanelEvent: "Viewed bulk assign housing",
        breadcrumb: "crumbs.BULK_ASSIGN_HOUSING",
    },
    redirectTo: parentIfEmpty,
};

export const memberPaperworkHistoryState = {
    name: "app.growers.grower.farm.manage.member.paperworkHistory",
    url: "/paperwork-history",
    component: "paperworkHistory",
    lazyLoad: function ($transition$) {
        return lazyLoad($transition$, CLOUDINARY_LIB_URL);
    },
    resolve: {
        completedPaperworkRecords: function (
            DocumentPackageRecordService,
            $transition$,
            growerMembership,
        ) {
            "ngInject";
            return DocumentPackageRecordService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                grower_membership__sid: growerMembership.sid,
                completion_date__isnull: false,
                archived: false,
            });
        },
        documentPackages: function (
            DocumentPackageService,
            completedPaperworkRecords,
        ) {
            "ngInject";
            let recordPackageSids = _.map(
                completedPaperworkRecords,
                "document_package",
            );
            let packageSids = _.uniq(recordPackageSids);
            return DocumentPackageService.several(packageSids);
        },
        paperworks: function (
            completedPaperworkRecords,
            documentPackages,
            user,
        ) {
            "ngInject";
            return _.map(completedPaperworkRecords, function (record) {
                return {
                    record: record,
                    documentPackage: _.find(documentPackages, [
                        SID_LOOKUP,
                        record.document_package,
                    ]),
                    user: user,
                };
            });
        },
    },
    data: {
        title: "routes.MEMBER_PAPERWORK_HISTORY_TITLE",
        mixpanelEvent: "Viewed paperwork history",
        breadcrumb: "crumbs.MEMBER_PAPERWORK_HISTORY",
    },
};

export const memberUploadExternalDocumentsState = {
    name: "app.growers.grower.farm.manage.member.paperworkHistory.uploadExternalDocuments",
    url: "/upload",
    component: "uploadExternalDocuments",
    resolve: {},
    data: {
        title: "routes.MEMBER_UPLOAD_EXTERNAL_DOCUMENTS_TITLE",
        mixpanelEvent: "Viewed upload external documents",
        breadcrumb: "crumbs.MEMBER_UPLOAD_EXTERNAL_DOCUMENTS",
    },
};

export const memberTrainingHistoryState = {
    name: "app.growers.grower.farm.manage.member.trainingHistory",
    url: "/training-history",
    component: "traineeHistory",
    resolve: {
        completedRequests: function (
            SafetyMeetingRequestService,
            $transition$,
        ) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                subject__sid: $transition$.params().member_sid,
                status: COMPLETE,
                requester: $transition$.params().grower_sid,
            });
        },
        topics: function (SafetyMeetingTopicService, completedRequests) {
            "ngInject";
            let topicsSids = _.map(completedRequests, "topic");
            return SafetyMeetingTopicService.several(topicsSids);
        },
        records: function (completedRequests, topics) {
            "ngInject";
            return _.map(completedRequests, function (request) {
                return {
                    request: request,
                    topic: _.find(topics, [SID_LOOKUP, request.topic]),
                };
            });
        },
    },
    data: {
        title: "routes.MEMBER_TRAINING_HISTORY_TITLE",
        mixpanelEvent: "Viewed training history",
        breadcrumb: "crumbs.MEMBER_TRAINING_HISTORY",
    },
};

export const memberAddTrainingHistoryState = {
    name: "app.growers.grower.farm.manage.member.trainingHistory.add",
    url: "/add",
    component: "addTrainingHistory",
    resolve: {
        topics: function (SafetyMeetingTopicService, $transition$) {
            "ngInject";
            return SafetyMeetingTopicService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        topicData: function (topics, administrators) {
            "ngInject";
            return _.map(topics, function (topic) {
                return {
                    topic: topic,
                    administrator: _.find(administrators, [
                        SID_LOOKUP,
                        topic.administrator,
                    ]),
                };
            });
        },
        request: function (user, grower, account) {
            "ngInject";
            return {
                subject: user.sid,
                requester: grower.sid,
                status: COMPLETE,
                certifier: account.sid,
                certification_type: GROWER_CERTIFIED,
            };
        },
    },
    data: {
        title: "routes.MEMBER_ADD_TRAINING_HISTORY_TITLE",
        mixpanelEvent: "Viewed add training history",
        breadcrumb: "crumbs.MEMBER_ADD_TRAINING_HISTORY",
    },
};

export const memberPaperworkState = {
    name: "app.growers.grower.farm.manage.member.paperwork",
    url: "/paperwork",
    component: "memberPaperwork",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "lazy.paperwork" */ "./lazy/paperwork"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        memberIncompleteDocumentRequests: function (
            DocumentPackageRequestService,
            $transition$,
            growerMembership,
        ) {
            "ngInject";
            return DocumentPackageRequestService.list({
                grower_membership__sid: growerMembership.sid,
                completed: false,
                cancelled: false,
                requester__sid: $transition$.params().grower_sid,
            });
        },
        signatureRequests: function (
            SignatureRequestService,
            $transition$,
            growerMembership,
        ) {
            "ngInject";
            return SignatureRequestService.list({
                document_package_request__completed: false,
                document_package_request__cancelled: false,
                document_package_request__grower_membership__sid:
                    growerMembership.sid,
                document_package_request__requester__sid:
                    $transition$.params().grower_sid,
            });
        },
        documentPackages: function (
            DocumentPackageService,
            memberIncompleteDocumentRequests,
        ) {
            "ngInject";
            return DocumentPackageService.several(
                _.map(memberIncompleteDocumentRequests, "document_package"),
            );
        },
        paperworkToDo: function (
            memberIncompleteDocumentRequests,
            signatureRequests,
            documentPackages,
        ) {
            "ngInject";
            return _.map(
                memberIncompleteDocumentRequests,
                function (documentRequest) {
                    return {
                        documentRequest: documentRequest,
                        signatureRequests: _.filter(signatureRequests, [
                            "document_package_request",
                            documentRequest.sid,
                        ]),
                        nextRole: getNextRole(
                            documentRequest,
                            _.find(documentPackages, [
                                SID_LOOKUP,
                                documentRequest.document_package,
                            ]),
                        ),
                    };
                },
            );
        },
        paperworkToStart: function (paperworkToDo) {
            "ngInject";
            return _.filter(paperworkToDo, function (paperwork) {
                return paperwork.documentRequest.isInPrefill();
            });
        },
        paperworkV3ToSign: function (paperworkToDo, account) {
            "ngInject";
            return _.filter(paperworkToDo, function (paperwork) {
                return !_.isEmpty(
                    _.filter(paperwork.signatureRequests, {
                        role: INITIATOR,
                        status: PAPERWORK_PENDING,
                        signer: account.sid,
                    }),
                );
            });
        },
        paperworkWithEmployerPartToDo: function (paperworkToDo) {
            "ngInject";
            return _.filter(paperworkToDo, function (paperwork) {
                return (
                    paperwork.nextRole === INITIATOR &&
                    _.isEmpty(
                        _.reject(paperwork.signatureRequests, {
                            role: SIGNER,
                            status: PAPERWORK_COMPLETE,
                        }),
                    )
                );
            });
        },
        redirect: function (
            paperworkToStart,
            paperworkV3ToSign,
            paperworkWithEmployerPartToDo,
            $transition$,
        ) {
            "ngInject";
            console.log({ paperworkToStart });
            console.log({ paperworkV3ToSign });
            console.log({ paperworkWithEmployerPartToDo });

            let state;
            let opts = { reload: memberState };
            let params = {
                grower_sid: $transition$.params().grower_sid,
                farm_sid: $transition$.params().farm_sid,
                member_sid: $transition$.params().member_sid,
            };
            if (paperworkToStart.length) {
                let nextDocument = _.first(paperworkToStart);
                state = prefillPaperworkState;
                params.document_package_request_sid =
                    nextDocument.documentRequest.sid;
            } else if (paperworkWithEmployerPartToDo.length) {
                let nextDocument = _.first(paperworkWithEmployerPartToDo);
                state = memberV3InitiatorSignaturePaperwork;
                params.document_package_request_sid =
                    nextDocument.documentRequest.sid;
            } else if (paperworkV3ToSign.length) {
                state = memberV3SignState;
                params.document_package_requests = _.map(
                    paperworkV3ToSign,
                    "documentRequest.sid",
                );
            } else {
                state = memberState;
            }
            return $transition$.router.stateService.target(state, params, opts);
        },
    },
    redirectTo: (trans) => {
        return trans.injector().getAsync("redirect");
    },
};

export const memberRequestPaperworkState = {
    name: "app.growers.grower.farm.manage.member.send-paperwork",
    url: "/send-paperwork",
    component: "memberRequestPaperwork",
    resolve: {
        documents: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                accessor__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        customizations: function (
            DocumentPackageCustomizationService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageCustomizationService.list({
                owner__sid: $transition$.params().grower_sid,
            });
        },
        administrators: function (TradeOrganizationService, $transition$) {
            "ngInject";
            return TradeOrganizationService.list({
                growerorganization__sid: $transition$.params().grower_sid,
            });
        },
        packets: function (PacketService, $transition$) {
            "ngInject";
            return PacketService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
            });
        },
        packetMembers: function (PacketMemberService, packets) {
            "ngInject";
            return PacketMemberService.list({
                active: true,
                packet__sid__in: _.join(_.map(packets, SID_LOOKUP)),
            });
        },
    },
    data: {
        title: "routes.MEMBER_REQUEST_PAPERWORK_TITLE",
        mixpanelEvent: "Viewed send paperwork individual",
        breadcrumb: "crumbs.MEMBER_REQUEST_PAPERWORK",
    },
};

export const prefillPaperworkState = {
    name: "app.growers.grower.farm.manage.member.paperwork.prefill",
    url: "/{document_package_request_sid}/prefill",
    component: "prefill",
    resolve: {
        documentPackageRequest: function (
            DocumentPackageRequestService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageRequestService.retrieve(
                $transition$.params().document_package_request_sid,
            );
        },
        documentPackage: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.first({
                documentpackagerequest__sid:
                    $transition$.params().document_package_request_sid,
            });
        },
        documentPackageCustomizationTranslated: function (
            DocumentPackageCustomizationTranslatedService,
            documentPackageRequest,
            $translate,
        ) {
            "ngInject";
            if (documentPackageRequest.document_package_customization) {
                return DocumentPackageCustomizationTranslatedService.first({
                    document_package_customization__sid:
                        documentPackageRequest.document_package_customization,
                    language: $translate.use(),
                });
            }
        },
        prefillData: function (
            DocumentPackageService,
            documentPackageRequest,
            account,
        ) {
            "ngInject";
            return DocumentPackageService.prefillForRequest(
                documentPackageRequest,
                account,
            );
        },
        formSchema: function (documentPackageRequest, documentPackage) {
            "ngInject";
            let fieldsToRemove = getSignatureFieldNames(documentPackage);

            // if there is a customization remove requester fields
            if (documentPackageRequest.document_package_customization) {
                fieldsToRemove = _.concat(
                    fieldsToRemove,
                    documentPackage.subject_fields.requester,
                );
            }
            return documentPackage.getFormSchemaRaw().then((data) => {
                let schema = data.schema;

                let fieldsThatPeopleCouldFillOut = _.flatten(
                    _.values(documentPackage.fields),
                );
                let fieldsToKeep = _.pick(
                    schema.properties,
                    fieldsThatPeopleCouldFillOut,
                );
                schema.properties = _.omit(fieldsToKeep, fieldsToRemove);

                // Remove required fields
                if (_.has(schema, "required")) {
                    let requiredFieldsToKeep = _.intersection(
                        schema.required,
                        fieldsThatPeopleCouldFillOut,
                    );
                    schema.required = _.difference(
                        requiredFieldsToKeep,
                        fieldsToRemove,
                    );
                }
                return schema;
            });
        },
        formData: function (prefillData, formSchema) {
            "ngInject";
            return filterOutBadEnums(prefillData, formSchema);
        },
        identificationDocumentTypes: function (
            IdentificationDocumentTypeService,
            $transition$,
        ) {
            "ngInject";
            return IdentificationDocumentTypeService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.MEMBER_START_SIGNATURE_PAPERWORK_TITLE",
        mixpanelEvent: "Grower viewed paperwork simple form",
        breadcrumb: "crumbs.MEMBER_START_SIGNATURE_PAPERWORK",
    },
};

export const memberV3SignState = {
    name: "app.growers.grower.farm.manage.member.paperwork.memberV3Sign",
    url: "/v3-sign?document_package_requests",
    params: {
        document_package_requests: {
            array: true,
        },
    },
    component: "newSignPaperwork",
    resolve: {
        documentPackageRequests: function (
            DocumentPackageRequestService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageRequestService.list({
                completed: false,
                cancelled: false,
                sid__in: _.join(
                    $transition$.params().document_package_requests,
                ),
            });
        },
        documentPackages: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                documentpackagerequest__sid__in: _.join(
                    $transition$.params().document_package_requests,
                ),
            });
        },
        signers: function (AccountService, documentPackageRequests) {
            "ngInject";
            return AccountService.several(
                _.uniq(_.map(documentPackageRequests, "signer")),
            );
        },
        signatureRequests: function (
            $transition$,
            SignatureRequestService,
            accountSid,
        ) {
            "ngInject";
            return SignatureRequestService.list({
                signer__sid: accountSid,
                status: PENDING,
                role: INITIATOR,
                document_package_request__sid__in: _.join(
                    $transition$.params().document_package_requests,
                ),
            });
        },
        redirectState: function () {
            "ngInject";
            return memberState;
        },
        reloadState: function () {
            "ngInject";
            return memberState;
        },
        initialSignature: function (
            PaperworkSignatureService,
            accountSid,
            $transition$,
        ) {
            "ngInject";
            return PaperworkSignatureService.get(
                accountSid,
                $transition$.params().grower_sid,
            );
        },
        requests: function (
            signatureRequests,
            documentPackages,
            documentPackageRequests,
        ) {
            "ngInject";
            return _.map(signatureRequests, function (signatureRequest) {
                return {
                    signatureRequest,
                    documentPackageRequest: _.find(documentPackageRequests, [
                        SID_LOOKUP,
                        signatureRequest.document_package_request,
                    ]),
                    documentPackage: _.find(documentPackages, [
                        SID_LOOKUP,
                        signatureRequest.document_package,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.V3_SIGN_TITLE",
        mixpanelEvent: "Grower viewed paperwork for signature",
        breadcrumb: "crumbs.V3_SIGN",
    },
};

export const memberV3InitiatorSignaturePaperwork = {
    name: "app.growers.grower.farm.manage.member.paperwork.initiatorStartPaperwork",
    url: "/{document_package_request_sid}/v3-start-signature",
    component: "startPaperwork",
    resolve: {
        documentPackageRequest: function (
            DocumentPackageRequestService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageRequestService.retrieve(
                $transition$.params().document_package_request_sid,
            );
        },
        documentPackage: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.first({
                documentpackagerequest__sid:
                    $transition$.params().document_package_request_sid,
            });
        },
        documentPackageCustomizationTranslated: function (
            DocumentPackageCustomizationTranslatedService,
            documentPackageRequest,
            $translate,
        ) {
            "ngInject";
            if (documentPackageRequest.document_package_customization) {
                return DocumentPackageCustomizationTranslatedService.first({
                    document_package_customization__sid:
                        documentPackageRequest.document_package_customization,
                    language: $translate.use(),
                });
            }
        },
        prefillData: function (
            DocumentPackageService,
            documentPackageRequest,
            account,
        ) {
            "ngInject";
            return DocumentPackageService.prefillForRequest(
                documentPackageRequest,
                account,
            );
        },
        formData: function (prefillData, formSchema, documentPackageRequest) {
            "ngInject";
            let filteredPrefillData = filterOutBadEnums(
                prefillData,
                formSchema,
            );
            return {
                ...filteredPrefillData,
                ...documentPackageRequest.data,
            };
        },
        formSchema: function (documentPackageRequest, documentPackage) {
            "ngInject";
            let signatureFieldNames = getSignatureFieldNames(documentPackage);
            let signerFields = _.get(documentPackage.fields, INITIATOR, []);
            // if there is a customization remove requester fields
            if (documentPackageRequest.document_package_customization) {
                signerFields = _.difference(
                    signerFields,
                    documentPackage.subject_fields.requester,
                );
            }
            return documentPackage.getFormSchemaRaw().then((data) => {
                let schema = data.schema;

                let fieldsToRemove = _.difference(
                    _.keys(schema.properties),
                    signerFields,
                );

                let fieldsThatPeopleCouldFillOut = _.flatten(
                    _.values(documentPackage.fields),
                );
                let fieldsToKeep = _.pick(
                    schema.properties,
                    fieldsThatPeopleCouldFillOut,
                );
                schema.properties = _.omit(
                    fieldsToKeep,
                    _.concat(fieldsToRemove, signatureFieldNames),
                );

                // Remove required fields
                if (_.has(schema, "required")) {
                    let required = _.intersection(
                        schema.required,
                        signerFields,
                    );
                    schema.required = _.difference(
                        required,
                        signatureFieldNames,
                    );
                }
                return schema;
            });
        },
        identificationDocumentTypes: function (
            IdentificationDocumentTypeService,
            $transition$,
        ) {
            "ngInject";
            return IdentificationDocumentTypeService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        redirectState: function () {
            "ngInject";
            return memberPaperworkState;
        },
        role: function () {
            "ngInject";
            return INITIATOR;
        },
        autoSubmit: function (documentPackageRequest) {
            "ngInject";
            return false;
        },
    },
    data: {
        title: "routes.V3_EMPLOYER_FORM_TITLE",
        mixpanelEvent: "Employer viewed employer part of paperwork simple form",
        breadcrumb: "crumbs.V3_EMPLOYER_FORM",
    },
    redirectTo: (trans) => {
        let requestResolve = trans
            .injector()
            .getAsync("documentPackageRequest");
        let redirectStateResolve = trans.injector().getAsync("redirectState");
        let $transition$ = trans.injector().get("$transition$");
        let $q = trans.injector().get("$q");
        return $q
            .all([requestResolve, redirectStateResolve])
            .then(function (resolves) {
                let [request, redirectState] = resolves;
                let targetRedirectState =
                    $transition$.router.stateService.target(
                        redirectState,
                        { member_sid: request.signer },
                        { reload: redirectState },
                    );
                if (
                    request.completed ||
                    request.cancelled ||
                    request.next_pending_signature_request
                ) {
                    return targetRedirectState;
                }
            });
    },
};

export const bulkFinishSafetyMeetingState = {
    name: "app.growers.grower.manageSafety.meetings.bulkFinish",
    url: "/finish",
    params: {
        actionItems: {
            array: true,
        },
    },
    component: "bulkSafetyMeetingFinish",
    resolve: {
        meetings: function (SafetyMeetingService, $transition$) {
            "ngInject";
            return SafetyMeetingService.several(
                $transition$.params().actionItems,
            );
        },
    },
    data: {
        title: "routes.BULK_FINISH_SAFETY_MEETING_TITLE",
        mixpanelEvent: "Viewed bulk finish safety meeting",
        breadcrumb: "crumbs.BULK_FINISH_SAFETY_MEETING",
    },
    redirectTo: parentIfEmpty,
};

export const bulkDownloadSafetyMeetingState = {
    name: "app.growers.grower.manageSafety.meetings.bulkDownload",
    url: "/download",
    params: {
        actionItems: {
            array: true,
        },
    },
    component: "bulkDownloadMeetingReport",
    resolve: {
        meetings: function (SafetyMeetingService, $transition$) {
            "ngInject";
            return SafetyMeetingService.several(
                $transition$.params().actionItems,
            );
        },
    },
    data: {
        title: "routes.BULK_DOWNLOAD_SAFETY_MEETING_TITLE",
        mixpanelEvent: "Viewed bulk download safety meeting",
        breadcrumb: "crumbs.BULK_DOWNLOAD_SAFETY_MEETING",
    },
    redirectTo: parentIfEmpty,
};

export const bulkMessageSafetyMeetingState = {
    name: "app.growers.grower.manageSafety.meetings.bulkMessage",
    url: "/message",
    params: {
        actionItems: {
            array: true,
        },
    },
    component: "bulkSafetyMeetingMessage",
    resolve: {
        meetings: function (SafetyMeetingService, $transition$) {
            "ngInject";
            return SafetyMeetingService.several(
                $transition$.params().actionItems,
            );
        },
    },
    data: {
        title: "routes.BULK_MESSAGE_SAFETY_MEETING_TITLE",
        mixpanelEvent: "Viewed bulk finish safety message",
        breadcrumb: "crumbs.BULK_MESSAGE_SAFETY_MEETING",
    },
    redirectTo: parentIfEmpty,
};
export const settingsState = {
    name: "app.settings",
    url: "/settings",
    component: "settings",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "common.account" */ "./common/account"),
        ]).then(function (modules) {
            return $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        profile: function (ProfileService, account) {
            "ngInject";
            return ProfileService.retrieve(account.profile.sid);
        },
    },
    data: {
        title: "routes.SETTINGS_TITLE",
        mixpanelEvent: "Viewed account settings",
    },
};
export const accountEditState = {
    name: "app.settings.accountEdit",
    url: "/account/edit",
    component: "accountEdit",
    data: {
        title: "routes.ACCOUNT_EDIT_TITLE",
        mixpanelEvent: "Viewed edit account",
    },
};
export const profileEditState = {
    name: "app.settings.profileEdit",
    url: "/profile/edit",
    component: "profileEdit",
    lazyLoad: function ($transition$) {
        return lazyLoad($transition$, CLOUDINARY_LIB_URL);
    },
    data: {
        title: "routes.PROFILE_EDIT_TITLE",
        mixpanelEvent: "Viewed edit profile",
    },
};

export const crewState = {
    name: "app.crew",
    url: "/crews/{crew_sid}",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "crew" */ "./crew"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        crew: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.retrieve($transition$.params().crew_sid);
        },
        farm: function (FarmService, crew) {
            "ngInject";
            return FarmService.retrieve(crew.farm);
        },
        grower: function (GrowerOrganizationService, farm) {
            "ngInject";
            return GrowerOrganizationService.retrieve(farm.grower_organization);
        },
    },
    redirectTo: homescreenState.name,
    data: {},
};

export const crewMessagesState = {
    name: "app.crew.messages",
    url: "/messages?state?owner",
    component: "crewConversationList",
    resolve: {
        conversations: function (ConversationService, $transition$, farm) {
            "ngInject";
            let params = {
                grower_organization__sid: farm.grower_organization,
                state: ACTIVE,
                contact__group__sid: $transition$.params().crew_sid,
            };
            let ownerParamValue = $transition$.params().owner;
            if (ownerParamValue === "unassigned") {
                params.owner__isnull = true;
            } else {
                params.owner__sid = ownerParamValue;
            }
            return ConversationService.list(params);
        },
        participants: function (AccountService, conversations) {
            "ngInject";
            let owners = _.map(conversations, "owner");
            let contacts = _.map(conversations, "contact");
            return AccountService.several(_.concat(owners, contacts));
        },
        beams: function (BeamsService, account) {
            "ngInject";
            BeamsService.initializeForConversations(account.sid);
        },
        twilioClient: function (ConversationService) {
            "ngInject";
            return ConversationService.createTwilioClient();
        },
        conversationData: function (conversations, participants) {
            "ngInject";
            return _.map(conversations, function (conversation) {
                return {
                    conversation: conversation,
                    contact: _.find(participants, [
                        SID_LOOKUP,
                        conversation.contact,
                    ]),
                    owner: _.find(participants, [
                        SID_LOOKUP,
                        conversation.owner,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.CREW_MESSAGE_TITLE",
        mixpanelEvent: "Viewed crew messages",
        breadcrumb: "crumbs.CREW_MESSAGE",
    },
};

export const crewConversationState = {
    name: "app.crew.messages.conversation",
    url: "/{conversation_sid}",
    component: "crewConversation",
    resolve: {
        conversation: function (ConversationService, $transition$) {
            "ngInject";
            return ConversationService.retrieve(
                $transition$.params().conversation_sid,
            );
        },
        addedParticipant: function (conversation) {
            "ngInject";
            return conversation.addParticipant();
        },
        twilioClientConversation: function (
            ConversationService,
            addedParticipant,
        ) {
            "ngInject";
            return ConversationService.createTwilioClient();
        },
        twilioConversation: function (twilioClientConversation, conversation) {
            "ngInject";
            return twilioClientConversation.getConversationBySid(
                conversation.conversation_sid,
            );
        },
        participants: function (AccountService, conversation) {
            "ngInject";
            return AccountService.several([
                conversation.contact,
                conversation.owner,
            ]);
        },
        contact: function (participants, conversation) {
            "ngInject";
            return _.find(participants, [SID_LOOKUP, conversation.contact]);
        },
        owner: function (participants, conversation) {
            "ngInject";
            return _.find(participants, [SID_LOOKUP, conversation.owner]);
        },
        messages: function () {
            "ngInject";
            return [];
        },
        growerMemberships: function (
            GrowerMembershipService,
            $transition$,
            conversation,
            grower,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: grower.sid,
                user__sid: conversation.contact,
            });
        },
        contactWorker: function (contact, conversation, growerMemberships) {
            "ngInject";
            let growerMembership = getLatestMembership(
                growerMemberships,
                contact.sid,
            );
            return {
                user: contact,
                growerMembership,
                displayName: getContactWorkerDisplayName(
                    conversation,
                    growerMembership,
                ),
            };
        },
        newMessage: function () {
            "ngInject";
            return {};
        },
    },
    data: {
        title: "routes.CREW_CONVERSATION_TITLE",
        mixpanelEvent: "Viewed conversation",
        breadcrumb: "crumbs.CREW_CONVERSATION",
    },
};

export const crewConversationCreateState = {
    name: "app.crew.messages.create",
    url: "/create",
    component: "crewConversationCreate",
    resolve: {
        growerMemberships: function (
            GrowerMembershipService,
            grower,
            farm,
            $transition$,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: grower.sid,
                user__group__sid: $transition$.params().crew_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, farm, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__sid: farm.sid,
                user__group__sid: $transition$.params().crew_sid,
                active: true,
            });
        },
        employees: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                group__sid: $transition$.params().crew_sid,
            });
        },
        workers: function (farmMemberships, employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
        conversation: function () {
            "ngInject";
            return {};
        },
    },
    data: {
        title: "routes.CREW_CONVERSATION_CREATE_TITLE",
        mixpanelEvent: "Viewed create conversation",
        breadcrumb: "crumbs.CREW_CONVERSATION_CREATE",
    },
};

export const crewAccidentReportState = {
    name: "app.crew.accidentReportCreate",
    url: "/report-accident",
    component: "crewAccidentReport",
    resolve: {
        growerMemberships: function (
            GrowerMembershipService,
            grower,
            farm,
            $transition$,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: grower.sid,
                user__group__sid: $transition$.params().crew_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, farm, $transition$) {
            "ngInject";
            return FarmMembershipService.list({
                farm__sid: farm.sid,
                user__group__sid: $transition$.params().crew_sid,
                active: true,
            });
        },
        employees: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                group__sid: $transition$.params().crew_sid,
            });
        },
        workers: function (farmMemberships, employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
    },
    data: {
        title: "routes.CREW_ACCIDENT_REPORT_TITLE",
        mixpanelEvent: "Viewed crew accident report",
        breadcrumb: "crumbs.CREW_ACCIDENT_REPORT",
    },
};

export const safetyState = {
    name: "app.safety",
    url: "/safety",
    abstract: true,
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "common.safety" */ "./common/safety"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    data: {},
};

export const manageMeetingCrewBossState = {
    name: "app.safety.manageMeetingCrewBoss",
    url: "/manage-meeting/{meeting_sid}",
    component: "manageMeetingCrewBoss",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "lazy.safety" */ "./lazy/safety"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        meeting: function (SafetyMeetingService, $transition$) {
            "ngInject";
            return SafetyMeetingService.retrieve(
                $transition$.params().meeting_sid,
            );
        },
        topics: function (SafetyMeetingTopicService, meeting) {
            "ngInject";
            return SafetyMeetingTopicService.several(meeting.topics);
        },
        translateds: function (
            SafetyMeetingTopicTRANSLATEDService,
            topics,
            $translate,
        ) {
            "ngInject";
            return SafetyMeetingTopicTRANSLATEDService.list({
                safety_meeting_topic__sid__in: _.join(
                    _.map(topics, SID_LOOKUP),
                ),
                language: $translate.use(),
            });
        },
        grower: function (GrowerOrganizationService, meeting) {
            "ngInject";
            return GrowerOrganizationService.retrieve(meeting.organizer);
        },
        farm: function (FarmService, meeting) {
            "ngInject";
            return FarmService.retrieve(meeting.farm);
        },
        requests: function (SafetyMeetingRequestService, $transition$) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                safety_meeting__sid: $transition$.params().meeting_sid,
            });
        },
        participants: function (requests, AccountService) {
            "ngInject";
            return AccountService.several(_.map(requests, "subject"));
        },
        crews: function (GroupService, meeting) {
            "ngInject";
            return GroupService.several(meeting.crews);
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                farm__sid: $transition$.params().farm_sid,
            });
        },
        signatures: function (SignatureService, requests) {
            "ngInject";
            return SignatureService.several(_.map(requests, "signature"));
        },
        requestStatuses: function (
            requests,
            participants,
            growerMemberships,
            signatures,
        ) {
            "ngInject";
            return _.map(participants, function (participant) {
                let participantsRequests = _.filter(requests, [
                    "subject",
                    participant.sid,
                ]);
                for (let request of participantsRequests) {
                    request.signature = _.find(signatures, [
                        SID_LOOKUP,
                        request.signature,
                    ]);
                }
                return {
                    requests: participantsRequests,
                    participant: participant,
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        participant.sid,
                    ),
                    pending: _.some(participantsRequests, [
                        "status",
                        SAFETY_PENDING,
                    ]),
                    complete: _.every(participantsRequests, [
                        "status",
                        COMPLETE,
                    ]),
                    cancelled: _.every(participantsRequests, [
                        "status",
                        CANCELLED,
                    ]),
                };
            });
        },
        completed: function (requestStatuses) {
            "ngInject";
            return _.filter(requestStatuses, "complete");
        },
    },
    data: {
        title: "routes.MANAGE_MEETING_CREW_BOSS_TITLE",
        mixpanelEvent: "Viewed manage meeting crew boss",
    },
};

export const manageMeetingCrewBossCreateNoteState = {
    name: "app.safety.manageMeetingCrewBoss.createNote",
    url: "/add-note",
    component: "manageMeetingCrewBossCreateNote",
    data: {
        title: "routes.MANAGE_MEETING_CREW_BOSS_CREATE_NOTE_TITLE",
        mixpanelEvent: "Viewed manage meeting crew boss create note",
    },
};

export const safetyMeetingScanAttendanceCrewBossState = {
    name: "app.safety.manageMeetingCrewBoss.scanAttendance",
    url: "/scan-attendance",
    component: "scanAttendance",
    resolve: {
        redirectState: function () {
            "ngInject";
            return manageMeetingCrewBossState;
        },
        manualState: function () {
            "ngInject";
            return safetyMeetingManualAttendanceCrewBossState;
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_SCAN_ATTENDANCE_CREW_BOSS_TITLE",
        mixpanelEvent: "Viewed scan attendance to safety meeting crew boss",
    },
};

export const safetyMeetingManualAttendanceCrewBossState = {
    name: "app.safety.manageMeetingCrewBoss.manualAttendance",
    url: "/manual-attendance",
    component: "manualAttendance",
    resolve: {
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                farm__sid: $transition$.params().farm_sid,
                active: true,
            });
        },
        farmMemberships: function (FarmMembershipService, meeting) {
            "ngInject";
            return FarmMembershipService.list({
                farm__sid: meeting.farm,
                active: true,
            });
        },
        employees: function (AccountService, farmMemberships) {
            "ngInject";
            return AccountService.several(_.map(farmMemberships, "user"));
        },
        workers: function (farmMemberships, employees, growerMemberships) {
            "ngInject";
            return _.map(employees, function (user) {
                return {
                    user: user,
                    farmMembership: _.find(farmMemberships, ["user", user.sid]),
                    growerMembership: getLatestMembership(
                        growerMemberships,
                        user.sid,
                    ),
                };
            });
        },
        redirectState: function () {
            "ngInject";
            return manageMeetingCrewBossState;
        },
        searchState: function () {
            "ngInject";
            return safetyMeetingScanAttendanceCrewBossState;
        },
    },
    data: {
        title: "routes.SAFETY_MEETING_MANUAL_ATTENDANCE_CREW_BOSS_TITLE",
        mixpanelEvent: "Viewed safety meeting manual attendance crew boss",
    },
};

export const safetyMeetingBaseState = {
    name: "app.safety.meeting",
    url: "/safety-meetings/{meeting_sid}",
    resolve: {
        meeting: function (SafetyMeetingService, $transition$) {
            "ngInject";
            return SafetyMeetingService.retrieve(
                $transition$.params().meeting_sid,
            );
        },
        topics: function (SafetyMeetingTopicService, meeting) {
            "ngInject";
            return SafetyMeetingTopicService.several(meeting.topics);
        },
        yourRequests: function (
            SafetyMeetingRequestService,
            $transition$,
            account,
        ) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                safety_meeting__sid: $transition$.params().meeting_sid,
                subject__sid: account.sid,
            });
        },
        requests: function (
            SafetyMeetingRequestService,
            $transition$,
            account,
            meeting,
            $q,
            yourRequests,
        ) {
            "ngInject";
            if (yourRequests.length) {
                return yourRequests;
            } else {
                let responses = _.map(meeting.topics, function (topic) {
                    return SafetyMeetingRequestService.create({
                        subject: account.sid,
                        safety_meeting: meeting.sid,
                        requester: meeting.organizer,
                        topic: topic,
                    });
                });
                return $q.all(responses);
            }
        },
        redirect: function (meeting, requests, topics) {
            "ngInject";
            let pendingRequests = _.filter(requests, ["status", PENDING]);
            let state;
            let params;

            let pendingTrainings = _.map(pendingRequests, function (request) {
                return {
                    request,
                    topic: _.find(topics, [SID_LOOKUP, request.topic]),
                };
            });
            pendingTrainings = _.orderBy(pendingTrainings, "topic.runtime");
            if (pendingTrainings.length) {
                state = attendSafetyMeetingRequestState;
                params = {
                    meeting_sid: meeting.sid,
                    request_sid: _.first(pendingTrainings).request.sid,
                };
            } else {
                state = safetyMeetingDoneState;
                params = {
                    meeting_sid: meeting.sid,
                };
            }
            return { state: state, params: params };
        },
    },
    redirectTo: (trans) => {
        return trans.injector().getAsync("redirect");
    },
};
// New
export const attendSafetyMeetingRequestState = {
    name: "app.safety.meeting.request",
    url: "/{request_sid}",
    component: "viewSafetyMeeting",
    resolve: {
        request: function (
            $transition$,
            SafetyMeetingRequestService,
            $state,
            $q,
        ) {
            "ngInject";
            return SafetyMeetingRequestService.retrieve(
                $transition$.params().request_sid,
            ).then(function (request) {
                if (request.status !== PENDING) {
                    let state = safetyMeetingBaseState;
                    let params = {
                        meeting_sid: $transition$.params().meeting_sid,
                    };
                    let response = $q.defer();
                    response.reject({ redirectTo: { state, params } });
                    return response.promise;
                } else {
                    return request;
                }
            });
        },
        topic: function (SafetyMeetingTopicService, request) {
            "ngInject";
            return SafetyMeetingTopicService.retrieve(request.topic);
        },
        content: function (
            SafetyMeetingTopicTRANSLATEDService,
            request,
            $translate,
        ) {
            "ngInject";
            return SafetyMeetingTopicTRANSLATEDService.first({
                safety_meeting_topic__sid: request.topic,
                language: $translate.use(),
            });
        },
        farm: function (FarmService, meeting) {
            "ngInject";
            return FarmService.retrieve(meeting.farm);
        },
        wpsTrainer: function (AccountService, meeting) {
            "ngInject";
            return AccountService.retrieve(meeting.wps_trainer);
        },
    },
    data: {
        title: "routes.ATTEND_SAFETY_MEETING_REQUEST_TITLE",
        mixpanelEvent: "Viewed safety meeting",
        mixpanelEventProperties: {
            topic__runtime: "topic.runtime",
            topic__category: "topic.category",
            topic__requires_signature: "topic.requires_signature",
            mp_trace_id: "meeting.sid",
            company_sid: "meeting.organizer",
        },
    },
};

export const attendSafetyMeetingState = {
    name: "app.safety.attendSafetyMeeting",
    url: "/safety-meeting/{request_sid}",
    component: "viewSafetyMeeting",
    resolve: {
        request: function (SafetyMeetingRequestService, $transition$, $state) {
            "ngInject";
            return SafetyMeetingRequestService.retrieve(
                $transition$.params().request_sid,
            ).then(function (request) {
                if (request.status !== PENDING) {
                    let target = $state.target(homescreenState);
                    $transition$.redirect(target).run();
                }
                return request;
            });
        },
        meeting: function (SafetyMeetingService, request) {
            "ngInject";
            return SafetyMeetingService.retrieve(request.safety_meeting);
        },
        topic: function (SafetyMeetingTopicService, request) {
            "ngInject";
            return SafetyMeetingTopicService.retrieve(request.topic);
        },
        content: function (
            SafetyMeetingTopicTRANSLATEDService,
            request,
            $translate,
        ) {
            "ngInject";
            return SafetyMeetingTopicTRANSLATEDService.first({
                safety_meeting_topic__sid: request.topic,
                language: $translate.use(),
            });
        },
        farm: function (FarmService, meeting) {
            "ngInject";
            return FarmService.retrieve(meeting.farm);
        },
        wpsTrainer: function (AccountService, meeting) {
            "ngInject";
            return AccountService.retrieve(meeting.wps_trainer);
        },
    },
    data: {
        title: "routes.ATTEND_SAFETY_MEETING_TITLE",
        mixpanelEvent: "Viewed safety meeting",
    },
};

export const markAttendanceState = {
    name: "app.safety.meeting.markAttendance",
    url: "/attendance/{participant_sid}",
    component: "markAttendance",
    resolve: {
        requests: function (SafetyMeetingRequestService, $transition$) {
            "ngInject";
            return SafetyMeetingRequestService.list({
                safety_meeting__sid: $transition$.params().meeting_sid,
                status: PENDING,
                subject__sid: $transition$.params().participant_sid,
            });
        },
        topics: function (SafetyMeetingTopicService, meeting) {
            "ngInject";
            return SafetyMeetingTopicService.several(meeting.topics);
        },
    },
    data: {
        title: "routes.MARK_ATTENDANCE_TITLE",
        mixpanelEvent: "Viewed mark attendance",
    },
};
export const safetyMeetingDoneState = {
    name: "app.safety.meeting.done",
    url: "/done",
    component: "safetyMeetingDone",
    data: {
        title: "routes.SAFETY_MEETING_DONE_TITLE",
        mixpanelEvent: "Viewed post safety meeting",
        mixpanelEventProperties: {
            mp_trace_id: "meeting.sid",
            company_sid: "meeting.organizer",
        },
    },
};
export const announcementsState = {
    name: "app.announcements",
    url: "/announcements",
    abstract: true,
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(
                /* webpackChunkName: "common.communication" */ "./common/communication"
            ),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    data: {},
};
export const announcementState = {
    name: "app.announcements.announcement",
    url: "/{announcement_sid}",
    component: "viewMessage",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
                {},
                {
                    expand: ["translations", "farm"],
                },
            );
        },
        translated: function (announcement, $translate) {
            "ngInject";
            return _.find(announcement.translations, [
                "language",
                $translate.use(),
            ]);
        },
        attachments: function (AttachmentService, $transition$) {
            "ngInject";
            return AttachmentService.list({
                announcement__sid: $transition$.params().announcement_sid,
            });
        },
    },
    data: {
        title: "routes.ANNOUNCEMENT_TITLE",
        mixpanelEvent: "Viewed message",
    },
};
export const announcementTranslatedState = {
    name: "app.announcements.announcement.translated",
    url: "/translations/{translated_sid}",
    abstract: true,
    resolve: {
        translated: function ($transition$, announcement) {
            "ngInject";
            return _.find(announcement.translations, [
                SID_LOOKUP,
                $transition$.params().translated_sid,
            ]);
        },
    },
};
export const announcementAttachmentsState = {
    name: "app.announcements.announcement.attachments",
    url: "/attachments",
    abstract: true,
};
export const announcementAttachmentState = {
    name: "app.announcements.announcement.attachments.attachment",
    url: "/{attachment_sid}",
    external: true,
    resolve: {
        attachment: function (attachments, $transition$) {
            "ngInject";
            return _.find(attachments, [
                SID_LOOKUP,
                $transition$.params().attachment_sid,
            ]);
        },
        redirect: function (attachment) {
            "ngInject";
            if (attachment.external_file_id) {
                return getCloudinaryUrl(attachment.external_file_id);
            } else {
                return attachment.file;
            }
        },
    },
    data: {
        title: "routes.ANNOUNCEMENT_ATTACHMENT_TITLE",
        mixpanelEvent: "Viewed attachment",
    },
};
export const announcementAudioState = {
    name: "app.announcements.announcement.translated.audio",
    url: "/audio",
    external: true,
    resolve: {
        redirect: function (translated) {
            "ngInject";
            return translated.audio_file;
        },
    },
    data: {
        title: "routes.ANNOUNCEMENT_AUDIO_TITLE",
        mixpanelEvent: "Viewed announcement audio",
    },
};

export const paperworkState = {
    name: "app.paperwork",
    url: "/paperwork",
    abstract: true,
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "lazy.paperwork" */ "./lazy/paperwork"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    data: {},
};

export const growerPaperworkState = {
    name: "app.paperwork.grower",
    url: "/{grower_sid}?skip__document_package_request__sid",
    params: {
        skip__document_package_request__sid: {
            inherit: false,
            value: undefined,
        },
    },
    component: "growerPaperwork",
    resolve: {
        grower: function ($transition$, GrowerOrganizationService) {
            "ngInject";
            return GrowerOrganizationService.retrieve(
                $transition$.params().grower_sid,
            );
        },
        growerMembership: function (
            $transition$,
            GrowerMembershipService,
            account,
        ) {
            "ngInject";
            return GrowerMembershipService.first({
                grower_organization__sid: $transition$.params().grower_sid,
                user__sid: account.sid,
            });
        },
        myIncompleteDocumentRequests: function (
            DocumentPackageRequestService,
            growerMembership,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageRequestService.list({
                grower_membership__sid: growerMembership.sid,
                completed: false,
                cancelled: false,
                requester__sid: $transition$.params().grower_sid,
            }).then(function (requests) {
                console.log("Before");
                console.log(requests);
                let x = _.filter(requests, function (request) {
                    return (
                        request.sid !==
                        $transition$.params()
                            .skip__document_package_request__sid
                    );
                });
                console.log("After");
                console.log(x);
                return x;
            });
        },
        mySignatureRequests: function (
            SignatureRequestService,
            growerMembership,
            myIncompleteDocumentRequests,
        ) {
            "ngInject";
            return SignatureRequestService.list({
                grower_membership__sid: growerMembership.sid,
                role: SIGNER,
                document_package_request__sid__in: _.join(
                    _.map(myIncompleteDocumentRequests, SID_LOOKUP),
                ),
            });
        },
        documentPackages: function (
            myIncompleteDocumentRequests,
            DocumentPackageService,
        ) {
            "ngInject";
            return DocumentPackageService.list({
                documentpackagerequest__sid__in: _.join(
                    _.map(myIncompleteDocumentRequests, SID_LOOKUP),
                ),
            });
        },
        paperworkToDo: function (
            myIncompleteDocumentRequests,
            mySignatureRequests,
            documentPackages,
        ) {
            "ngInject";
            return _.map(
                myIncompleteDocumentRequests,
                function (documentRequest) {
                    let documentPackage = _.find(documentPackages, [
                        SID_LOOKUP,
                        documentRequest.document_package,
                    ]);
                    return {
                        documentRequest: documentRequest,
                        signatureRequest: _.find(mySignatureRequests, [
                            "document_package_request",
                            documentRequest.sid,
                        ]),
                        documentPackage,
                        nextRole: getNextRole(documentRequest, documentPackage),
                    };
                },
            );
        },
        newPaperworkWaitingForPrefill: function (paperworkToDo) {
            "ngInject";
            return _.filter(paperworkToDo, function (paperwork) {
                return paperwork.documentRequest.isInPrefill();
            });
        },
        oldPaperworkToStart: function (paperworkToDo) {
            "ngInject";
            return _.filter(paperworkToDo, function (paperwork) {
                return (
                    paperwork.nextRole === SIGNER &&
                    _.isNil(
                        paperwork.documentRequest
                            .next_pending_signature_request,
                    ) &&
                    _.isNil(paperwork.signatureRequest) &&
                    !paperwork.documentRequest.isInPrefill() &&
                    !paperwork.documentRequest.source
                );
            });
        },
        oldPaperworkToSign: function (paperworkToDo) {
            "ngInject";
            return _.filter(paperworkToDo, function (paperwork) {
                return (
                    !_.isNil(
                        _.get(
                            paperwork.signatureRequest,
                            "data_request_id",
                            undefined,
                        ),
                    ) &&
                    _.get(paperwork, "signatureRequest.status", null) ===
                        PAPERWORK_PENDING
                );
            });
        },
        paperworkV3ToSign: function (paperworkToDo) {
            "ngInject";
            return _.filter(paperworkToDo, function (paperwork) {
                return (
                    _.isNil(
                        _.get(paperwork.signatureRequest, "data_request_id"),
                    ) &&
                    _.get(paperwork.signatureRequest, "sid") ===
                        paperwork.documentRequest.next_pending_signature_request
                );
            });
        },
        redirectData: function (
            oldPaperworkToStart,
            oldPaperworkToSign,
            newPaperworkWaitingForPrefill,
            paperworkV3ToSign,
        ) {
            "ngInject";
            return [
                oldPaperworkToStart,
                oldPaperworkToSign,
                newPaperworkWaitingForPrefill,
                paperworkV3ToSign,
            ];
        },
    },
    redirectTo: (trans) => {
        return trans
            .injector()
            .getAsync("redirectData")
            .then(function ([
                oldPaperworkToStart,
                oldPaperworkToSign,
                newPaperworkWaitingForPrefill,
                paperworkV3ToSign,
            ]) {
                console.log({ oldPaperworkToStart });
                console.log({ oldPaperworkToSign });
                console.log({ newPaperworkWaitingForPrefill });
                console.log({ paperworkV3ToSign });
                let state;
                let params = {
                    grower_sid: trans.params().grower_sid,
                };
                if (oldPaperworkToStart.length) {
                    state = oldStartState;
                    params.document_request_sid =
                        _.first(oldPaperworkToStart).documentRequest.sid;
                } else if (paperworkV3ToSign.length) {
                    state = v3SignState;
                } else {
                    state = growerPaperworkDoneState;
                }
                let toState = trans.to();
                if (toState.name === "app.paperwork.grower") {
                    let $state = trans.injector().get("$state");
                    return $state.target(state, params, { reload: toState });
                } else {
                    return;
                }
            });
    },
};

export const growerPaperworkWaitingState = {
    name: "app.paperwork.grower.waiting",
    url: "/waiting",
    component: "growerPaperworkWaiting",
    data: {
        title: "routes.GROWER_PAPERWORK_WAITING_TITLE",
        mixpanelEvent: "Viewed paperwork waiting",
    },
};

export const oldStartState = {
    name: "app.paperwork.grower.oldStart",
    url: "/old-start/{document_request_sid}",
    component: "startPaperwork",
    resolve: {
        documentPackageRequest: function (
            DocumentPackageRequestService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageRequestService.retrieve(
                $transition$.params().document_request_sid,
            );
        },
        documentPackage: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.first({
                documentpackagerequest__sid:
                    $transition$.params().document_request_sid,
            });
        },
        documentPackageCustomizationTranslated: function (
            DocumentPackageCustomizationTranslatedService,
            documentPackageRequest,
            $translate,
        ) {
            "ngInject";
            if (documentPackageRequest.document_package_customization) {
                return DocumentPackageCustomizationTranslatedService.first({
                    document_package_customization__sid:
                        documentPackageRequest.document_package_customization,
                    language: $translate.use(),
                });
            }
        },
        prefillData: function (DocumentPackageService, documentPackageRequest) {
            "ngInject";
            return DocumentPackageService.prefillForRequest(
                documentPackageRequest,
            );
        },
        formData: function (prefillData, formSchema) {
            "ngInject";
            return filterOutBadEnums(prefillData, formSchema);
        },
        formSchema: function (documentPackage, documentPackageRequest) {
            "ngInject";
            addBreadcrumb({
                category: "resolve",
                message: "documentPackage",
                data: documentPackage,
            });
            let signatureFieldNames = getSignatureFieldNames(documentPackage);
            let signerFields = _.get(documentPackage.fields, SIGNER, []);
            // if there is a customization remove requester fields
            if (documentPackageRequest.document_package_customization) {
                signerFields = _.difference(
                    signerFields,
                    documentPackage.subject_fields.requester,
                );
            }
            return documentPackage.getFormSchemaRaw().then((data) => {
                let schema = data.schema;
                let fieldsToRemove = _.difference(
                    _.keys(schema.properties),
                    signerFields,
                );
                let fieldsThatPeopleCouldFillOut = _.flatten(
                    _.values(documentPackage.fields),
                );
                let fieldsToKeep = _.pick(
                    schema.properties,
                    fieldsThatPeopleCouldFillOut,
                );
                schema.properties = _.omit(
                    fieldsToKeep,
                    _.concat(fieldsToRemove, signatureFieldNames),
                );

                // Remove required fields
                if (_.has(schema, "required")) {
                    let required = _.intersection(
                        schema.required,
                        signerFields,
                    );
                    schema.required = _.difference(
                        required,
                        signatureFieldNames,
                    );
                }
                return schema;
            });
        },
        redirectState: function () {
            "ngInject";
            return growerPaperworkState;
        },
        role: function () {
            "ngInject";
            return SIGNER;
        },
        autoSubmit: function (documentPackageRequest) {
            "ngInject";
            return documentPackageRequest.initiator_fill_first;
        },
    },
    data: {
        title: "routes.START_TITLE",
        mixpanelEvent: "Signer viewed paperwork simple form",
    },
    redirectTo: (trans) => {
        let requestResolve = trans
            .injector()
            .getAsync("documentPackageRequest");
        let accountResolve = trans.injector().getAsync("account");
        let redirectStateResolve = trans.injector().getAsync("redirectState");
        let $transition$ = trans.injector().get("$transition$");
        let $q = trans.injector().get("$q");
        return $q
            .all([requestResolve, accountResolve, redirectStateResolve])
            .then(function (resolves) {
                let [request, account, redirectState] = resolves;
                let targetRedirectState =
                    $transition$.router.stateService.target(
                        redirectState,
                        { grower_sid: request.requester },
                        { reload: redirectState },
                    );
                if (request.completed || request.cancelled) {
                    return targetRedirectState;
                } else if (request.next_pending_signature_request) {
                    let SignatureRequestService = trans
                        .injector()
                        .get("SignatureRequestService");
                    return SignatureRequestService.retrieve(
                        request.next_pending_signature_request,
                    ).then(function (signatureRequest) {
                        if (signatureRequest.signer === account.sid) {
                            return targetRedirectState;
                        }
                    });
                }
            });
    },
};

export const v3SignState = {
    name: "app.paperwork.grower.v3Sign",
    url: "/v3-sign",
    component: "newSignPaperwork",
    resolve: {
        documentPackageRequests: function (
            DocumentPackageRequestService,
            $transition$,
            paperworkV3ToSign,
        ) {
            "ngInject";
            return DocumentPackageRequestService.list({
                completed: false,
                cancelled: false,
                sid__in: _.join(
                    _.map(paperworkV3ToSign, "documentRequest.sid"),
                ),
            });
        },
        documentPackages: function (
            DocumentPackageService,
            $transition$,
            paperworkV3ToSign,
        ) {
            "ngInject";
            return DocumentPackageService.list({
                documentpackagerequest__sid__in: _.join(
                    _.map(paperworkV3ToSign, "documentRequest.sid"),
                ),
            });
        },
        signers: function (AccountService, documentPackageRequests) {
            "ngInject";
            return AccountService.several(
                _.uniq(_.map(documentPackageRequests, "signer")),
            );
        },
        signatureRequests: function (
            $transition$,
            SignatureRequestService,
            accountSid,
            paperworkV3ToSign,
        ) {
            "ngInject";
            return SignatureRequestService.list({
                signer__sid: accountSid,
                status: PENDING,
                role: SIGNER,
                document_package_request__sid__in: _.join(
                    _.map(paperworkV3ToSign, "documentRequest.sid"),
                ),
            });
        },
        redirectState: function () {
            "ngInject";
            return growerPaperworkState;
        },
        reloadState: function () {
            "ngInject";
            return growerPaperworkState;
        },
        initialSignature: function (
            PaperworkSignatureService,
            accountSid,
            $transition$,
        ) {
            "ngInject";
            return PaperworkSignatureService.get(
                accountSid,
                $transition$.params().grower_sid,
            );
        },
        requests: function (
            signatureRequests,
            documentPackages,
            documentPackageRequests,
        ) {
            "ngInject";
            return _.map(signatureRequests, function (signatureRequest) {
                return {
                    signatureRequest,
                    documentPackageRequest: _.find(documentPackageRequests, [
                        SID_LOOKUP,
                        signatureRequest.document_package_request,
                    ]),
                    documentPackage: _.find(documentPackages, [
                        SID_LOOKUP,
                        signatureRequest.document_package,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.V3_SIGN_TITLE",
        mixpanelEvent: "Signer viewed paperwork for signature",
    },
    redirectTo: (trans) => {
        return trans
            .injector()
            .getAsync("documentPackageRequests")
            .then(function (requests) {
                if (requests.length === 0) {
                    return trans.router.stateService.target(
                        growerPaperworkState,
                        trans.params(),
                    );
                }
            });
    },
};

export const growerPaperworkDoneState = {
    name: "app.paperwork.grower.done",
    url: "/done",
    component: "growerPaperworkDone",
    resolve: {
        farmMembership: function (
            FarmMembershipService,
            $transition$,
            account,
        ) {
            "ngInject";
            return FarmMembershipService.first({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                user__sid: account.sid,
                active: true,
            });
        },
    },
    data: {
        title: "routes.GROWER_PAPERWORK_DONE_TITLE",
        mixpanelEvent: "Viewed paperwork done",
    },
};

// TODO: OK to move to grower level
export const newAnnouncementBaseState = {
    name: "app.growers.grower.manageCommunication.base",
    url: "/{announcement_sid}",
    abstract: true,
    component: "newAnnouncementBase",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
                {},
            );
        },
        deleteRedirectState: function () {
            "ngInject";
            return communicationHistoryState;
        },
        progressStates: function () {
            "ngInject";
            return [
                addRecipientsState,
                addContentsState,
                addAttachmentsState,
                addLocationState,
                reviewAnnouncementState,
            ];
        },
    },
};

// TODO: OK to move to grower level
export const addRecipientsState = {
    name: "app.growers.grower.manageCommunication.base.new",
    url: "/select-recipients",
    component: "addRecipients",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        recipientSids: function (announcement) {
            "ngInject";
            return announcement.recipients;
        },
        nextState: function () {
            "ngInject";
            return addContentsState;
        },
        redirectIndividualState: function () {
            "ngInject";
            return addRecipientsIndividualsState;
        },
        redirectCrewsState: function () {
            "ngInject";
            return addRecipientsCrewsState;
        },
        redirectEveryoneState: function () {
            "ngInject";
            return addRecipientsEveryoneState;
        },
        redirectEveryoneGrowerState: function () {
            "ngInject";
            return addRecipientsEveryoneGrowerState;
        },
    },
    data: {
        title: "routes.ADD_RECIPIENTS_TITLE",
        mixpanelEvent: "Viewed new announcement add recipients",
    },
};

// TODO: OK to move to grower level
export const addRecipientsEveryoneGrowerState = {
    name: "app.growers.grower.manageCommunication.base.new.everyoneGrower",
    url: "/everyone-grower",
    component: "addRecipientsEveryoneGrower",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
    },
    data: {
        title: "routes.ADD_RECIPIENTS_EVERYONE_GROWER_TITLE",
        mixpanelEvent: "Viewed new announcement add recipients everyone grower",
    },
};

// TODO: OK to move to grower level
export const addRecipientsEveryoneState = {
    name: "app.growers.grower.manageCommunication.base.new.everyone",
    url: "/everyone",
    component: "addRecipientsEveryone",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
    },
    data: {
        title: "routes.ADD_RECIPIENTS_EVERYONE_TITLE",
        mixpanelEvent: "Viewed new announcement add recipients everyone",
    },
};

// TODO: OK to move to grower level
export const addRecipientsCrewsState = {
    name: "app.growers.grower.manageCommunication.base.new.crews",
    url: "/crews",
    component: "addRecipientsCrews",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        groups: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
            });
        },
        recentAnnouncements: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
                status: SENT_STATUS,
                omit: "recipients",
            });
        },
    },
    data: {
        title: "routes.ADD_RECIPIENTS_CREWS_TITLE",
        mixpanelEvent: "Viewed new announcement add recipients crews",
    },
};

// TODO: OK to move to grower level
export const addRecipientsIndividualsState = {
    name: "app.growers.grower.manageCommunication.base.new.individuals",
    url: "/individuals",
    component: "addRecipientsIndividuals",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        users: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                growermembership__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        workers: function (users, growerMemberships) {
            "ngInject";
            return _.map(growerMemberships, function (growerMembership) {
                return {
                    user: _.find(users, [SID_LOOKUP, growerMembership.user]),
                    growerMembership,
                };
            });
        },
    },
    data: {
        title: "routes.ADD_RECIPIENTS_INDIVIDUALS_TITLE",
        mixpanelEvent: "Viewed new announcement add recipients individuals",
    },
};

// TODO: OK to move to grower level
export const addContentsState = {
    name: "app.growers.grower.manageCommunication.base.addContents",
    url: "/write-message",
    component: "addContents",
    resolve: {
        announcementTranslateds: function (
            AnnouncementTRANSLATEDService,
            $transition$,
        ) {
            "ngInject";
            return AnnouncementTRANSLATEDService.list({
                announcement__sid: $transition$.params().announcement_sid,
            });
        },
        previousState: function () {
            "ngInject";
            return addRecipientsState;
        },
        nextState: function () {
            "ngInject";
            return addAttachmentsState;
        },
        reloadState: function () {
            "ngInject";
            return newAnnouncementBaseState;
        },
    },
    data: {
        title: "routes.ADD_CONTENTS_TITLE",
        mixpanelEvent: "Viewed new announcement write message",
    },
};

// TODO: OK to move to grower level
export const addAttachmentsState = {
    name: "app.growers.grower.manageCommunication.base.addAttachments",
    url: "/add-attachments",
    component: "addAttachments",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        currentAttachments: function (AttachmentService, $transition$) {
            "ngInject";
            return AttachmentService.list({
                announcement__sid: $transition$.params().announcement_sid,
            });
        },
        previousState: function () {
            "ngInject";
            return addContentsState;
        },
        nextState: function () {
            "ngInject";
            return addLocationState;
        },
        reloadState: function () {
            "ngInject";
            return newAnnouncementBaseState;
        },
    },
    data: {
        title: "routes.ADD_ATTACHMENTS_TITLE",
        mixpanelEvent: "Viewed new announcement add attachments",
    },
};

// TODO: OK to move to grower level
export const addLocationState = {
    name: "app.growers.grower.manageCommunication.base.addLocation",
    url: "/add-location",
    component: "addLocation",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        previousState: function () {
            "ngInject";
            return addAttachmentsState;
        },
        nextState: function () {
            "ngInject";
            return reviewAnnouncementState;
        },
        reloadState: function () {
            "ngInject";
            return newAnnouncementBaseState;
        },
        farm: function (FarmService, $transition$) {
            "ngInject";
            return FarmService.first({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.ADD_LOCATION_TITLE",
        mixpanelEvent: "Viewed new announcement add location",
    },
};

// TODO: OK to move to grower level
export const reviewAnnouncementState = {
    name: "app.growers.grower.manageCommunication.base.reviewAnnouncement",
    url: "/review",
    component: "reviewAnnouncement",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
                {},
                {
                    expand: ["translations"],
                },
            );
        },
        sender: function (AccountService, announcement) {
            "ngInject";
            return AccountService.retrieve(announcement.sender);
        },
        attachments: function ($transition$, AttachmentService) {
            "ngInject";
            return AttachmentService.list({
                announcement__sid: $transition$.params().announcement_sid,
            }).then(function (results) {
                return _.groupBy(results, "language");
            });
        },
        previousState: function () {
            "ngInject";
            return addLocationState;
        },
        nextState: function () {
            "ngInject";
            return announcementDetailState;
        },
        scheduledNextState: function () {
            "ngInject";
            return communicationHistoryState;
        },
        reloadState: function () {
            "ngInject";
            return newAnnouncementBaseState;
        },
    },
    data: {
        title: "routes.REVIEW_ANNOUNCEMENT_TITLE",
        mixpanelEvent: "Viewed new announcement review",
    },
};

export const crewCreateAnnouncementBaseState = {
    name: "app.crew.send-announcement",
    url: "/{announcement_sid}",
    abstract: true,
    component: "crewAnnouncementBase",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(
                /* webpackChunkName: "lazy.communication" */ "./lazy/communication"
            ),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        crew: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.retrieve($transition$.params().crew_sid);
        },
        deleteRedirectState: function () {
            "ngInject";
            return homescreenState;
        },
        progressStates: function () {
            "ngInject";
            return [
                crewCreateAnnouncementAddRecipientsState,
                crewCreateAnnouncementAddContentsState,
                crewCreateAnnouncementAddAttachmentsState,
                crewCreateAnnouncementAddLocationState,
                crewCreateAnnouncementReviewAnnouncementState,
            ];
        },
    },
};

export const crewCreateAnnouncementAddRecipientsState = {
    name: "app.crew.send-announcement.addRecipients",
    url: "/select-recipients",
    component: "addRecipients",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        recipientSids: function (announcement) {
            "ngInject";
            return announcement.recipients;
        },
        nextState: function () {
            "ngInject";
            return crewCreateAnnouncementAddContentsState;
        },
        reloadState: function () {
            "ngInject";
            return crewCreateAnnouncementBaseState;
        },
        redirectCrewsState: function () {
            "ngInject";
            return crewAddRecipientsCrewsState;
        },
    },
    data: {
        title: "routes.CREW_CREATE_ANNOUNCEMENT_ADD_RECIPIENTS_TITLE",
        mixpanelEvent: "Viewed new crew announcement add recipients",
    },
};

export const crewAddRecipientsCrewsState = {
    name: "app.crew.send-announcement.addRecipients.crews",
    url: "/crews",
    component: "addRecipientsCrews",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        groups: function (crew) {
            "ngInject";
            return [crew];
        },
    },
    data: {
        title: "routes.CREW_ADD_RECIPIENTS_CREWS_TITLE",
        mixpanelEvent: "Viewed new announcement add recipients crews",
    },
};

export const crewCreateAnnouncementAddContentsState = {
    name: "app.crew.send-announcement.addContents",
    url: "/write-message",
    component: "addContents",
    resolve: {
        announcementTranslateds: function (
            AnnouncementTRANSLATEDService,
            $transition$,
        ) {
            "ngInject";
            return AnnouncementTRANSLATEDService.list({
                announcement__sid: $transition$.params().announcement_sid,
            });
        },
        previousState: function () {
            "ngInject";
            return crewCreateAnnouncementAddRecipientsState;
        },
        nextState: function () {
            "ngInject";
            return crewCreateAnnouncementAddAttachmentsState;
        },
        reloadState: function () {
            "ngInject";
            return crewCreateAnnouncementBaseState;
        },
    },
    data: {
        title: "routes.CREW_CREATE_ANNOUNCEMENT_ADD_CONTENTS_TITLE",
        mixpanelEvent: "Viewed new crew announcement write message",
    },
};

export const crewCreateAnnouncementAddAttachmentsState = {
    name: "app.crew.send-announcement.addAttachments",
    url: "/add-attachments",
    component: "addAttachments",
    lazyLoad: function ($transition$) {
        return lazyLoad($transition$, CLOUDINARY_LIB_URL);
    },
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        currentAttachments: function (AttachmentService, $transition$) {
            "ngInject";
            return AttachmentService.list({
                announcement__sid: $transition$.params().announcement_sid,
            });
        },
        previousState: function () {
            "ngInject";
            return crewCreateAnnouncementAddContentsState;
        },
        nextState: function () {
            "ngInject";
            return crewCreateAnnouncementAddLocationState;
        },
        reloadState: function () {
            "ngInject";
            return crewCreateAnnouncementBaseState;
        },
    },
    data: {
        title: "routes.CREW_CREATE_ANNOUNCEMENT_ADD_ATTACHMENTS_TITLE",
        mixpanelEvent: "Viewed new crew announcement add attachments",
    },
};

export const crewCreateAnnouncementAddLocationState = {
    name: "app.crew.send-announcement.addLocation",
    url: "/add-location",
    component: "addLocation",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
            );
        },
        previousState: function () {
            "ngInject";
            return crewCreateAnnouncementAddAttachmentsState;
        },
        nextState: function () {
            "ngInject";
            return crewCreateAnnouncementReviewAnnouncementState;
        },
        reloadState: function () {
            "ngInject";
            return crewCreateAnnouncementBaseState;
        },
    },
    data: {
        title: "routes.CREW_CREATE_ANNOUNCEMENT_ADD_LOCATION_TITLE",
        mixpanelEvent: "Viewed new crew announcement add location",
    },
};

export const crewCreateAnnouncementReviewAnnouncementState = {
    name: "app.crew.send-announcement.reviewAnnouncement",
    url: "/review",
    component: "reviewAnnouncement",
    resolve: {
        announcement: function (AnnouncementService, $transition$) {
            "ngInject";
            return AnnouncementService.retrieve(
                $transition$.params().announcement_sid,
                {},
                {
                    expand: ["translations"],
                },
            );
        },
        sender: function (AccountService, announcement) {
            "ngInject";
            return AccountService.retrieve(announcement.sender);
        },
        recipients: function ($transition$, AccountService) {
            "ngInject";
            return AccountService.list({
                announcement__sid: $transition$.params().announcement_sid,
            });
        },
        attachments: function ($transition$, AttachmentService) {
            "ngInject";
            return AttachmentService.list({
                announcement__sid: $transition$.params().announcement_sid,
            }).then(function (results) {
                return _.groupBy(results, "language");
            });
        },
        previousState: function () {
            "ngInject";
            return crewCreateAnnouncementAddLocationState;
        },
        nextState: function () {
            "ngInject";
            return homescreenState;
        },
        scheduledNextState: function () {
            "ngInject";
            return homescreenState;
        },
    },
    data: {
        title: "routes.CREW_CREATE_ANNOUNCEMENT_REVIEW_ANNOUNCEMENT_TITLE",
        mixpanelEvent: "Viewed new crew announcement review",
    },
};

export const takeProfilePictureState = {
    name: "takeProfilePicture",
    url: "/take-profile-picture?grower_membership_sid?selfie",
    params: {
        grower_membership_sid: {
            dynamic: true,
        },
        selfie: {
            type: "bool",
            value: false,
        },
    },
    component: "takeProfilePicture",
    resolve: {
        growerMembershipSid: function ($transition$) {
            "ngInject";
            return $transition$.params().grower_membership_sid;
        },
        selfie: function ($transition$) {
            "ngInject";
            return $transition$.params().selfie;
        },
    },
    data: {
        title: "routes.TAKE_PROFILE_PICTURE_TITLE",
    },
};

export const documentScanStartState = {
    name: "app.documentScanStart",
    url: "/document-scan?user_sid?grower_sid?grower_membership_sid",
    params: {
        user_sid: {
            dynamic: true,
        },
        grower_sid: {
            dynamic: true,
        },
        document_type_sid: {
            dynamic: true,
        },
        grower_membership_sid: {
            dynamic: true,
        },
    },
    component: "documentScanStart",
    resolve: {
        userSid: function ($transition$) {
            "ngInject";
            return $transition$.params().user_sid;
        },
        growerSid: function ($transition$) {
            "ngInject";
            return $transition$.params().grower_sid;
        },
        documentTypeSid: function ($transition$) {
            "ngInject";
            return $transition$.params().document_type_sid;
        },
        growerMembershipSid: function ($transition$) {
            "ngInject";
            return $transition$.params().grower_membership_sid;
        },
        documentTypes: function (
            IdentificationDocumentTypeService,
            $transition$,
        ) {
            "ngInject";
            return IdentificationDocumentTypeService.list({
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        growerMembership: function (GrowerMembershipService, $transition$) {
            "ngInject";
            console.log($transition$.params().grower_membership_sid);
            return GrowerMembershipService.retrieve(
                $transition$.params().grower_membership_sid,
            );
        },
    },
    data: {
        title: "routes.DOCUMENT_SCAN_START",
        mixpanelEvent: "Viewed document scan start",
    },
};

export const waflaContractListState = {
    name: "app.growers.grower.farm.wafla",
    url: "/wafla",
    views: { lower: { component: "contractList" } },
    resolve: {
        contractsData: function (WAFLAContractService, grower) {
            "ngInject";
            return WAFLAContractService.retrieve(grower.wafla_id);
        },
        activeContracts: function (contractsData) {
            "ngInject";
            return _.filter(contractsData.contracts, function (contract) {
                return _.includes(
                    [
                        "Active",
                        "Crossing Completed",
                        "Transfer",
                        "Processing Outbound",
                    ],
                    contract.status,
                );
            });
        },
        doneContracts: function (contractsData) {
            "ngInject";
            return _.filter(contractsData.contracts, ["status", "Done"]);
        },
    },
    data: {
        title: "routes.WAFLA_DASHBOARD",
        breadcrumb: "crumbs.WAFLA_DASHBOARD",
        mixpanelEvent: "Viewed wafla dashboard",
    },
};

export const waflaContractDetailState = {
    name: "app.growers.grower.farm.wafla.contractDetail",
    url: "/{contract_airtable_id}",
    component: "contractDetail",
    resolve: {
        contract: function (contractsData, $transition$) {
            "ngInject";
            return _.find(contractsData.contracts, {
                id: $transition$.params().contract_airtable_id,
            });
        },
        candidates: function (H2ACandidateService, $transition$) {
            "ngInject";
            return H2ACandidateService.list({
                contract_airtable_id:
                    $transition$.params().contract_airtable_id,
            });
        },
        growerMemberships: function (GrowerMembershipService, candidates) {
            "ngInject";
            return GrowerMembershipService.several(
                _.map(candidates, "grower_membership"),
            );
        },
    },
    data: {
        title: "routes.WAFLA_CONTRACT",
        breadcrumb: "crumbs.WAFLA_CONTRACT",
        mixpanelEvent: "Viewed wafla contract detail",
    },
};

export const waflaContractOnboardingState = {
    name: "app.growers.grower.farm.wafla.contractDetail.onboard",
    url: "/onboard",
    params: {
        actionItems: {
            squash: true,
            inherit: false,
        },
    },
    component: "contractOnboarding",
    resolve: {
        selectedWorkers: function ($transition$, GrowerMembershipService) {
            "ngInject";
            return GrowerMembershipService.several(
                $transition$.params().actionItems,
            );
        },
        contract: function (contractsData, $transition$) {
            "ngInject";
            return _.find(contractsData.contracts, {
                id: $transition$.params().contract_airtable_id,
            });
        },
        housingSites: function (HousingSiteService, $transition$) {
            "ngInject";
            return HousingSiteService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        courses: function (SafetyCourseService, $transition$) {
            "ngInject";
            return SafetyCourseService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
        groups: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        wpsCredentials: function ($transition$, CredentialService) {
            "ngInject";
            return CredentialService.list({
                archived: false,
                credential_type__wps_qualified: true,
                expiration_date__gt: moment().format(DATE_FORMAT),
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        wpsCredentialedUsers: function (wpsCredentials, AccountService) {
            "ngInject";
            let wpsTrainerUserSids = _.map(wpsCredentials, "subject");
            return AccountService.several(wpsTrainerUserSids);
        },
        wpsCredentialedGrowerMemberships: function (
            wpsCredentials,
            GrowerMembershipService,
        ) {
            "ngInject";
            return GrowerMembershipService.list({
                user__sid__in: _.join(_.map(wpsCredentials, "subject")),
            });
        },
        wpsTrainers: function (
            wpsCredentialedUsers,
            wpsCredentialedGrowerMemberships,
        ) {
            "ngInject";
            return _.map(wpsCredentialedUsers, function (user) {
                return {
                    user: user,
                    growerMembership: _.find(wpsCredentialedGrowerMemberships, [
                        "user",
                        user.sid,
                    ]),
                };
            });
        },
        packets: function (PacketService, $transition$) {
            "ngInject";
            return PacketService.list({
                active: true,
                owner__sid: $transition$.params().grower_sid,
                delivery_type_fully_specified: true,
            });
        },
        packetMembers: function (PacketMemberService, packets) {
            "ngInject";
            return PacketMemberService.list({
                active: true,
                packet__sid__in: _.join(_.map(packets, SID_LOOKUP)),
            });
        },
        documentPackages: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.list({
                active: true,
                accessor__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.WAFLA_CONTRACT_ONBOARDING",
        breadcrumb: "crumbs.WAFLA_CONTRACT_ONBOARDING",
        mixpanelEvent: "Viewed wafla contract onboarding",
    },
    redirectTo: parentIfEmpty,
};

export const waflaContractAddEmployeeState = {
    name: "app.growers.grower.farm.wafla.contractDetail.addEmployee",
    url: "/add?candidate_sid",
    component: "contractAddEmployee",
    resolve: {
        candidate: function (H2ACandidateService, $transition$) {
            "ngInject";
            return H2ACandidateService.retrieve(
                $transition$.params().candidate_sid,
            );
        },
        crew: function (GroupService, $transition$, contract) {
            "ngInject";
            return GroupService.first({
                farm__grower_organization__sid:
                    $transition$.params().grower_sid,
                name: contract.name,
            });
        },
    },
    data: {
        title: "routes.WAFLA_CONTRACT_ADD_EMPLOYEE",
        breadcrumb: "crumbs.WAFLA_CONTRACT_ADD_EMPLOYEE",
        mixpanelEvent: "Viewed wafla contract add employee",
    },
};

export const fieldClockLinkRedirectState = {
    name: "app.linkFieldClock",
    url: "/link-fieldclock",
    resolve: {
        growerManagementMemberships: function (
            GrowerManagementMembershipService,
            accountSid,
        ) {
            "ngInject";
            return GrowerManagementMembershipService.list({
                active: true,
                user__sid: accountSid,
            });
        },
        redirect: function ($transition$, growerManagementMemberships) {
            "ngInject";
            let params = {
                grower_sid: _.first(growerManagementMemberships)
                    .grower_organization,
            };
            return $transition$.router.stateService.target(
                growerEditState,
                params,
            );
        },
    },
    data: {
        mixpanelEvent: "Viewed FieldClock link redirect",
    },
    redirectTo: (trans) => {
        return trans.injector().getAsync("redirect");
    },
};

export const publicListTrainingState = {
    name: "publicListTraining",
    url: "/trainings",
    abstract: true,
};

export const publicTrainingState = {
    name: "publicListTraining.publicTraining",
    url: "/{slug}",
    component: "publicSafetyMeetingTopic",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "common.safety" */ "./common/safety"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        topic: function (PublicTrainingService, $transition$) {
            "ngInject";
            return PublicTrainingService.retrievePublic(
                $transition$.params().slug,
            );
        },
        defaultTranslation: function (topic) {
            "ngInject";
            return _.find(topic.translations, ["language", ENGLISH]);
        },
    },
    data: {
        title: "routes.VIEW_PUBLIC_TRAINING_TITLE",
        mixpanelEvent: "Viewed public training",
        description: "routes.VIEW_PUBLIC_TRAINING_DESCRIPTION",
    },
};

export const growerHousingSitesState = {
    name: "app.growers.grower.housing",
    url: "/housing?from?to?crews?housing_site?employees",
    params: {
        from: {
            value: () => moment().startOf("year").toISOString(),
            squash: true,
            inherit: false,
        },
        to: {
            value: () => moment().toISOString(),
            squash: true,
            inherit: false,
        },
        crews: {
            array: true,
            value: [],
        },
        housing_site: {
            array: true,
            value: [],
        },
        employees: {
            array: true,
            value: [],
        },
    },
    views: { lower: { component: "housingSiteList" } },
    resolve: {
        housingSites: function ($transition$, HousingSiteService) {
            "ngInject";
            return HousingSiteService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        housingAssignments: function ($transition$, HousingAssignmentService) {
            "ngInject";
            return HousingAssignmentService.list({
                housing_site__grower_organization__sid:
                    $transition$.params().grower_sid,
                move_out_date__gt_or_null: $transition$.params().from,
            });
        },
        growerMemberships: function (
            $transition$,
            GrowerMembershipService,
            housingAssignments,
        ) {
            "ngInject";
            let growerMembershipSids = _.map(
                housingAssignments,
                "grower_membership",
            );
            return GrowerMembershipService.several(
                _.concat($transition$.params().employees, growerMembershipSids),
            );
        },
        groups: function (GroupService, $transition$) {
            "ngInject";
            return GroupService.list({
                active: true,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        query: function ($transition$) {
            "ngInject";
            let x = {
                fromDate: $transition$.params().from,
                toDate: $transition$.params().to,
                crews: $transition$.params().crews,
                housing_site: $transition$.params().housing_site,
                employees: $transition$.params().employees,
            };
            console.log(x);
            return x;
        },
        memberships: function (MembershipService, $transition$) {
            "ngInject";
            return MembershipService.list({
                group__grower_organization__sid:
                    $transition$.params().grower_sid,
                active: true,
            });
        },
    },
    data: {
        title: "routes.GROWER_HOUSING_SITES_TITLE",
        mixpanelEvent: "Viewed grower housing sites",
    },
};

export const growerHousingSiteCreateState = {
    name: "app.growers.grower.housing.create",
    url: "/create",
    component: "housingSiteCreate",
    data: {
        title: "routes.GROWER_HOUSING_SITE_CREATE_TITLE",
        mixpanelEvent: "Viewed grower housing site create",
    },
};

export const documentPackageCustomizationCreateState = {
    name: "app.growers.grower.manageOnboarding.documents.createDocumentPackageCustomization",
    url: "/{documentpackage_sid}/versions/create",
    component: "documentPackageCustomizationCreate",
    resolve: {
        document: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.retrieve(
                $transition$.params().documentpackage_sid,
            );
        },
    },
    data: {
        title: "routes.DOCUMENT_PACKAGE_CUSTOMIZATION_CREATE_TITLE",
        mixpanelEvent: "Viewed document package customization create",
        breadcrumb: "crumbs.DOCUMENT_PACKAGE_CUSTOMIZATION_CREATE",
    },
};

export const documentPackageCustomizationEditState = {
    name: "app.growers.grower.manageOnboarding.documents.documentPackageCustomizationEdit",
    url: "/{documentpackage_sid}/versions/{document_package_customization_sid}/edit",
    component: "documentPackageCustomizationEdit",
    resolve: {
        customization: function (
            $transition$,
            DocumentPackageCustomizationService,
        ) {
            "ngInject";
            return DocumentPackageCustomizationService.retrieve(
                $transition$.params().document_package_customization_sid,
            );
        },
        customizationTranslateds: function (
            DocumentPackageCustomizationTranslatedService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageCustomizationTranslatedService.list({
                document_package_customization__sid:
                    $transition$.params().document_package_customization_sid,
            });
        },
        document: function (DocumentPackageService, $transition$) {
            "ngInject";
            return DocumentPackageService.retrieve(
                $transition$.params().documentpackage_sid,
            );
        },
        documentTranslateds: function (
            DocumentPackageTranslatedService,
            $transition$,
        ) {
            "ngInject";
            return DocumentPackageTranslatedService.list({
                document_package__sid:
                    $transition$.params().documentpackage_sid,
            });
        },
    },
    data: {
        title: "routes.DOCUMENT_PACKAGE_CUSTOMIZATION_EDIT_TITLE",
        mixpanelEvent: "Viewed document package customization edit",
        breadcrumb: "crumbs.DOCUMENT_PACKAGE_CUSTOMIZATION_EDIT",
    },
};

export const handbooksState = {
    name: "app.handbooks",
    url: "/handbooks",
    abstract: true,
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(/* webpackChunkName: "lazy.handbooks" */ "./lazy/handbooks"),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    data: {},
};

export const growerHandbooksState = {
    name: "app.handbooks.grower",
    url: "/{grower_sid}",
    component: "viewHandbooks",
    resolve: {
        grower: function ($transition$, GrowerOrganizationService) {
            "ngInject";
            return GrowerOrganizationService.retrieve(
                $transition$.params().grower_sid,
            );
        },
        handbooks: function ($transition$, DocumentPackageService) {
            "ngInject";
            return DocumentPackageService.list({
                active: true,
                category: "handbook",
                owner__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.VIEW_HANDBOOKS_TITLE",
        mixpanelEvent: "Viewed handbook",
        breadcrumb: "crumbs.VIEW_HANDBOOKS",
    },
};

export const myDocumentsState = {
    name: "app.myDocuments",
    url: "/my-documents/{grower_sid}",
    component: "myDocuments",
    resolve: {
        grower: function ($transition$, GrowerOrganizationService) {
            "ngInject";
            return GrowerOrganizationService.retrieve(
                $transition$.params().grower_sid,
            );
        },
        myGrowerMembership: function (
            GrowerMembershipService,
            account,
            $transition$,
        ) {
            "ngInject";
            return GrowerMembershipService.first({
                grower_organization__sid: $transition$.params().grower_sid,
                user__sid: account.sid,
            });
        },
        completedPaperworkRecords: function (
            DocumentPackageRecordService,
            $transition$,
            myGrowerMembership,
        ) {
            "ngInject";
            return DocumentPackageRecordService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                grower_membership__sid: myGrowerMembership.sid,
                completion_date__isnull: false,
                archived: false,
            });
        },
        documentPackages: function (
            DocumentPackageService,
            completedPaperworkRecords,
        ) {
            "ngInject";
            let recordPackageSids = _.map(
                completedPaperworkRecords,
                "document_package",
            );
            let packageSids = _.uniq(recordPackageSids);
            return DocumentPackageService.several(packageSids);
        },
        paperworks: function (completedPaperworkRecords, documentPackages) {
            "ngInject";
            return _.map(completedPaperworkRecords, function (record) {
                return {
                    record: record,
                    documentPackage: _.find(documentPackages, [
                        SID_LOOKUP,
                        record.document_package,
                    ]),
                };
            });
        },
    },
    data: {
        title: "routes.VIEW_MY_DOCUMENTS_TITLE",
        mixpanelEvent: "Viewed my documents",
    },
};

export const crewCreateDisciplineReportState = {
    name: "app.createDisciplineReport",
    url: "/manager/{grower_sid}/record-employee-issue/create",
    component: "addDescriptionDisciplineReport",
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(
                /* webpackChunkName: "lazy.discipline" */ "./lazy/discipline"
            ),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        grower: function ($transition$, GrowerOrganizationService) {
            "ngInject";
            return GrowerOrganizationService.retrieve(
                $transition$.params().grower_sid,
            );
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        employees: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                growermembership__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        workers: function (employees, growerMemberships) {
            "ngInject";
            return _.map(growerMemberships, function (employee) {
                return {
                    user: _.find(employees, [SID_LOOKUP, employee.user]),
                    growerMembership: employee,
                    farmMembership: undefined,
                };
            });
        },
    },
    data: {
        title: "routes.CREATE_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew create discipline report",
    },
};

export const crewDisciplineReportBaseState = {
    name: "app.crewDisciplineReport",
    url: "/manager/{grower_sid}/record-employee-issue/{report_sid}",
    abstract: true,
    lazyLoad: function ($transition$) {
        return Promise.all([
            import(
                /* webpackChunkName: "lazy.discipline" */ "./lazy/discipline"
            ),
        ]).then(function (modules) {
            $transition$
                .injector()
                .get("$ocLazyLoad")
                .load(_.map(modules, "default"));
        });
    },
    resolve: {
        grower: function ($transition$, GrowerOrganizationService) {
            "ngInject";
            return GrowerOrganizationService.retrieve(
                $transition$.params().grower_sid,
            );
        },
    },
};

export const crewAddDetailsDisciplineReportState = {
    name: "app.crewDisciplineReport.addDetails",
    url: "/add-details",
    component: "addDetailsDisciplineReport",
    resolve: {
        report: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
        growerMemberships: function (GrowerMembershipService, $transition$) {
            "ngInject";
            return GrowerMembershipService.list({
                grower_organization__sid: $transition$.params().grower_sid,
                active: true,
            });
        },
        users: function (AccountService, $transition$) {
            "ngInject";
            return AccountService.list({
                growermembership__grower_organization__sid:
                    $transition$.params().grower_sid,
            });
        },
        workers: function (users, growerMemberships) {
            "ngInject";
            return _.map(growerMemberships, function (employee) {
                return {
                    user: _.find(users, [SID_LOOKUP, employee.user]),
                    growerMembership: employee,
                    farmMembership: undefined,
                };
            });
        },
    },
    data: {
        title: "routes.CREW_ADD_DETAILS_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew add details discipline report",
    },
};

export const crewInstructionsDisciplineReportState = {
    name: "app.crewDisciplineReport.instructions",
    url: "/instructions",
    component: "instructionsDisciplineReport",
    resolve: {
        report: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
    },
    data: {
        title: "routes.CREW_INSTRUCTION_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew instructions discipline report",
    },
};

export const crewAddEmployeeStatementDisciplineReportState = {
    name: "app.crewDisciplineReport.addEmployeeStatement",
    url: "/add-employee-statement",
    component: "addEmployeeStatementDisciplineReport",
    resolve: {
        report: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
    },
    data: {
        title: "routes.CREW_ADD_EMPLOYEE_STATEMENT_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew add employee statement discipline report",
    },
};

export const crewAwaitApprovalDisciplineReportState = {
    name: "app.crewDisciplineReport.awaitApproval",
    url: "/wait-for-approval",
    component: "awaitApprovalDisciplineReport",
    resolve: {
        report: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
    },
    data: {
        title: "routes.CREW_AWAIT_APPROVAL_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew await approval discipline report",
    },
};

export const crewRecommendedActionDisciplineReportState = {
    name: "app.crewDisciplineReport.recommendedAction",
    url: "/recommended-action",
    component: "recommendedActionDisciplineReport",
    resolve: {
        report: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
        subjectEmployee: function (GrowerMembershipService, report) {
            "ngInject";
            return GrowerMembershipService.retrieve(report.subject_employee);
        },
        translated: function (
            $transition$,
            DisciplineReportTranslatedService,
            $translate,
        ) {
            "ngInject";
            return DisciplineReportTranslatedService.first({
                discipline_report__sid: $transition$.params().report_sid,
                language: $translate.use(),
            });
        },
        myGrowerMembership: function (
            account,
            GrowerMembershipService,
            $transition$,
        ) {
            "ngInject";
            return GrowerMembershipService.first({
                user__sid: account.sid,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.CREW_RECOMMENDED_ACTION_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew recommended action discipline report",
    },
};

export const crewAddOwnActionDisciplineReportState = {
    name: "app.crewDisciplineReport.recommendedAction.edit",
    url: "/edit",
    component: "addOwnActionDisciplineReport",
    resolve: {
        report: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
        myGrowerMembership: function (
            account,
            GrowerMembershipService,
            $transition$,
        ) {
            "ngInject";
            return GrowerMembershipService.first({
                user__sid: account.sid,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.CREW_ADD_OWN_ACTION_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew add own action discipline report",
    },
};

export const crewExecuteActionDisciplineReportState = {
    name: "app.crewDisciplineReport.executeAction",
    url: "/action",
    component: "executeActionDisciplineReport",
    resolve: {
        report: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
        subjectEmployee: function (GrowerMembershipService, report) {
            "ngInject";
            return GrowerMembershipService.retrieve(report.subject_employee);
        },
        translated: function (
            $transition$,
            DisciplineReportTranslatedService,
            $translate,
        ) {
            "ngInject";
            return DisciplineReportTranslatedService.first({
                discipline_report__sid: $transition$.params().report_sid,
                language: $translate.use(),
            });
        },
        myGrowerMembership: function (
            account,
            GrowerMembershipService,
            $transition$,
        ) {
            "ngInject";
            return GrowerMembershipService.first({
                user__sid: account.sid,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.CREW_EXECUTE_ACTION_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew execute action discipline report",
    },
};

export const crewDoneDisciplineReportState = {
    name: "app.crewDisciplineReport.done",
    url: "/done",
    component: "doneDisciplineReport",
    resolve: {
        report: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().report_sid,
            );
        },
    },
    data: {
        title: "routes.CREW_DONE_DISCIPLINE_REPORT_TITLE",
        mixpanelEvent: "Viewed crew done discipline report",
    },
};

export const disciplineListState = {
    name: "app.growers.grower.discipline",
    url: "/discipline",
    views: { lower: { component: "disciplineList" } },
    resolve: {
        disciplineReports: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.list({
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
        subjectEmployees: function (
            GrowerMembershipService,
            disciplineReports,
        ) {
            "ngInject";
            return GrowerMembershipService.several(
                _.map(disciplineReports, "subject_employee"),
            );
        },
        reportingEmployees: function (
            GrowerMembershipService,
            disciplineReports,
        ) {
            "ngInject";
            return GrowerMembershipService.several(
                _.map(disciplineReports, "reporting_employee"),
            );
        },
        disciplineReportTranslateds: function (
            $transition$,
            DisciplineReportTranslatedService,
            $translate,
        ) {
            "ngInject";
            return DisciplineReportTranslatedService.list({
                discipline_report__grower_organization__sid:
                    $transition$.params().grower_sid,
                language: $translate.use(),
            });
        },
    },
    data: {
        title: "routes.DISCIPLINE_LIST_TITLE",
        mixpanelEvent: "Viewed discipline list",
        breadcrumb: "crumbs.DISCIPLINE_LIST",
    },
};

export const disciplineDetailState = {
    name: "app.growers.grower.discipline.view",
    url: "/{discipline_report_sid}",
    component: "disciplineDetail",
    resolve: {
        disciplineReport: function ($transition$, DisciplineReportService) {
            "ngInject";
            return DisciplineReportService.retrieve(
                $transition$.params().discipline_report_sid,
            );
        },
        subjectEmployee: function (GrowerMembershipService, disciplineReport) {
            "ngInject";
            return GrowerMembershipService.retrieve(
                disciplineReport.subject_employee,
            );
        },
        subjectEmployeeUser: function (AccountService, subjectEmployee) {
            "ngInject";
            return AccountService.retrieve(subjectEmployee.user);
        },
        reportingEmployee: function (
            GrowerMembershipService,
            disciplineReport,
        ) {
            "ngInject";
            return GrowerMembershipService.retrieve(
                disciplineReport.reporting_employee,
            );
        },
        disciplineReportTranslated: function (
            $transition$,
            DisciplineReportTranslatedService,
            $translate,
        ) {
            "ngInject";
            return DisciplineReportTranslatedService.first({
                discipline_report__sid:
                    $transition$.params().discipline_report_sid,
                language: $translate.use(),
            });
        },
        pastDisciplineReports: function (
            $transition$,
            DisciplineReportService,
            disciplineReport,
        ) {
            "ngInject";
            return DisciplineReportService.list({
                subject_employee__sid: disciplineReport.subject_employee,
                created__lt: disciplineReport.created,
            });
        },
        pastDisciplineReportTranslateds: function (
            pastDisciplineReports,
            DisciplineReportTranslatedService,
            $translate,
        ) {
            "ngInject";
            return DisciplineReportTranslatedService.list({
                discipline_report__sid__in: _.join(
                    _.map(pastDisciplineReports, SID_LOOKUP),
                ),
                language: $translate.use(),
            });
        },
        actionApprovingEmployee: function (
            GrowerMembershipService,
            disciplineReport,
        ) {
            "ngInject";
            return GrowerMembershipService.retrieve(
                disciplineReport.action_approving_employee,
            );
        },
        actionExecutingEmployee: function (
            GrowerMembershipService,
            disciplineReport,
        ) {
            "ngInject";
            return GrowerMembershipService.retrieve(
                disciplineReport.action_executing_employee,
            );
        },
        breadcrumb: function (disciplineReport, subjectEmployee) {
            "ngInject";
            return (
                subjectEmployee.first_name +
                " " +
                subjectEmployee.last_name +
                " (" +
                subjectEmployee.employee_id +
                ") - " +
                moment(disciplineReport.created).format(COMMON_DATE_FORMAT)
            );
        },
        myGrowerMembership: function (
            account,
            GrowerMembershipService,
            $transition$,
        ) {
            "ngInject";
            return GrowerMembershipService.first({
                user__sid: account.sid,
                grower_organization__sid: $transition$.params().grower_sid,
            });
        },
    },
    data: {
        title: "routes.DISCIPLINE_DETAIL_TITLE",
        mixpanelEvent: "Viewed discipline detail",
        breadcrumb: "crumbs.DISCIPLINE_DETAIL",
    },
};

export const disciplineHRCustomActionState = {
    name: "app.growers.grower.discipline.view.editAction",
    url: "/edit-action",
    component: "disciplineHRCustomAction",
    data: {
        title: "routes.DISCIPLINE_HR_CUSTOM_ACTION_TITLE",
        mixpanelEvent: "Viewed discipline HR custom action",
        breadcrumb: "crumbs.DISCIPLINE_HR_CUSTOM_ACTION",
    },
};
